import CoreToggle from '@nrk/core-toggle/jsx';
import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { IHelpOptionModal } from '../../model/help';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { track } from '../../lib/analytics/track';
import './Clippy.scss';
import { Clippy__Modal } from './Clippy__Modal';
import { Clippy__Option } from './Clippy__Option';
import { help } from './help';
import { Text } from '../Text/Text';

export function Clippy() {
  const { currentPage } = useAppState();
  const translate = useTranslate();
  const [showClippy, setShowClippy] = useState(false);
  const [currentOption, setCurrentOption] = useState<IHelpOptionModal>();
  const rootRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Automatically close Clippy whenever the current page (but not subpage) changes,
  // e.g. when going back in browser history.
  useEffect(() => {
    setShowClippy(false);
  }, [currentPage.details.pageId]);

  useEffect(() => {
    if (showClippy === false) {
      // Close the content when CoreToggle is closed so next time we open
      // Clippy we start with the list of options.
      setCurrentOption(undefined);

      // Focus the toggle button when CoreToggle is closed.
      // The CoreToggle component normally does this for us,
      // but not when we close the toggle by setting the `hidden` attribute
      // to `false` when the user clicks our custom close button.
      if (buttonRef.current != null) {
        buttonRef.current.focus();
      }
    }
  }, [showClippy]);

  // Close Clippy if the user clicks on the root element,
  // effectively this means they clicked the backdrop.
  function handleClippyClick(event: React.MouseEvent) {
    if (event.target === rootRef.current) {
      setShowClippy(false);
    }
  }

  let currentOptions = help.pages[currentPage.details.pageId] ?? help.default;

  if (currentPage.details.subpageId != null) {
    const fullPageId = `${currentPage.details.pageId}/${currentPage.details.subpageId}`;
    if (help.pages[fullPageId] != null) {
      currentOptions = help.pages[fullPageId];
    }
  }

  if (currentOptions === false) {
    return null;
  }

  const options = currentOptions.map(optionId => {
    return {
      id: optionId,
      data: help.options[optionId]
    };
  });

  const list = (
    <ul className="clippy__list">
      {options.map((option, index) => (
        <li key={index} className="clippy__list-item">
          <Clippy__Option
            {...option.data}
            trackLabel={`${currentPage.details.pageId}/${option.id}`}
            onClick={() => {
              if (option.data.type === 'link') {
                setShowClippy(false);
              } else {
                setCurrentOption(option.data);
              }
            }}
          />
        </li>
      ))}
    </ul>
  );

  return (
    // The `onClick` even handler is only used as an additional way to close Clippy
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={classNames('clippy', { 'clippy--open': showClippy })} ref={rootRef} onClick={handleClippyClick}>
      <button
        className="clippy__button"
        title={translate('clippy/button')}
        ref={buttonRef}
        onClick={() => {
          if (showClippy === false) {
            // Track that the user has opened Clippy
            track.event({ category: 'clippy', action: 'open', label: currentPage.details.pageId });
          }
        }}
        // Fallback label for screen readers, since we change the button text when expanding clippy
        aria-label={translate('clippy/open')}
      >
        <Text className="clippy__button-text" size="4" weight="bold">
          {showClippy === false ? translate('clippy/open') : translate('clippy/close')}
        </Text>
        <Icon className="clippy__button-icon" id={showClippy ? 'icon-cross' : 'icon-help'} size={3} />
      </button>
      <CoreToggle
        className="clippy__body"
        onToggle={event => {
          // Reflect the `hidden` attribute to our local variable.
          // The CoreToggle component is not a controlled component
          // and will set itself to hidden when e.g. the user presses escape.
          // We want our local variable to match CoreToggle's `hidden` attribute
          // because CoreToggle does listen for when the attribute changes
          // which means we can close CoreToggle by changing `hidden` from `true` to `false`,
          // but in order to do that we need to keep the local variable in sync.
          setShowClippy(!event.target.hidden);
        }}
        hidden={showClippy === false}
      >
        {currentOption != null ? (
          <Clippy__Modal
            {...currentOption}
            onClose={() => {
              setShowClippy(false);
            }}
          />
        ) : (
          list
        )}
      </CoreToggle>
    </div>
  );
}
