import { IAppState } from '../../model/app';
import { createContext, useContext, useMemo } from 'react';

interface IAppStateProviderProps extends IAppState {
  children: React.ReactNode;
}

const AppStateContext = createContext<IAppState | undefined>(undefined);

export function AppStateProvider(props: IAppStateProviderProps) {
  const {
    previousPage,
    currentPage,
    currentPageSettings,
    setCurrentPageSettings,
    isFirstRender,
    isLoading,
    history,
    children
  } = props;

  const value = useMemo(() => {
    return {
      previousPage,
      currentPage,
      currentPageSettings,
      setCurrentPageSettings,
      isFirstRender,
      isLoading,
      history
    };
  }, [previousPage, currentPage, history, isFirstRender, isLoading, currentPageSettings, setCurrentPageSettings]);

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
}

export function useAppState() {
  const context = useContext(AppStateContext);

  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider');
  }

  return context;
}
