import { useFetchServiceAnnouncement } from '../../data/serviceAnnouncement/hooks';
import { Notification } from '../Notification/Notification';
import './ServiceAnnouncement.scss';

const ServiceAnnouncement = () => {
  const { data: serviceAnnouncement } = useFetchServiceAnnouncement();

  const message = serviceAnnouncement?.message;

  if (message == null) {
    return null;
  }

  return (
    <div className="service-announcement">
      <Notification text={message} icon={{ id: 'icon-error', type: 'icon' }} />
    </div>
  );
};

export default ServiceAnnouncement;
