import { useQueryWrapper } from '../query';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { queryBirkebeinerRouteByRouteId } from './queries';

export function useFetchBirkebeinerRouteByRouteId({ routeId }: { routeId?: string }) {
  const localeCode = useLocaleCode();

  return useQueryWrapper({
    ...queryBirkebeinerRouteByRouteId({ localeCode, routeId }),

    // The query will not execute until routeId exists
    enabled: routeId != null
  });
}
