import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { AriaLevel } from '../../model/aria';
import { initialState, reducer } from './helpers/reducer';

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  text: string;
  level: AriaLevel;
  addAriaLive: ({ text, level }: { text: string; level?: AriaLevel }) => void;
}

const AriaLiveContext = createContext<IContext | undefined>(undefined);

export function AriaLiveProvider(props: IProps) {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const addAriaLive = useCallback(({ text, level }: { text: string; level?: AriaLevel }) => {
    dispatch({ type: 'ADD', text, level });
  }, []);

  const value = useMemo(() => {
    const { text, level } = state;

    return {
      text,
      level,
      addAriaLive
    };
  }, [state, addAriaLive]);

  return <AriaLiveContext.Provider value={value}>{children}</AriaLiveContext.Provider>;
}

export function useAriaLive() {
  const context = useContext(AriaLiveContext);

  if (context === undefined) {
    throw new Error('useAriaLive must be used within a AriaLiveProvider');
  }

  return context;
}
