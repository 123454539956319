import { getApiBase } from '../app/api';
import { FetchResponseError } from '../app/errors';
import settings from '../app/settings';

export async function api<ResponseType>({ path }: { path: string }): Promise<ResponseType> {
  // After upgrading to node 18, msw is not properly intercepting our fetch calls correctly.
  // To circumvent this we are forcing the baseUrl to be localhost when running browser tests.
  // See github issue for msw: https://github.com/mswjs/msw/issues/1388
  const baseUrl = settings.site.isBrowserTest ? 'http://localhost:3000/api/v0' : getApiBase(path);
  const url = `${baseUrl}/${path}`;

  const response = await fetch(url);
  if (response.ok === false) {
    throw new FetchResponseError(response.status, `Could not fetch "${url}"`);
  }

  return await response.json();
}
