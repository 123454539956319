import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';
import './WebcamLabel.scss';

interface IProps {
  label?: string;
  value?: number;
  degrees?: number;
}

export function WebcamLabel(props: IProps) {
  const { label, value, degrees } = props;

  if (label == null && value == null && degrees == null) {
    return null;
  }

  return (
    <Text as="p" size="5" className="webcam-label">
      {degrees != null && (
        <span className="webcam-label__icon" style={{ transform: `rotate(${degrees}deg)` }}>
          <Icon id="icon-compass" size={2} />
        </span>
      )}

      {label}
      {value != null ? `+${value}` : null}
    </Text>
  );
}
