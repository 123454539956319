import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { IToastContent, TToastType, useToast } from '../../contexts/ToastContext/ToastContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import './Toast.scss';

const VISIBLE_DURATION = 3000;
interface IToastContentProps {
  type: TToastType;
  content: IToastContent;
  onRemoveToast: () => void;
}

export function Toast() {
  const { id, content, type, removeToast } = useToast();

  useEffect(() => {
    if (type === 'NOTIFICATION') {
      const timeOutId = setTimeout(() => {
        removeToast();
      }, VISIBLE_DURATION);

      return () => {
        clearTimeout(timeOutId);
      };
    }

    return;
  }, [type, removeToast]);

  if (type == null || content == null) {
    return null;
  }

  return <Toast__Content key={id} content={content} type={type} onRemoveToast={removeToast} />;
}

function Toast__Content(props: IToastContentProps) {
  const { content, type, onRemoveToast } = props;
  const { iconId, title, text } = content;

  const [isVisible, setIsVisible] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    setIsVisible(true);

    return () => setIsVisible(false);
  }, []);

  return (
    <div
      aria-hidden="true"
      className={classNames('toast', {
        'toast--error': type === 'ERROR',
        'toast--notification': type === 'NOTIFICATION',
        'toast--visible': isVisible === true
      })}
    >
      <Icon
        className={classNames('toast__icon', { 'toast__icon--star-filled': iconId === 'icon-star-filled' })}
        id={iconId ?? 'icon-partly-cloudy'}
        size={3}
      />
      <div className="toast__content">
        {title && (
          <Heading level="h2" size="4" className="toast__title">
            {title}
          </Heading>
        )}
        <Text as="p" size="4" className="toast__text">
          {text}
        </Text>
      </div>

      {type === 'ERROR' && (
        <button
          aria-label={translate('grammar/close')}
          className="toast__close-button"
          onClick={onRemoveToast}
          data-testid="toast/close"
        >
          <Icon className="toast__close-icon" id="icon-cross" size={2} />
        </button>
      )}
    </div>
  );
}
