import { useQueryWrapper } from '../query';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { querySuggestLocations } from './queries';

export function useFetchSuggestLocations({ query }: { query?: string }) {
  const localeCode = useLocaleCode();

  return useQueryWrapper({
    ...querySuggestLocations({ localeCode, query }),

    // We need a minimum of one character before we start searching.
    // Two or three would be better, but since there are a lot of
    // placed called just "Å" in Norway it's odd to not get any
    // results when you have typed one character.
    enabled: query != null && query.length > 0
  });
}
