import produce from 'immer';
import settings from '../../../app/settings';

interface IState {
  favouritedLocationIds: string[];
  visitedLocationIds: string[];
  prepopulatedLocationIds: string[];
}

type TAction =
  | { type: 'INITIALIZE'; favouritedLocationIds: string[]; visitedLocationIds: string[] }
  | { type: 'FAVOURITED_LOCATION_ADD'; locationId: string }
  | { type: 'FAVOURITED_LOCATION_REMOVE'; locationId: string }
  | { type: 'VISITED_LOCATION_ADD'; locationId: string }
  | { type: 'VISITED_LOCATION_REMOVE'; locationId: string }
  | { type: 'PREPOPULATED_LOCATION_REMOVE'; locationId: string };

export const initialState: IState = {
  favouritedLocationIds: [],
  visitedLocationIds: [],
  prepopulatedLocationIds: []
};

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'INITIALIZE':
      return produce(state, draft => {
        draft.favouritedLocationIds = action.favouritedLocationIds;
        draft.visitedLocationIds = action.visitedLocationIds;
        draft.prepopulatedLocationIds = [];
      });

    case 'FAVOURITED_LOCATION_ADD':
      return produce(state, draft => {
        // Remove the location from the list of visited locations
        // since we don't want the same location in both lists.
        draft.visitedLocationIds = draft.visitedLocationIds.filter(locationId => locationId !== action.locationId);

        // Remove the location from the list of prepopulated locations
        // since we don't want the same location in both lists.
        // This could happen when favouriting a prepopulated location
        // directly on the home page.
        draft.prepopulatedLocationIds = draft.prepopulatedLocationIds.filter(
          locationId => locationId !== action.locationId
        );

        // Remove the location from the list of favourited locations
        // so we don't end up with duplicate entries.
        draft.favouritedLocationIds = draft.favouritedLocationIds.filter(
          locationId => locationId !== action.locationId
        );

        // Add the location to the start of the list
        draft.favouritedLocationIds.unshift(action.locationId);
      });

    case 'FAVOURITED_LOCATION_REMOVE':
      return produce(state, draft => {
        // If the location does not exist in the list of favourited locations we shouldn't modify the state
        if (draft.favouritedLocationIds.includes(action.locationId) === false) {
          return;
        }

        draft.favouritedLocationIds = draft.favouritedLocationIds.filter(
          locationId => locationId !== action.locationId
        );
      });

    case 'VISITED_LOCATION_ADD':
      return produce(state, draft => {
        // If the location has already been favourited we should do nothing
        if (draft.favouritedLocationIds.includes(action.locationId)) {
          return;
        }

        // Whenever we add a new location to the list of visited locations
        // we want to shrink the list of prepopulated locations.
        if (draft.visitedLocationIds.includes(action.locationId) === false) {
          if (draft.prepopulatedLocationIds.includes(action.locationId)) {
            // The list of prepopulated locations includes the newly visited location
            // so we remove that specific location only.
            draft.prepopulatedLocationIds = draft.prepopulatedLocationIds.filter(
              locationId => locationId !== action.locationId
            );
          } else if (
            draft.visitedLocationIds.length + 1 + draft.prepopulatedLocationIds.length >
            settings.location.maxVisited
          ) {
            // Remove whichever location is last in the list of prepopulated locations,
            // but only if visited + prepopulated together is greater than the max number
            // of visited location.
            draft.prepopulatedLocationIds.pop();
          }
        }

        // Remove the location from the list of visited locations so we don't end up with duplicate entries
        draft.visitedLocationIds = draft.visitedLocationIds.filter(locationId => locationId !== action.locationId);

        // Add the location to the start of the list
        draft.visitedLocationIds.unshift(action.locationId);

        // Limit the number of visited locations
        draft.visitedLocationIds.splice(settings.location.maxVisited);
      });

    case 'VISITED_LOCATION_REMOVE':
      return produce(state, draft => {
        // If the location does not exist in the list of favourited locations we shouldn't modify the state
        if (draft.visitedLocationIds.includes(action.locationId) === false) {
          return;
        }

        draft.visitedLocationIds = draft.visitedLocationIds.filter(locationId => locationId !== action.locationId);
      });

    case 'PREPOPULATED_LOCATION_REMOVE':
      return produce(state, draft => {
        // If the location does not exist in the list of prepopulated locations we shouldn't modify the state
        if (draft.prepopulatedLocationIds.includes(action.locationId) === false) {
          return;
        }

        draft.prepopulatedLocationIds = draft.prepopulatedLocationIds.filter(
          locationId => locationId !== action.locationId
        );
      });

    default:
      throw new Error();
  }
}
