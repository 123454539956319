import { Icon } from '@nrk/yr-icons';
import { ClickableLink } from '../Clickable/Clickable';
import { Text } from '../Text/Text';

interface IProps {
  title: string;
  enabled: boolean;
  onClick: () => void;
}

export const Search__SuggestedGeolocationButton = (props: IProps) => {
  const { title, enabled, onClick } = props;

  if (enabled === false) {
    return (
      <li className="search__suggested-item search__suggested-item--disabled">
        <div className="search__suggested-link search__suggested-link--disabled">
          <Icon id="icon-geolocation" className="search__suggested-icon" />
          <span className="search__suggested-content">
            <Text size="4" weight="bold" color="inherit" className="search__suggested-title">
              {title}
            </Text>
          </span>
        </div>
      </li>
    );
  }

  return (
    <li className="search__suggested-item">
      {/* We render the GeolocationButton as a link because of some issues with focusing button inside tabIndex="-1" on Firefox (OS X) and Safari
          https://github.com/nrkno/core-components/issues/403
          https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Clicking_and_focus
      */}
      <ClickableLink href="" onClick={onClick} className="search__suggested-link search__suggested-link--geolocation">
        <Icon id="icon-geolocation" className="search__suggested-icon" />
        <span className="search__suggested-content">
          <Text size="4" weight="bold" color="inherit" className="search__suggested-title">
            {title}
          </Text>
        </span>
      </ClickableLink>
    </li>
  );
};
