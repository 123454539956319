import settings from '../../app/settings';
import { template } from '../../lib/string';
import {
  IAPINearestWaterTemperatures,
  IAPIWaterTemperature,
  IAPIWaterTemperaturesMaxMin,
  IAPIWaterTemperaturesReiseradioen
} from '../../model/api/waterTemperatures';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import {
  parseNearestWaterTemperatures,
  parseWaterTemperatureRegion,
  parseWaterTemperaturesMaxMin,
  parseWaterTemperaturesReiseradioen
} from './parse';

export function queryWaterTemperaturesByRegionId({
  localeCode,
  regionId
}: {
  localeCode: LocaleCode;
  regionId: string;
}) {
  const queryKey = ['waterTemperatureRegion', localeCode, regionId];

  async function queryFn() {
    const response = await api<IAPIWaterTemperature[]>({
      path: template(settings.api.paths.waterTemperatureRegion, { localeCode, regionId })
    });

    return parseWaterTemperatureRegion(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryNearestWaterTemperaturesByLocationId({
  localeCode,
  locationId
}: {
  localeCode: LocaleCode;
  locationId?: string;
}) {
  const queryKey = ['waterTemperatureLocation', localeCode, locationId];

  async function queryFn() {
    const response = await api<IAPINearestWaterTemperatures>({
      path: template(settings.api.paths.waterTemperatureLocation, { localeCode, locationId })
    });

    return parseNearestWaterTemperatures(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryWaterTemperaturesMaxMin({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['waterTemperaturesMaxMin', localeCode];

  async function queryFn() {
    const response = await api<IAPIWaterTemperaturesMaxMin>({
      path: template(settings.api.paths.waterTemperaturesMaxMin, { localeCode })
    });

    return parseWaterTemperaturesMaxMin(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryWaterTemperaturesReiseradioen({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['waterTemperaturesReiseradioen', localeCode];

  async function queryFn() {
    const response = await api<IAPIWaterTemperaturesReiseradioen>({
      path: template(settings.api.paths.waterTemperaturesReiseradioen, { localeCode })
    });

    return parseWaterTemperaturesReiseradioen(response);
  }

  return {
    queryKey,
    queryFn
  };
}
