// @ts-ignore
import numberUtils from '@nrk/yr-number-utils';
import { ITranslateFunction } from '../../model/translate';
import { getDirectionDescription, getSpeedWithDirection } from './direction';

const WIND_TYPES = [
  { value: 0.3, type: 'calm' },
  { value: 1.5, type: 'light air' },
  { value: 3.4, type: 'light breeze' },
  { value: 5.4, type: 'gentle breeze' },
  { value: 7.9, type: 'moderate breeze' },
  { value: 10.7, type: 'fresh breeze' },
  { value: 13.8, type: 'strong breeze' },
  { value: 17.1, type: 'moderate gale' },
  { value: 20.7, type: 'fresh gale' },
  { value: 24.4, type: 'strong gale' },
  { value: 28.4, type: 'storm' },
  { value: 32.6, type: 'violent storm' },
  { value: 1000, type: 'hurricane' }
];

// Return e.g. "light breeze"
export function getWindSpeedDescription(speed: number, translate: ITranslateFunction): string | undefined {
  for (let i = 0, n = WIND_TYPES.length; i < n; i++) {
    if (speed <= WIND_TYPES[i].value) {
      return translate(`wind/type/${[WIND_TYPES[i].type]}`);
    }
  }

  return;
}

// Return e.g. "light breeze from north west"
export function getWindDescriptionWithDirection(
  speed: number,
  direction: number,
  translate: ITranslateFunction,
  gust?: number
): string | undefined {
  const speedRounded = numberUtils.round(speed, 0);
  const isZero = speedRounded === 0;

  const speedText = getWindSpeedDescription(speedRounded, translate);

  if (isZero) {
    return gust != null
      ? `${speedText} ${translate('wind/gustDescription', { gust: numberUtils.round(gust) })}`
      : speedText;
  }

  const directionText = getDirectionDescription(direction, translate, false);
  return gust != null
    ? `${speedText} ${translate('direction/label')} ${directionText} ${translate('wind/gustDescription', {
        gust: numberUtils.round(gust)
      })}`
    : `${speedText} ${translate('direction/label')} ${directionText}`;
}

// Return e.g. "10 m/s light breeze from north west"
export function getDetailedWindDescriptionWithDirection(
  speed: number,
  direction: number,
  translate: ITranslateFunction,
  gust?: number
): string | undefined {
  const speedRounded = numberUtils.round(speed, 0);
  const isZero = speedRounded === 0;

  const speedText = `${speedRounded} ${translate('units/metersPerSecond/short')}`;

  if (isZero) {
    return gust != null
      ? `${speedText} ${translate('wind/gustDescription', { gust: numberUtils.round(gust) })}`
      : speedText;
  }

  const directionText = getWindDescriptionWithDirection(speed, direction, translate, gust);

  return `${speedText} ${directionText}`;
}

// Return e.g. "10 m/s with gusts at 15 m/s "
export function getDetailedWindDescriptionWithGust(translate: ITranslateFunction, speed: number, gust?: number) {
  const speedRounded = numberUtils.round(speed, 0);
  const gustRounded = gust != null ? numberUtils.round(gust, 0) : undefined;

  const windSpeedDescription = getWindSpeedDescription(speedRounded, translate);
  const speedText = `${speedRounded} ${translate('units/metersPerSecond/short')} ${windSpeedDescription}`;

  if (gustRounded == null || gustRounded === 0) {
    return speedText;
  }

  const gustText = `${translate('wind/gustDescription', { gust: gustRounded })}`;

  return `${speedText} ${gustText}`;
}

// Return e.g. "10 m/s from south with gusts at 15 m/s"
export function getWindSpeedWithDirectionAndGust(
  speed: number,
  direction: number,
  translate: ITranslateFunction,
  gust?: number
) {
  const gustRounded = gust != null ? numberUtils.round(gust, 0) : undefined;

  const speedText = getSpeedWithDirection(speed, direction, translate);
  if (gustRounded == null || gustRounded === 0) {
    return speedText;
  }

  const gustText = `${translate('wind/gustDescription', { gust: gustRounded })}`;

  return `${speedText} ${gustText}`;
}
