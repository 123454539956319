import time from '@nrk/yr-time';
import { IWarning, TWarningEventType, TWarningSeverity } from '../model/warning';

const severityRank = {
  Ultra: 4,
  Extreme: 3,
  Severe: 2,
  Moderate: 1,
  Minor: 0
};

export const allSeverities: TWarningSeverity[] = ['Moderate', 'Severe', 'Extreme'];

export const allEventTypes: TWarningEventType[] = [
  'Avalanches',
  'BlowingSnow',
  'Flood',
  'ForestFire',
  'Gale',
  'Ice',
  'Icing',
  'Landslide',
  'Lightning',
  'PolarLow',
  'Rain',
  'RainFlood',
  'Snow',
  'StormSurge',
  'Wind'
];

export function isWarningEventType(eventType: string): eventType is TWarningEventType {
  return allEventTypes.includes(eventType as TWarningEventType);
}

// Return a list of unique warnings sorted first by severity and then by start time.
// Doing two separate sorts is ok since Array.sort is stable for short arrays in most modern browsers.
// for the browsers that don't have a stable sorting algorithm, its fine.
// See https://mathiasbynens.be/demo/sort-stability
export function getSortedWarnings(warnings: IWarning[]) {
  const uniqueWarnings = getUniqueWarnings(warnings);

  // Sort by time first, then sort that sorted array by severity.
  // This means all extreme warnings are first in the sorted array,
  // and warnings with the same severity are sorted by time.
  return uniqueWarnings.sort(sortByStartTimeAscending).sort(sortBySeverityDescending);
}

/**
 * Get the starting time of the warning.
 */
function getWarningStart(warning: IWarning) {
  return warning.meta.onset ? warning.meta.onset : warning.meta.effective;
}

/**
 * Sort by severity {@link WarningSeverity} (Extreme > Severe > Moderate)
 * @param a
 * @param b
 */
export function sortBySeverityDescending(a: IWarning, b: IWarning) {
  return severityRank[b.meta.severity] - severityRank[a.meta.severity];
}

/**
 * Sort functions for sorting array of {@link IWarning} by when it start.
 * The array is sorted in ascending order, meaning that the earliest {@link IWarning} comes first
 */
function sortByStartTimeAscending(a: IWarning, b: IWarning) {
  const startA = getWarningStart(a);
  const startB = getWarningStart(b);

  if (startA === startB) {
    return 0;
  }

  if (startA == null) {
    // Sort a before b, assuming a missing start time means the warning is already active
    return 1;
  }

  if (startB == null) {
    // Sort b before a, assuming a missing start time means the warning is already active
    return -1;
  }

  return time.create(startA).diff(time.create(startB));
}

// Return a list of unique warnings where duplicate warnings with the same event type and severity
// have been filtered out.
function getUniqueWarnings(warnings: IWarning[]) {
  const uniqueWarnings: IWarning[] = [];

  warnings.forEach(warning => {
    const existingUniqueWarning = uniqueWarnings.find(
      uniqueWarning =>
        uniqueWarning.meta.eventType === warning.meta.eventType && uniqueWarning.meta.severity === warning.meta.severity
    );

    if (existingUniqueWarning == null) {
      uniqueWarnings.push(warning);
    }
  });

  return uniqueWarnings;
}

// Maps an array of warnings to an interval, so we can display warnings on the correct day.
export function mapWarningsToIntervals(intervals: Array<{ start: string; end: string }>, warnings: IWarning[]) {
  const parsedWarnings = warnings.map(warning => {
    return {
      start: getWarningStart(warning) !== undefined ? time.create(getWarningStart(warning)) : undefined,
      end: warning.meta.expires !== undefined ? time.create(warning.meta.expires) : undefined,
      warning
    };
  });

  return intervals.map(interval => {
    const intervalStart = time.create(interval.start);
    const intervalEnd = time.create(interval.end);
    const warningsForInterval: IWarning[] = [];

    parsedWarnings.forEach(parsedWarning => {
      if (
        parsedWarning.start != null &&
        parsedWarning.end != null &&
        intervalStart.isBefore(parsedWarning.end) &&
        intervalEnd.isAfter(parsedWarning.start)
      ) {
        warningsForInterval.push(parsedWarning.warning);
      }
    });

    return warningsForInterval;
  });
}

export function filterWarningsByEventTypes(warnings: IWarning[], eventTypes: TWarningEventType[]) {
  return warnings.filter(warning => eventTypes.includes(warning.meta.eventType));
}
