import classNames from 'classnames';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { testSmil } from '../../lib/browserFeatures';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IHelpOptionModal } from '../../model/help';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { Translate } from '../Translate/Translate';

interface IProps extends IHelpOptionModal {
  onClose: () => void;
}

export function Clippy__Modal(props: IProps) {
  const { illustration, link, keys, onClose } = props;

  const { currentPage } = useAppState();
  const translate = useTranslate();
  const localeCode = useLocaleCode();

  const { locationId, urlPath } = currentPage.details.params;

  // Only show illustration if the browser supports SVG SMIL animations
  const showIllustration = illustration != null && testSmil();

  return (
    <div
      data-testid="clippy/modal"
      className={classNames('clippy__modal', { 'clippy__modal--has-illustration': showIllustration })}
    >
      {showIllustration && illustration != null && (
        <img
          data-testid="clippy/modal-illustration"
          className="clippy__modal-illustration"
          src={illustration.src}
          alt=""
          width={illustration.width}
          height={illustration.height}
        />
      )}

      <div className="clippy__modal-body">
        <Heading level="h2" size="3" className="clippy__modal-title">
          {translate(keys.title)}
        </Heading>

        <Translate id={keys.body} splitLinebreaksAs="p" />

        <div className="clippy__modal-toolbar">
          {link != null && keys.link != null ? (
            <Button
              as="link"
              buttonType="primary"
              className="clippy__modal-close-button"
              onClick={onClose}
              href={createPageUrl({
                localeCode,
                pageId: link.pageId,
                subpageId: link.subpageId,
                locationId,
                urlPath
              })}
            >
              {translate(keys.link)}
            </Button>
          ) : (
            <Button as="button" buttonType="primary" className="clippy__modal-close-button" onClick={onClose}>
              {translate('grammar/ok')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
