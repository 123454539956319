import CoreDialog from '@nrk/core-dialog/jsx';
import noScroll from 'no-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { eventHasActiveModifiers, LEFT, RIGHT } from '../../lib/keyboard';
import { DistanceFromLocationWithElevation } from '../DistanceFromLocationWithElevation/DistanceFromLocationWithElevation';
import { Elevation } from '../Elevation/Elevation';
import { Heading } from '../Heading/Heading';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import { WebcamLabel } from '../WebcamLabel/WebcamLabel';
import { calculateImageSize, createWebcamUrlWithTimestamp } from './helpers';
import './WebcamModal.scss';

export interface IWebcamView {
  images: {
    small: {
      url: string;
      width?: number;
      height?: number;
    };
    medium: {
      url: string;
      width?: number;
      height?: number;
    };
    large: {
      url: string;
      width?: number;
      height?: number;
    };
  };
  direction?: {
    short: string;
    long: string;
    degrees: number;
  };
  description: string;
}

interface IProps {
  locationName?: string;
  cameraName: string;
  distance?: number;
  elevation?: number;
  source?: string;
  attribution?: string;
  // views: IApiLocationWebcamView[];
  // Ideally we'd use IApiLocationWebcamView directly but because the mountain pass
  // API differs a lot from the new webcam API we need a custom interface without
  // width and height for the images.
  // See https://nrknyemedier.atlassian.net/browse/YR-5061
  views: IWebcamView[];
  timestamp?: number;
  // We use the image's original size when we calculate how big the image should be shown to the user.
  // Ideally we'd just use width/height from the webcam and mountain pass APIs,
  // but this is currently not available.
  // Reading the image's size after this component has been mounted is slightly slow,
  // and because the entire modal is hidden until we have the image size this component would feel very
  // sluggish to the user. We use the thumbnail size during the first calculation so we can show the
  // modal to the user as soon as possible.
  thumbnailSize?: { width: number; height: number };
  onClose: () => void;
}

interface ISize {
  width: number;
  height: number;
}

export function WebcamModal(props: IProps) {
  const {
    locationName,
    cameraName,
    distance,
    elevation,
    source,
    attribution,
    views,
    timestamp,
    thumbnailSize,
    onClose
  } = props;

  const translate = useTranslate();
  const { isFirstRender } = useAppState();
  const coreDialogRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalSize, setModalSize] = useState<ISize>();
  const [imageSize, setImageSize] = useState<ISize | undefined>(thumbnailSize);
  const detailsRef = useRef<HTMLDivElement>(null);
  const [detailsSize, setDetailsSize] = useState<ISize>();
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const [buttonContainerSize, setButtonContainerSize] = useState<ISize>();
  const [activeViewIndex, setActiveViewIndex] = useState(0);
  const [calculatedImageSize, setCalculatedImageSize] = useState<{
    width: number;
    height: number;
  }>();

  useEffect(() => {
    noScroll.on();

    return () => {
      noScroll.off();
    };
  }, []);

  const previousView = useCallback(() => {
    let newActiveViewIndex = activeViewIndex - 1;
    if (newActiveViewIndex < 0) {
      newActiveViewIndex = views.length - 1;
    }

    setActiveViewIndex(newActiveViewIndex);
  }, [views, activeViewIndex]);

  const nextView = useCallback(() => {
    let newActiveViewIndex = activeViewIndex + 1;
    if (newActiveViewIndex === views.length) {
      newActiveViewIndex = 0;
    }

    setActiveViewIndex(newActiveViewIndex);
  }, [views, activeViewIndex]);

  // Navigate to the previous/next image when pressing left/right
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // Do nothing if if the key event has any active modifiers.
      // We don't want to override any potential browser shortcuts such as cmd+left/right
      if (eventHasActiveModifiers(event)) {
        return;
      }

      if (event.keyCode === LEFT) {
        previousView();
      }

      if (event.keyCode === RIGHT) {
        nextView();
      }
    }

    document.body.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [previousView, nextView]);

  // Read the sizes we need to calculate the layout
  useEffect(() => {
    function handleResize() {
      if (modalRef.current == null || detailsRef.current == null || buttonContainerRef.current == null) {
        return;
      }

      setModalSize(modalRef.current.getBoundingClientRect());
      setDetailsSize(detailsRef.current.getBoundingClientRect());
      setButtonContainerSize(buttonContainerRef.current.getBoundingClientRect());
    }

    // Run immediately
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate how large the image should be to scale up and down with the viewport.
  // Ideally we'd just do this with CSS but that turns out to be quite tricky.
  useEffect(() => {
    if (modalSize == null || imageSize == null || detailsSize == null || buttonContainerSize == null) {
      return;
    }

    const newCalculatedImageSize = calculateImageSize({
      modalSize,
      imageSize,
      // The padding can be in fixed dimensions since we don't want the padding to increase
      // when the user has a larger default font size.
      padding: {
        top: 16,
        right: 16 + buttonContainerSize.width,
        // Add enough padding at the bottom of the image to account for the height of the
        // footer when it is as a single row. To get this height we use the height of the
        // details element within the footer.
        bottom: 16 + detailsSize.height,
        left: 16 + buttonContainerSize.width
      }
    });

    setCalculatedImageSize(newCalculatedImageSize);
  }, [isFirstRender, modalSize, imageSize, detailsSize, buttonContainerSize]);

  // Check the image size after the first image has loaded.
  // This is only necessary since we don't yet get width/height from the mountain pass API.
  function handleImageLoad(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    const image = event.currentTarget;

    setImageSize({
      width: image.naturalWidth,
      height: image.naturalHeight
    });
  }

  const modalRoot = __BROWSER__ ? document?.querySelector('#modalRoot') : undefined;
  if (modalRoot == null) {
    return null;
  }

  const activeView = views[activeViewIndex];

  // Show the footer as a single row if the details element is less than half the width of the calculated image itself
  let showFooterAsASingleRow = false;
  if (detailsSize != null && calculatedImageSize != null) {
    showFooterAsASingleRow = detailsSize.width <= calculatedImageSize.width / 2;
  }

  return ReactDOM.createPortal(
    <div
      className="webcam-modal"
      data-testid="webcam-modal"
      ref={modalRef}
      // Since WebcamModal is dark also in light mode, we force the theme to allways be dark here
      data-theme="dark"
      data-visible={calculatedImageSize != null}
    >
      <CoreDialog
        onDialogToggle={onClose}
        className="webcam-modal__core-dialog"
        forwardRef={coreDialogRef}
        backdrop={'off'}
        aria-label={translate('roadCameraModal/label')}
      >
        <div className="webcam-modal__close-button">
          <IconButton
            as="button"
            buttonType="subtle"
            buttonSize="large"
            buttonShape="square"
            icon="icon-cross"
            ariaLabel={translate('grammar/close')}
            onClick={onClose}
          />
        </div>

        <div
          className="webcam-modal__image-container"
          style={{
            width: calculatedImageSize?.width,
            height: calculatedImageSize?.height
          }}
        >
          <img
            className="webcam-modal__image"
            src={createWebcamUrlWithTimestamp(activeView.images.large.url, timestamp)}
            width={activeView.images.large.width}
            height={activeView.images.large.height}
            alt={activeView.description}
            onLoad={handleImageLoad}
          />

          {activeView.direction != null && (
            <div className="webcam-modal__direction-label">
              <WebcamLabel label={activeView.direction.long} degrees={activeView.direction.degrees} />
            </div>
          )}
        </div>

        <div
          className="webcam-modal__button-container webcam-modal__button-container--left"
          // We only need to check the size of the left button container since the right should be the same size
          ref={buttonContainerRef}
          data-disabled={views.length < 2}
        >
          <IconButton
            as="button"
            buttonType="primary"
            buttonSize="large"
            buttonShape="rounded"
            icon="icon-chevron-left"
            ariaLabel={translate('webcamModal/previous')}
            disabled={views.length < 2}
            onClick={previousView}
          />
        </div>

        <div
          className="webcam-modal__button-container webcam-modal__button-container--right"
          data-disabled={views.length < 2}
        >
          <IconButton
            as="button"
            buttonType="primary"
            buttonSize="large"
            buttonShape="rounded"
            icon="icon-chevron-right"
            ariaLabel={translate('webcamModal/next')}
            disabled={views.length < 2}
            onClick={nextView}
          />
        </div>

        <div
          className="webcam-modal__footer"
          data-single-row={showFooterAsASingleRow}
          style={{
            width: calculatedImageSize?.width
          }}
        >
          <div className="webcam-modal__details" ref={detailsRef}>
            <Heading level="h3" size="3" color="primary">
              {cameraName}
            </Heading>
            {elevation != null && (
              <>
                {distance != null && locationName != null ? (
                  <DistanceFromLocationWithElevation
                    fromLocationName={locationName}
                    meters={distance}
                    elevation={elevation}
                  />
                ) : (
                  <Elevation elevation={elevation} />
                )}
              </>
            )}
            {/*
              TODO(scb): Attribution will no longer exist after the old API is gone
              See https://nrknyemedier.atlassian.net/browse/YR-5187
            */}
            {attribution != null && (
              <Text as="p" size="4" className="webcam-modal__attribution">
                {attribution}
              </Text>
            )}
            {attribution == null && source != null && (
              <Text as="p" size="4" className="webcam-modal__attribution">
                {translate('webcamModal/attribution', { source })}
              </Text>
            )}
          </div>

          {views.length > 1 && (
            <div className="webcam-modal__view-list">
              {views.map((view, index) => (
                <button
                  key={index}
                  className="webcam-modal__view-button"
                  aria-pressed={index === activeViewIndex}
                  onClick={() => {
                    setActiveViewIndex(index);
                  }}
                >
                  <img
                    className="webcam-modal__view-thumbnail"
                    // We use the large version of the image in the thumbnail to make
                    // it more likely the image has finished loading before the user
                    // switches to it.
                    src={createWebcamUrlWithTimestamp(view.images.large.url, timestamp)}
                    width={view.images.large.width}
                    height={view.images.large.height}
                    alt=""
                  />

                  {view.direction != null && (
                    <div className="webcam-modal__view-direction-label">
                      <WebcamLabel label={view.direction.short} degrees={view.direction.degrees} />
                    </div>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
      </CoreDialog>

      <div className="webcam-modal__backdrop" />
    </div>,
    modalRoot
  );
}
