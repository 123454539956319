const MAX_MODAL_WIDTH = 2000;
const MAX_MODAL_HEIGHT = 1000;
const MIN_IMAGE_SIZE = 200;

export function createWebcamUrlWithTimestamp(url: string, timestamp?: number) {
  if (timestamp == null) {
    return url;
  }

  // For images on vegvesen.no we only want to use the original URL.
  // This can be removed once the mountain pass APIs return URLs from
  // Yr's webcam proxy instead.
  if (url.indexOf('vegvesen.no') !== -1) {
    return url;
  }

  return `${url}?timestamp=${timestamp}`;
}

// Calculate how big the image should be to scale with the viewport
export function calculateImageSize({
  modalSize,
  imageSize,
  padding
}: {
  modalSize: { width: number; height: number };
  imageSize: { width: number; height: number };
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}) {
  // Calculate how much space is available to the image within the modal when subtracting the padding
  const availableImageWidth = Math.min(MAX_MODAL_WIDTH, modalSize.width) - padding.left - padding.right;
  const availableImageHeight = Math.min(MAX_MODAL_HEIGHT, modalSize.height) - padding.top - padding.bottom;

  // The image should fill up the available space, but should never shrink below a minimum width
  const maxImageWidth = Math.max(MIN_IMAGE_SIZE, availableImageWidth);
  const maxImageHeight = Math.max(MIN_IMAGE_SIZE, availableImageHeight);

  const imageRatio = imageSize.width / imageSize.height;
  const maxImageRatio = maxImageWidth / maxImageHeight;

  // Check if the aspect ratio of image is wider than the maximum area available to it
  if (imageRatio > maxImageRatio) {
    // Constrict the image based on the maximum width
    const width = Math.round(maxImageWidth);
    const height = Math.round((imageSize.height / imageSize.width) * width);

    return { width, height };
  } else {
    // Constrict the image based on the maximum height
    const height = Math.round(maxImageHeight);
    const width = Math.round((imageSize.width / imageSize.height) * height);

    return { width, height };
  }
}
