// This function sets focus to the correct element when navigating to a new page,
// or if you want to reset the focus.
// See https://nrknyemedier.atlassian.net/browse/YR-3370
export function focusPage() {
  const locationHeading = document.getElementById('location-heading');
  if (locationHeading != null) {
    locationHeading.focus();
    return;
  }

  const contentHeading = document.getElementById('content-heading');
  if (contentHeading != null) {
    contentHeading.focus();
    return;
  }

  const yrLogo = document.getElementById('yr-logo');
  if (yrLogo != null) {
    yrLogo.focus();
  }
}
