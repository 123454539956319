import coreAnalytics from '@nrk/core-analytics';
import { addGlobalContexts, newTracker, trackPageView, trackStructEvent } from '@snowplow/browser-tracker';
import { noop } from '../noop';
import { AnalyticsTrackParameters } from './model/analytics';

const SESSION_TIMEOUT = 30 * 60 * 1000;

class AnalyticsTracker {
  coreAnalytics: (...args: any) => any;
  snowplowIsActive: boolean;
  activeSessionUrl: string;

  constructor() {
    this.coreAnalytics = noop;
    this.snowplowIsActive = false;
    this.activeSessionUrl = '';

    // Window is not available on server and will fail
    if (__SERVER__) {
      return;
    }
    this.snowplowIsActive = window != null && !!window.spCollectorId;

    this.coreAnalytics = coreAnalytics('initialize', {});

    // initialize snowplow
    if (this.snowplowIsActive === true) {
      newTracker('yr-web', window.spCollectorId, {
        appId: 'no.nrk.yr.web',
        postPath: '/nrk/wd6',
        plugins: [],
        platform: 'web'
      });

      const globalContextEntity = {
        schema: 'iglu:no.nrk/service/jsonschema/2-0-0',
        //A simple way to differ from beta.yr.no while we dont have the setup to use enviorment variables
        data: { id: window.location.hostname === 'beta.yr.no' ? 'yrbeta' : 'yr' }
      };
      addGlobalContexts([globalContextEntity]);
    }
  }

  page(title: string, relativeUrl: string) {
    // Send pageview if code is running in right environment, and not active session
    if (relativeUrl !== this.activeSessionUrl) {
      this.activateSession(relativeUrl);

      this.coreAnalytics('pageview', {
        tnsSection: window.tnsSectionId
      });

      if (this.snowplowIsActive === true) {
        trackPageView({ title });
      }
    }
  }

  event({ category, action, label, value }: AnalyticsTrackParameters) {
    if (this.snowplowIsActive) {
      trackStructEvent({
        category,
        action,
        label,
        value
      });
    }
  }

  activateSession(url: string) {
    this.activeSessionUrl = url;
    setTimeout(() => {
      this.activeSessionUrl = '';
    }, SESSION_TIMEOUT);
  }
}

export const track = new AnalyticsTracker();
