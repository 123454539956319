import { IHelp } from '../../model/help';

export const help: IHelp = {
  default: ['frequentlyAskedQuestions', 'seeAllArticles'],
  pages: {
    home: [
      'coordinateSearch',
      'saveYourLocations',
      'findTheRightLocation',
      'frequentlyAskedQuestions',
      'seeAllArticles'
    ],
    forecast: [
      'coordinateSearch',
      'movedLongTermGraph',
      'hourlyForecast',
      'radarMap',
      'frequentlyAskedQuestions',
      'seeAllArticles'
    ],
    statistics: [
      'seeWeatherStatistics',
      'seeHistoricWeatherDataAsATable',
      'frequentlyAskedQuestions',
      'seeAllArticles'
    ],
    search: ['coordinateSearch', 'frequentlyAskedQuestions', 'seeAllArticles'],
    map: false,
    'standalone-map': false
  },
  options: {
    frequentlyAskedQuestions: {
      type: 'link',
      icon: 'icon-info',
      keys: {
        title: 'clippy/option/frequentlyAskedQuestions/title',
        subtitle: 'clippy/option/frequentlyAskedQuestions/subtitle',
        url: 'clippy/option/frequentlyAskedQuestions/url'
      }
    },
    saveYourLocations: {
      type: 'modal',
      icon: 'icon-star',
      illustration: {
        src: '/assets/images/animated/star.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/saveYourLocations/title',
        subtitle: 'clippy/option/saveYourLocations/subtitle',
        body: 'clippy/option/saveYourLocations/body'
      }
    },
    seeAllArticles: {
      type: 'link',
      icon: 'icon-help',
      keys: {
        title: 'clippy/option/seeAllArticles/title',
        url: 'clippy/option/seeAllArticles/url'
      }
    },
    findTheRightLocation: {
      type: 'modal',
      icon: 'icon-search',
      illustration: {
        src: '/assets/images/animated/search.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/findTheRightLocation/title',
        subtitle: 'clippy/option/findTheRightLocation/subtitle',
        body: 'clippy/option/findTheRightLocation/body'
      }
    },
    waterTemperatures: {
      type: 'modal',
      icon: 'icon-temperature',
      illustration: {
        src: '/assets/images/animated/temperature.svg',
        width: 312,
        height: 152
      },
      link: {
        pageId: 'water-temperatures-region-list'
      },
      keys: {
        title: 'clippy/option/waterTemperatures/title',
        subtitle: 'clippy/option/waterTemperatures/subtitle',
        body: 'clippy/option/waterTemperatures/body',
        link: 'clippy/option/waterTemperatures/link'
      }
    },
    hourlyForecast: {
      type: 'modal',
      icon: 'icon-clock',
      illustration: {
        src: '/assets/images/animated/clock.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/hourlyForecast/title',
        subtitle: 'clippy/option/hourlyForecast/subtitle',
        body: 'clippy/option/hourlyForecast/body'
      }
    },
    radarMap: {
      type: 'modal',
      icon: 'icon-umbrella-open',
      illustration: {
        src: '/assets/images/animated/umbrella.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/radarMap/title',
        subtitle: 'clippy/option/radarMap/subtitle',
        body: 'clippy/option/radarMap/body'
      }
    },
    seeWeatherStatistics: {
      type: 'modal',
      icon: 'icon-time-machine',
      illustration: {
        src: '/assets/images/animated/time-machine.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/seeWeatherStatistics/title',
        subtitle: 'clippy/option/seeWeatherStatistics/subtitle',
        body: 'clippy/option/seeWeatherStatistics/body'
      }
    },
    seeHistoricWeatherDataAsATable: {
      type: 'modal',
      icon: 'icon-table',
      illustration: {
        src: '/assets/images/animated/table.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/seeHistoricWeatherDataAsATable/title',
        subtitle: 'clippy/option/seeHistoricWeatherDataAsATable/subtitle',
        body: 'clippy/option/seeHistoricWeatherDataAsATable/body'
      }
    },
    movedLongTermGraph: {
      type: 'modal',
      icon: 'icon-graph',
      illustration: {
        src: '/assets/images/animated/graph.svg',
        width: 312,
        height: 152
      },
      link: {
        pageId: 'details',
        subpageId: 'graph'
      },
      keys: {
        title: 'clippy/option/movedLongTermGraph/title',
        subtitle: 'clippy/option/movedLongTermGraph/subtitle',
        body: 'clippy/option/movedLongTermGraph/body',
        link: 'clippy/option/movedLongTermGraph/link'
      }
    },
    coordinateSearch: {
      type: 'modal',
      icon: 'icon-location',
      illustration: {
        src: '/assets/images/animated/location.svg',
        width: 312,
        height: 152
      },
      keys: {
        title: 'clippy/option/coordinateSearch/title',
        subtitle: 'clippy/option/coordinateSearch/subtitle',
        body: 'clippy/option/coordinateSearch/body'
      }
    }
  }
};
