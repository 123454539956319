import { useEffect } from 'react';
import { useAppState } from '../app/contexts/AppStateContext';
import { requestNextAnimationFrame } from './helpers/frame';

/**
 * An effect that runs fn after the first render to the dom.
 * @param fn The function to run after first render
 */
export function useAnimationSafeAfterFirstRender(fn: () => void) {
  useAnimationSafeAfterDepsChange(fn, []);
}

function useAnimationSafeAfterDepsChange(callback: () => void, deps: any[]) {
  useEffect(() => {
    return requestNextAnimationFrame(callback);
  }, [deps, callback]);
}

export function useCurrentLocationId() {
  const { currentPage } = useAppState();

  const { locationId } = currentPage.details.params;

  return locationId;
}
