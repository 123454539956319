import { ClickableLink } from '../Clickable/Clickable';
import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';

interface IProps {
  title: string;
  onClick: (href: string, isCurrentPage: boolean) => void;
  href: string;
  isCurrentPage: boolean;
}

export const Search__SuggestedShowMore = (props: IProps) => {
  const { title, onClick, href, isCurrentPage } = props;

  return (
    <li className="search__suggested-item">
      <ClickableLink
        href={href}
        onClick={event => {
          event.preventDefault();
          onClick(href, isCurrentPage);
        }}
        className="search__suggested-link"
      >
        <Icon id="icon-search" className="search__suggested-icon" />
        <span className="search__suggested-content">
          <Text size="4" weight="bold" color="inherit" className="search__suggested-title">
            {title}
          </Text>
        </span>
      </ClickableLink>
    </li>
  );
};
