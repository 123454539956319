import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { Text } from '../Text/Text';
import './InfoBadgeButton.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export function InfoBadgeButton(props: IProps) {
  const { className, children, ...htmlProps } = props;

  return (
    <button {...htmlProps} className={classNames('info-badge-button', className)}>
      <Text size="5">{children}</Text>

      <span className="info-badge-button__circle">
        <Icon id="icon-i" size={2} />
      </span>
    </button>
  );
}
