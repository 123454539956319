// @ts-ignore
import numberUtils from '@nrk/yr-number-utils';
import { ITranslateFunction } from '../../model/translate';
import { clampDegrees } from './math';

const DIRECTIONS = [
  { value: 0, text: 'n' },
  { value: 45, text: 'ne' },
  { value: 90, text: 'e' },
  { value: 135, text: 'se' },
  { value: 180, text: 's' },
  { value: 225, text: 'sw' },
  { value: 270, text: 'w' },
  { value: 315, text: 'nw' }
];

export function getDirectionDescription(direction: number, translate: ITranslateFunction, short: boolean) {
  direction = clampDegrees(direction);

  const length = DIRECTIONS.length;
  const offset = 360 / length / 2;

  for (let i = 1; i < length; i++) {
    if (direction > DIRECTIONS[i].value - offset && direction <= DIRECTIONS[i].value + offset) {
      return translate(`direction/${DIRECTIONS[i].text}/${short ? 'short' : 'long'}`);
    }
  }

  return translate(`direction/${DIRECTIONS[0].text}/${short ? 'short' : 'long'}`);
}

export function getSpeedWithDirection(speed: number, direction: number, translate: ITranslateFunction) {
  const speedRounded = numberUtils.round(speed, 0);

  const directionText = `${translate('direction/label')} ${getDirectionDescription(direction, translate, false)}`;

  return `${speedRounded} ${translate('units/metersPerSecond/short')} ${directionText}`;
}
