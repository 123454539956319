import { getLocalStorageObject, isLocalStorageSupported, setLocalStorageObject } from '../../lib/helpers/localStorage';
import produce from 'immer';
import ms from 'ms';

interface IAutomaticReloadLocalStorageObject {
  timestamp: string;
}

export function storeReloadAttemptTimestamp() {
  if (isLocalStorageSupported()) {
    const previousObject = getLocalStorageObject<IAutomaticReloadLocalStorageObject>('automaticReload') ?? {
      timestamp: 0
    };

    const newObject = produce(previousObject, draft => {
      draft.timestamp = Date.now();
    });

    setLocalStorageObject('automaticReload', newObject);
  }
}

export function isItSafeToReload() {
  // If localStorage is not supported we don't want an automatic reload since
  // we cannot guard for infinite loops.
  if (isLocalStorageSupported() === false) {
    return false;
  }

  const previousObject = getLocalStorageObject<IAutomaticReloadLocalStorageObject>('automaticReload');

  // If we have no existing automaticReload object we can try to reload.
  if (previousObject == null) {
    return true;
  }

  const previousReloadTime = new Date(previousObject.timestamp);

  // If previousReloadTime is longer than one minute ago, we consider it safe to do automatic reload
  if (Date.now() - previousReloadTime.getTime() > ms('1m')) {
    return true;
  }

  return false;
}
