import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { getLocationFromQueryCache } from '../../data/locations/queries';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

type TSubpageId = 'table' | 'graph';

const SUBPAGE_KEYS: { [key in TSubpageId]: string } = {
  table: 'table',
  graph: 'graph'
};

export class DetailsPageHandler implements IPageHandler {
  getSettings(_props: { pageDetails: IPageDetails }) {
    return { isModal: false, showHeader: true, showFooter: true, expandedSearchInput: false };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId, locationId } = pageDetails.params;
    if (!isValidDetailsSubpageId(subpageId) || locationId == null) {
      return undefined;
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    if (location == null) {
      return undefined;
    }

    const locationName = location.name;
    const subpageKey = SUBPAGE_KEYS[subpageId];

    return {
      title: translate(`meta/details/${subpageKey}/title`, { locationName }),
      ogTitle: translate(`meta/details/${subpageKey}/ogTitle`, { locationName }),
      description: translate(`meta/details/${subpageKey}/description`, { locationName })
    };
  }

  async getDataQueries({ pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { locationId } = pageDetails.params;

    if (locationId == null) {
      throw new PageNotFoundError();
    }

    return [queryForecastByLocationId({ locationId })];
  }
}

function isValidDetailsSubpageId(subpageId?: string): subpageId is TSubpageId {
  if (subpageId == null) {
    return false;
  }

  return SUBPAGE_KEYS.hasOwnProperty(subpageId);
}
