import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiNowcast } from '../../model/api/nowcast';
import { api } from '../api';

export function queryNowcastByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['now', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IApiNowcast>({
      path: template(settings.api.paths.now, { locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
