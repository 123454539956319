import settings from '../../app/settings';
import { template } from '../../lib/string';
import { LocaleCode } from '../../model/locale';
import { IServiceAnnouncement } from '../../model/serviceAnnouncement';
import { api } from '../api';
import { parse } from './parse';

export function queryServiceAnnouncement({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['serviceAnnouncement', localeCode];

  async function queryFn() {
    const response = await api<IServiceAnnouncement>({
      path: template(settings.api.paths.serviceAnnouncement, { localeCode })
    });

    return parse(response);
  }

  return {
    queryKey,
    queryFn
  };
}
