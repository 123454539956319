import { createPageUrl } from '../../app/redirects';
import { ClickableLink } from '../Clickable/Clickable';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { ILocation } from '../../model/location';
import { snakeToCamel } from '../../lib/helpers/string';
import { Text } from '../Text/Text';
import { Badge } from '../Badge/Badge';

interface IProps {
  currentPageId: string;
  pageId: 'forecast' | 'details' | 'other-conditions' | '21-day-forecast' | 'map' | 'coast' | 'statistics';
  subpageId?: string;
  location: ILocation;
  showNewTag?: boolean;
}

export function LocationHeader__MenuItem(props: IProps) {
  const { currentPageId, pageId, subpageId, location, showNewTag = false } = props;

  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const href = createPageUrl({
    localeCode,
    pageId,
    subpageId,
    locationId: location.id,
    urlPath: location.urlPath
  });

  const label = translate(`locationHeader/page/${snakeToCamel(pageId)}`);
  const isCurrent = currentPageId === pageId;

  return (
    <li className="location-header__menu-item">
      <ClickableLink
        className="location-header__menu-link"
        href={href}
        aria-current={isCurrent ? 'page' : undefined}
        data-testid={`${pageId}/${subpageId}`}
      >
        <div className="location-header__menu-item-text">
          <Text size="4" weight="bold" className="location-header__active-menu-label">
            {label}
          </Text>
          <Text color="inherit" size="4" weight="normal" className="location-header__inactive-menu-label">
            {label}
          </Text>
        </div>

        {showNewTag && (
          <Badge className="location-header__menu-new-tag" type="new" size="small">
            {translate('locationHeader/newTag')}
          </Badge>
        )}
      </ClickableLink>
    </li>
  );
}
