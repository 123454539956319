import settings from '../../app/settings';
import { template } from '../../lib/string';
import { LocaleCode } from '../../model/locale';
import { IWarningList } from '../../model/warning';
import { api } from '../api';

export function queryWarnings({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['warnings', localeCode];

  async function queryFn() {
    const response = await api<IWarningList>({
      path: template(settings.api.paths.warnings, { localeCode })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryWarningsByLocationId({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['locationWarnings', localeCode, locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IWarningList>({
      path: template(settings.api.paths.locationWarnings, { localeCode, locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
