import classNames from 'classnames';
import { ITranslateFunction } from '../../model/translate';
import { IWarning } from '../../model/warning';
import { getSortedWarnings } from '../../modelHelpers/warning';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { WarningIcon } from '../WarningIcon/WarningIcon';
import './WarningsIconGroup.scss';

export interface IProps {
  warnings: IWarning[];
  stack?: boolean;
  size?: number;
}

export function WarningsIconGroup(props: IProps) {
  const { stack = false, warnings, size = 4 } = props;
  const translate = useTranslate();

  const sortedWarnings = getSortedWarnings(warnings);
  const a11yLabel = warnings.map(warning => getWarningIconA11yLabel(warning, translate)).join(', ');

  return (
    <span className={classNames('warnings-icon-group', { 'warnings-icon-group--stack': stack })}>
      <ul aria-hidden={true} className="warnings-icon-group__list">
        {/* Only display the first 3 warnings */}
        {sortedWarnings.slice(0, 3).map((warning, index) => (
          <li className="warnings-icon-group__icon" key={index} data-testid="warnings-icon-group__icon">
            <WarningIcon
              title={warning.meta.shortTitle}
              eventType={warning.meta.eventType}
              severity={warning.meta.severity}
              size={size}
            />
          </li>
        ))}
      </ul>

      <span className="nrk-sr">{a11yLabel}</span>
    </span>
  );
}

function getWarningIconA11yLabel(warning: IWarning, translate: ITranslateFunction) {
  const severityLabel = translate('warning/severityLabel', {
    severity: translate(`warning/enums/severity/${warning.meta.severity}`)
  });

  return `${warning.meta.shortTitle}: ${severityLabel}`;
}
