import { useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useFetchLocation } from '../../data/locations/hooks';
import { useCurrentLocationId } from '../../lib/hooks';
import { Search } from '../Search/Search';
import './PageHeader.scss';
import { PageHeader__Location } from './PageHeader__Location';
import { PageHeader__Logo } from './PageHeader__Logo';
import { PageHeader__Toolbar } from './PageHeader__Toolbar';

export function PageHeader() {
  const locationId = useCurrentLocationId();
  const { isFirstRender, currentPage } = useAppState();
  const { data: location } = useFetchLocation({ locationId });
  const pageHeaderRef = useRef<HTMLElement>(null);
  const [searchInputPositionRight, setSearchInputPositionRight] = useState(0);

  const pageId = currentPage.details.pageId;

  const searchId = 'page-header__search';
  const searchButtonId = 'page-header__search-button';
  const searchInputId = 'page-header__search-input';

  // Calculate what the right position of the search input container should be in
  // order for its right side to overlap the right side of the search button.
  // This value is made available to the CSS using a custom property.
  function handleToggleSearchInput(open: boolean) {
    if (open === false) {
      return;
    }

    const searchButton = document.getElementById(searchButtonId);
    if (pageHeaderRef.current == null || searchButton == null) {
      return;
    }

    const pageHeaderRect = pageHeaderRef.current.getBoundingClientRect();
    const searchButtonRect = searchButton.getBoundingClientRect();

    setSearchInputPositionRight(pageHeaderRect.right - searchButtonRect.right);
  }

  return (
    <header
      className="page-header"
      ref={pageHeaderRef}
      style={{
        // We need to use a type assertion to prevent TypeScript from warning about the custom property.
        // See https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
        ['--search-input-position-right' as any]: `${searchInputPositionRight}px`
      }}
    >
      <PageHeader__Logo />

      {isFirstRender === false && (
        <div className="page-header__search">
          <Search searchId={searchId} searchInputId={searchInputId} onToggleSearchInput={handleToggleSearchInput} />
        </div>
      )}

      <PageHeader__Toolbar currentPageId={pageId} searchButtonId={searchButtonId} searchInputId={searchInputId} />

      {/* TODO(as): re-implement functionality for clicking page-header__spacer to open search dialog*/}
      {location == null ? <div className="page-header__spacer" /> : <PageHeader__Location location={location} />}
    </header>
  );
}
