import { useIsFetching } from '@tanstack/react-query';
import { useAppState } from '../../app/contexts/AppStateContext';
import './LoadingBar.scss';

export function LoadingBar() {
  const { isFirstRender } = useAppState();
  const isFetching = useIsFetching();

  if (isFirstRender) {
    return null;
  }

  return (
    <div className="loading-bar" data-is-fetching={isFetching > 0}>
      <span className="loading-bar__bar" />
    </div>
  );
}
