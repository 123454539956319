import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { getLocationFromQueryCache } from '../../data/locations/queries';
import { queryStatistics } from '../../data/statistics/queries';
import { createStatisticsPeriodLabel, getStatisticsPeriodFromQuery } from '../../lib/helpers/statistics';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

type TSubpageId = 'graph' | 'table';

const SUBPAGE_KEYS: { [key in TSubpageId]: string } = {
  graph: 'graph',
  table: 'table'
};

export class StatisticsPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId, locationId } = pageDetails.params;
    if (!isValidStatisticsSubpageId(subpageId) || locationId == null) {
      return undefined;
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    if (location == null) {
      return undefined;
    }

    const locationName = location.name;
    const subpageKey = SUBPAGE_KEYS[subpageId];

    const period = getStatisticsPeriodFromQuery(pageDetails.query.q);
    const periodLabel = createStatisticsPeriodLabel({ period, translate });

    return {
      title: translate(`meta/statistics/${subpageKey}/title`, { locationName, periodLabel }),
      ogTitle: translate(`meta/statistics/${subpageKey}/ogTitle`, { locationName, periodLabel }),
      description: translate(`meta/statistics/${subpageKey}/description`, { locationName })
    };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, locationId } = pageDetails.params;
    if (locationId == null) {
      throw new PageNotFoundError();
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    if (location == null) {
      throw new PageNotFoundError();
    }

    // We do not support statistics for locations outside Norway yet,
    // and we do not link to the page either.
    if (location.isNorway === false) {
      throw new PageNotFoundError();
    }

    const period = getStatisticsPeriodFromQuery(pageDetails.query.q);

    return [queryStatistics({ localeCode, locationId, period })];
  }
}

function isValidStatisticsSubpageId(subpageId?: string): subpageId is TSubpageId {
  if (subpageId == null) {
    return false;
  }

  return SUBPAGE_KEYS.hasOwnProperty(subpageId);
}
