export class FetchResponseError extends Error {
  status: number;

  constructor(status: number, message?: string) {
    super(message);

    this.name = 'FetchResponseError';
    this.status = status;
  }
}

export class PageNotFoundError extends Error {
  status: number;

  constructor(message?: string) {
    super(message);

    this.name = 'PageNotFoundError';
    this.status = 404;
  }
}

export class PageRedirectError extends Error {
  status: number;
  url: string;

  constructor(url: string, message?: string) {
    super(message);

    this.name = 'PageRedirectError';
    this.status = 301;
    this.url = url;
  }
}

export class PageRenderingError extends Error {
  status: number;

  constructor(message?: string) {
    super(message);

    this.name = 'PageRenderingError';
    this.status = 500;
  }
}
