import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { useAppState } from '../../app/contexts/AppStateContext';
import { focusPage } from '../../lib/helpers/accessibility';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';
import { Text } from '../Text/Text';

interface IProps {
  id?: string;
  suggestedId: string;
  searchInputRef: React.RefObject<HTMLInputElement>;
  clearSearchRef: React.RefObject<HTMLButtonElement>;
  cancelSearchRef: React.RefObject<HTMLButtonElement>;
  onInputChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onInputClear: () => void;
  showClearSearchButton: boolean;
}

export const Search__Input = (props: IProps) => {
  const {
    id,
    suggestedId,
    searchInputRef,
    clearSearchRef,
    cancelSearchRef,
    onInputChange,
    onInputClear,
    showClearSearchButton
  } = props;
  const { currentPageSettings } = useAppState();
  const { expandedSearchInput } = currentPageSettings;

  const translate = useTranslate();

  return (
    <div className="search__input">
      {/*
        Disable eslint rule about unsupported aria props.
        aria-expanded is used in core-suggest, so we know here it is supported
      */}
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <input
        id={id}
        className={classNames('search__input-field', { 'search__input-field--hidden': expandedSearchInput === false })}
        type="text"
        list={suggestedId}
        placeholder={translate('nav/locationSearchPlaceholder')}
        aria-label={translate('nav/locationSearchPlaceholder')}
        onChange={event => onInputChange(event)}
        ref={searchInputRef}
        aria-expanded="false"
      />
      <Icon id="icon-search" className="search__input-icon" size={3} />
      {showClearSearchButton && (
        <ClickableButton
          type="button"
          tabIndex={-1}
          className="search__input-clear-button"
          aria-label={translate('nav/navigatorSearchClearLabel')}
          elementRef={clearSearchRef}
          onClick={event => {
            // prevent core-suggest from closing
            event.preventDefault();
            onInputClear();
          }}
        >
          <Icon id="icon-cross" className="search__input-clear-icon" size={2} />
        </ClickableButton>
      )}
      <ClickableButton
        type="button"
        className="search__input-cancel-button"
        elementRef={cancelSearchRef}
        onClick={() => {
          onInputClear();
          // focusing page closes core-suggest
          focusPage();
        }}
      >
        <Text size="4">{translate('nav/navigatorSearchCancelLabel')}</Text>
      </ClickableButton>
    </div>
  );
};
