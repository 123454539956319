import { WarningColor, WarningIconId, WarningIconType } from '../../model/icon';
import { ITranslateFunction } from '../../model/translate';
import { TWarningEventType, TWarningSeverity } from '../../model/warning';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { WarningIconImage } from '../WarningIconImage/WarningIconImage';

const GENERIC_EVENT_TYPE: WarningIconType = 'icon-warning-generic';

const EVENT_TYPE_MAP: { [K in TWarningEventType]: WarningIconType | undefined } = {
  Avalanches: 'icon-warning-avalanches',
  BlowingSnow: 'icon-warning-snow',
  Flood: 'icon-warning-flood',
  ForestFire: 'icon-warning-forestfire',
  Gale: 'icon-warning-wind',
  Ice: 'icon-warning-ice',
  Icing: 'icon-warning-generic',
  Landslide: 'icon-warning-landslide',
  Lightning: 'icon-warning-lightning',
  PolarLow: 'icon-warning-polarlow',
  Rain: 'icon-warning-rain',
  RainFlood: 'icon-warning-rainflood',
  Snow: 'icon-warning-snow',
  StormSurge: 'icon-warning-stormsurge',
  Wind: 'icon-warning-wind'
};

interface IProps {
  className?: string;
  title: string;
  eventType: TWarningEventType;
  severity: TWarningSeverity;
  size?: number;
}

export function WarningIcon(props: IProps) {
  const { className, title, eventType, severity, size } = props;
  const translate = useTranslate();

  const mappedEventType = EVENT_TYPE_MAP[eventType];
  const warningColor = mapSeverityToColor(severity);
  const warningId = mapWarningTypeToIconType({ eventType: mappedEventType, warningColor });
  const altText = getAltText({ title, severity, translate });

  return <WarningIconImage id={warningId} className={className} size={size} alt={altText} />;
}

function mapWarningTypeToIconType({
  eventType,
  warningColor
}: {
  eventType?: WarningIconType;
  warningColor: WarningColor;
}) {
  eventType = eventType != null ? eventType : GENERIC_EVENT_TYPE;

  // Extreme only has red color
  if (eventType === 'icon-warning-extreme') {
    return eventType;
  }

  return `${eventType}-${warningColor}` as WarningIconId;
}

function mapSeverityToColor(severity: TWarningSeverity): WarningColor {
  switch (severity) {
    case 'Moderate':
      return 'yellow';
    case 'Severe':
      return 'orange';
    case 'Extreme':
      return 'red';
    default:
      return 'yellow'; // Yellow was chosen arbitrarily as the default value.
  }
}

function getAltText({
  title,
  severity,
  translate
}: {
  title: string;
  severity: TWarningSeverity;
  translate: ITranslateFunction;
}) {
  const severityLabel = translate('warning/severityLabel', {
    severity: translate(`warning/enums/severity/${severity}`)
  });

  return `${title}: ${severityLabel}`;
}
