import { Icon } from '@nrk/yr-icons';
import { ILocation } from '../../model/location';
import { positionToDecimalDegrees } from '../../lib/format';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { OverlayPanel } from '../OverlayPanel/OverlayPanel';
import './LocationDetailsPanel.scss';
import { Text } from '../Text/Text';

interface IProps {
  openedById: string;
  location: ILocation;
  isFavourite: boolean;
  onToggleFavourite: () => void;
  onClose: () => void;
}

export function LocationDetailsPanel(props: IProps) {
  const { openedById, location, isFavourite, onToggleFavourite, onClose } = props;

  const translate = useTranslate();

  function handleToggleFavourite() {
    onToggleFavourite();
    onClose();
  }

  return (
    <OverlayPanel
      openedById={openedById}
      ariaLabel={translate('locationDetailsPanel/ariaLabel')}
      autofocusSelf={true}
      onClose={onClose}
    >
      <div className="location-details-panel" data-is-favourite={isFavourite}>
        <ClickableButton type="button" className="location-details-panel__button" onClick={handleToggleFavourite}>
          <Icon
            className="location-details-panel__button-icon"
            id={isFavourite ? 'icon-star-filled' : 'icon-star'}
            size={2}
          />

          <Text size="4" weight="bold" color="inherit" className="location-details-panel__button-label">
            {isFavourite ? translate('nav/favouritesButtonActiveLabel') : translate('nav/favouritesButtonLabel')}
          </Text>
        </ClickableButton>

        <div className="location-details-panel__details">
          {/* Hack: Force VoiceOver to read the full content, by using a span with role="text" */}
          <Text as="p" size="5" role="text">
            <LocationRegion location={location} />
          </Text>

          <Text as="p" size="5">
            {location.isCoordinate ? location.name : positionToDecimalDegrees({ position: location.position })}
          </Text>
        </div>
      </div>
    </OverlayPanel>
  );
}
