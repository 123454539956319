// List of browser errors we can safely ignore.
// Usually caused by badly written extensions,
// or official browser injected code such as Samsung Internet's reader mode.
// See https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
export const ignorableErrors = [
  // Ignore our middleware fetching error.
  // Ideally we'd check if `error instanceof MiddlewareError === true`
  // but that's not easy to do with the old Sentry/Raven SDK we use.
  // See https://nrknyemedier.atlassian.net/browse/YR-3271
  /^Error fetching .+$/,

  // Random plugins/extensions
  'top.GLOBALS',

  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
  'originalCreateNotification',

  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',

  // Facebook borked
  'fb_xd_fragment',

  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',

  'EBCallBackMessageReceived',

  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',

  // Ignore errors from a Chrome based browser on Samsung that sent 500k+ errors in a one week period.
  // See https://sentry.io/nrk/yr-frontend/issues/515019214/events/ (since deleted)
  'document.getElementsByClassName.ToString',

  // Ignore errors from some older devices running Chrome 73 or 74 - most likely caused by blocking access to cookies
  `Failed to execute 'pushState' on 'History'`,

  // When Smart Invert is enabled in iOS Safari runs JavaScript which fails when there is no
  // `<meta name="supported-color-schemes" content="...">` element on the page.
  // See https://twitter.com/levelsio/status/1187624477795835904
  `document.head.querySelector("meta[name='supported-color-schemes']").content`,

  // Google translate has troubles when trying to translate SVGs
  // https://github.com/airbnb/lottie-web/issues/1134
  `a[b].target.className.indexOf(Zb)`,

  // Ignore the maximum call stack size exceeded error we still haven't been able to solve.
  // The bug mainly affects mobile users in South Africa and we have been unable to reproduce
  // the issue. We were asked to ignore the error so we wouldn't use up the Sentry quota.
  // See https://sentry.io/organizations/nrk/issues/792991321/?project=275413
  // and https://nrknyemedier.atlassian.net/browse/YR-4203
  'Maximum call stack size exceeded'
];
