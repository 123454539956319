import settings from '../../app/settings';
import { template } from '../../lib/string';
import { TStatisticsPeriod, TStatisticsPeriodMonth } from '../../model/statistics';

export function createApiPath({
  locationId,
  period
}: {
  locationId: string;
  period: Exclude<TStatisticsPeriod, TStatisticsPeriodMonth>;
}) {
  if (period.type === 'last-13-months') {
    return template(settings.api.paths.statisticsLast13Months, { locationId });
  }

  if (period.type === 'last-30-days') {
    return template(settings.api.paths.statisticsLast30Days, { locationId });
  }

  if (period.type === 'last-24-hours') {
    return template(settings.api.paths.statisticsLast24Hours, { locationId });
  }

  if (period.type === 'year') {
    const year = period.time;

    return template(settings.api.paths.statisticsYear, { locationId, year });
  }

  if (period.type === 'day') {
    return template(settings.api.paths.statisticsDay, { locationId, date: period.time });
  }

  // @ts-ignore TypeScript correctly determines that we can't reach here with a valid period
  // object, but we still want to throw an exception in case we do so we need to tell TypeScript
  // to ignore this line.
  if (period.type != null) {
    // @ts-ignore
    throw new Error(`Can't create API path with type "${period.type}"`);
  }

  throw new Error(`Can't create API path`);
}
