import CoreDialog from '@nrk/core-dialog/jsx';
import classNames from 'classnames';
import { isAncestor } from '../../lib/element';
import noScroll from 'no-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './OverlayPanel.scss';

interface IAnimateFrom {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right' | 'center';
}

interface IRect {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
}

type OverlayPanelState = 'measuring' | 'layout' | 'will-animate' | 'is-animating';

interface IProps {
  openedById: string;
  ariaLabel?: string;
  /** - defaults to false
   *  - set to true if the overlay panel has no interactive elements
   *    or it doesn't make sense to focus the first interactive element
   *    and the overlay panel itself should be focused when opened.
   */
  autofocusSelf?: boolean;
  children?: React.ReactNode;
  testId?: string;
  onClose: () => void;
}

export function OverlayPanel(props: IProps) {
  const { ariaLabel, autofocusSelf = false, children, onClose, openedById, testId } = props;

  const dialogContainerRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<OverlayPanelState>('measuring');
  const [position, setPosition] = useState<{ top: number; left: number } | undefined>();
  const [transformOrigin, setTransformOrigin] = useState<string>('center center');
  const [fullscreen, setFullscreen] = useState(false);
  const [openedByRect, setOpenedByRect] = useState<IRect>();
  const [dialogContainerRect, setDialogContainerRect] = useState<IRect>();
  const bodyRect = document.body.getBoundingClientRect();

  // We want to toggle no scroll before the browser renders this component for the first time.
  useEffect(() => {
    noScroll.on();

    return () => {
      noScroll.off();
    };
  }, []);

  const measure = useCallback(() => {
    if (bodyRect == null || dialogContainerRef.current == null) {
      return;
    }

    // Create a rect in the center of the viewport we can use
    // in case we can't find the "opened by" element.
    const centerRect: IRect = {
      top: bodyRect.height / 2,
      right: bodyRect.width / 2,
      bottom: bodyRect.height / 2,
      left: bodyRect.width / 2,
      width: 0,
      height: 0
    };

    const openedByElement = document.getElementById(`${openedById}`);
    const openedByRect = openedByElement ? openedByElement.getBoundingClientRect() : centerRect;
    setOpenedByRect(openedByRect);

    const dialogContainerRect = dialogContainerRef.current.getBoundingClientRect();
    setDialogContainerRect(dialogContainerRect);

    setState('layout');
  }, [bodyRect, openedById]);

  const layout = useCallback(() => {
    if (openedByRect == null || bodyRect == null || dialogContainerRect == null) {
      return;
    }

    const openedByRectCenter = {
      vertical: openedByRect.top + (openedByRect.bottom - openedByRect.top) / 2,
      horizontal: openedByRect.left + (openedByRect.right - openedByRect.left) / 2
    };

    const bodyCenter = {
      vertical: bodyRect.height / 2,
      horizontal: bodyRect.width / 2
    };

    const animateFrom: IAnimateFrom = {
      vertical: openedByRectCenter.vertical < bodyCenter.vertical ? 'top' : 'bottom',
      horizontal: openedByRectCenter.horizontal < bodyCenter.horizontal ? 'left' : 'right'
    };

    const position = {
      left: 0,
      top: 0
    };

    switch (animateFrom.vertical) {
      case 'top': {
        position.top = openedByRect.bottom;
        break;
      }

      case 'bottom': {
        position.top = openedByRect.top - dialogContainerRect.height;
        break;
      }
    }

    switch (animateFrom.horizontal) {
      case 'left': {
        position.left = openedByRect.left;
        break;
      }

      case 'right': {
        position.left = openedByRect.right - dialogContainerRect.width;
        break;
      }

      case 'center': {
        position.left = openedByRect.right - openedByRect.width / 2 - dialogContainerRect.width / 2;
        break;
      }
    }

    // Center the overlay panel horizontally if it would otherwise be rendered outside the viewport
    if (position.left < 0 || position.left + dialogContainerRect.width > bodyRect.width) {
      position.left = (bodyRect.width - dialogContainerRect.width) / 2;
    }

    // Render the overlay as a fullscreen if it doesn't fit within the viewport in the desired position
    let isOutsideOfViewport = false;
    if (animateFrom.vertical === 'bottom' && position.top < 0) {
      isOutsideOfViewport = true;
    }
    // Safari on ios somehow gives us a wrong value from getBoundClientRect().bottom, hence we also need to check if
    // position.top is below 0 when animatefrom.vertical === 'top'
    if (animateFrom.vertical === 'top' && position.top < 0) {
      isOutsideOfViewport = true;
    }
    if (animateFrom.vertical === 'top' && position.top + dialogContainerRect.height > bodyRect.height) {
      isOutsideOfViewport = true;
    }

    if (isOutsideOfViewport) {
      setFullscreen(true);
      setState('will-animate');

      return;
    }

    setPosition(position);

    // Set the transform origin CSS property so the overlay panel will animate from the correct position
    setTransformOrigin(`${animateFrom.vertical} ${animateFrom.horizontal}`);

    setState('will-animate');
  }, [bodyRect, dialogContainerRect, openedByRect]);

  const startAnimating = useCallback(() => {
    setState('is-animating');
  }, []);

  useEffect(() => {
    if (state === 'measuring') {
      measure();
      return;
    }

    if (state === 'layout') {
      layout();
      return;
    }

    if (state === 'will-animate') {
      startAnimating();
      return;
    }
  }, [state, measure, layout, startAnimating]);

  // Close the overlay panel if the user clicks on the backdrop
  function handleOverlayPanelClick(event: React.MouseEvent) {
    if (dialogContainerRef.current == null) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    // Don't close the overlay panel if the user clicked inside the dialog
    if (isAncestor(dialogContainerRef.current, event.target)) {
      return;
    }

    onClose();
  }

  const modalRoot = document.querySelector('#modalRoot');
  if (modalRoot == null) {
    return null;
  }

  return ReactDOM.createPortal(
    // The `onClick` event handler on the div is only used as an extra way to close the overlay panel by clicking on the
    // backdrop, so it's not an accessibility-problem.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames('overlay-panel', {
        'overlay-panel--fullscreen': fullscreen
      })}
      onClick={handleOverlayPanelClick}
    >
      <CoreDialog className="overlay-panel__content" backdrop={'off'} onDialogToggle={onClose} style={position}>
        <div
          className={classNames('overlay-panel__animation-container', {
            'overlay-panel__animation-container--will-animate': state === 'will-animate',
            'overlay-panel__animation-container--is-animating': state === 'is-animating'
          })}
          style={{ transformOrigin }}
        >
          <div ref={dialogContainerRef} className="overlay-panel__dialog-wrapper">
            <div
              // A tabindex of `-1` means core-dialog will automatically focus this element
              // when the overlay panel is opened.
              tabIndex={autofocusSelf ? -1 : undefined}
              aria-label={ariaLabel}
              className="overlay-panel__dialog"
              data-testid={testId}
            >
              <div className="overlay-panel__children">{children}</div>
            </div>
          </div>
        </div>
        <div className="overlay-panel__backdrop" />
      </CoreDialog>
    </div>,
    modalRoot
  );
}
