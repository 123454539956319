import yrTime from '@nrk/yr-time';
import { sentenceCase } from '../string';
import { ITranslateFunction } from '../../model/translate';
import { createTimeLocale } from '../hooks/useTimeLocale';

/**
 * The time formats used by these helpers are documented on
 * https://confluence.nrk.no/pages/viewpage.action?pageId=152371833
 *
 * The test cases in `time.test.ts` also includes examples
 * of all the time formats supported by these helpers.
 */

export type TTimeType =
  | 'day-short'
  | 'day-long'
  | 'month-short'
  | 'month-long'
  | 'month-with-year-short'
  | 'month-with-year-long'
  | 'day-with-date-short'
  | 'day-with-date-medium'
  | 'day-with-date-long'
  | 'day-with-time'
  | 'date-short'
  | 'date-long'
  | 'date-with-year-short'
  | 'date-with-year-long'
  | 'date-with-time-short'
  | 'date-with-time-long'
  | 'date-with-year-and-time-long'
  | 'date'
  | 'hour'
  | 'hour-with-minutes'
  | 'numerical-day-short';

export type TRelativeTimeType =
  | 'relative-day-short'
  | 'relative-day-long'
  | 'relative-day-with-date-short'
  | 'relative-day-with-date-medium'
  | 'relative-day-with-date-long'
  | 'relative-date-short'
  | 'relative-date-long'
  | 'relative-date-with-time-short'
  | 'relative-date-with-time-long'
  | 'relative-date-with-time-long-date-fallback';

export type TTimeNumericalType = 'numerical-day-short';

export type TTimeWithHourIntervalTimeType =
  | 'day-with-date-short'
  | 'day-with-date-medium'
  | 'day-with-date-long'
  | 'date-short'
  | 'date-long';

type TTimeWithHourTimeType =
  | 'day-with-date-short'
  | 'day-with-date-medium'
  | 'day-with-date-long'
  | 'date-short'
  | 'date-long';

export type TRelativeTimeWithHourIntervalTimeType =
  | 'relative-day-with-date-medium'
  | 'relative-day-with-date-long'
  | 'relative-date-short'
  | 'relative-date-long';

export type TIntervalTimeType = 'month-with-year-long' | 'day-with-date-long' | 'hour' | 'date-short';

const TIME: Record<TTimeType, string> = {
  'day-short': 'time/mask/day/short',
  'day-long': 'time/mask/day/long',
  'month-short': 'time/mask/month/short',
  'month-long': 'time/mask/month/long',
  'month-with-year-short': 'time/mask/monthWithYear/short',
  'month-with-year-long': 'time/mask/monthWithYear/long',
  'day-with-date-short': 'time/mask/dayWithDate/short',
  'day-with-date-medium': 'time/mask/dayWithDate/medium',
  'day-with-date-long': 'time/mask/dayWithDate/long',
  'day-with-time': 'time/mask/dayWithTime',
  'date-short': 'time/mask/date/short',
  'date-long': 'time/mask/date/long',
  'date-with-year-short': 'time/mask/dateWithYear/short',
  'date-with-year-long': 'time/mask/dateWithYear/long',
  'date-with-time-short': 'time/mask/dateWithTime/short',
  'date-with-time-long': 'time/mask/dateWithTime/long',
  'date-with-year-and-time-long': 'time/mask/dateWithYearAndTime/long',
  date: 'time/mask/date',
  hour: 'time/mask/hour',
  'hour-with-minutes': 'time/mask/hourWithMinutes',
  'numerical-day-short': 'time/mask/numericalDay/short'
};

interface IRelativeTime {
  localeKeys: {
    yesterday: string;
    today: string;
    tomorrow: string;
  };
  fallbackTimeType: TTimeType;
}

const RELATIVE_TIME: Record<TRelativeTimeType, IRelativeTime> = {
  'relative-day-short': {
    localeKeys: {
      yesterday: 'time/mask/yesterday',
      today: 'time/mask/today',
      tomorrow: 'time/mask/tomorrow'
    },
    fallbackTimeType: 'day-short'
  },
  'relative-day-long': {
    localeKeys: {
      yesterday: 'time/mask/yesterday',
      today: 'time/mask/today',
      tomorrow: 'time/mask/tomorrow'
    },
    fallbackTimeType: 'day-long'
  },
  'relative-day-with-date-short': {
    localeKeys: {
      yesterday: 'time/mask/yesterday',
      today: 'time/mask/today',
      tomorrow: 'time/mask/tomorrow'
    },
    fallbackTimeType: 'day-with-date-short'
  },
  'relative-day-with-date-medium': {
    localeKeys: {
      yesterday: 'time/mask/dayWithDate/medium/yesterday',
      today: 'time/mask/dayWithDate/medium/today',
      tomorrow: 'time/mask/dayWithDate/medium/tomorrow'
    },
    fallbackTimeType: 'day-with-date-medium'
  },
  'relative-day-with-date-long': {
    localeKeys: {
      yesterday: 'time/mask/dayWithDate/long/yesterday',
      today: 'time/mask/dayWithDate/long/today',
      tomorrow: 'time/mask/dayWithDate/long/tomorrow'
    },
    fallbackTimeType: 'day-with-date-long'
  },
  'relative-date-short': {
    localeKeys: {
      yesterday: 'time/mask/yesterday',
      today: 'time/mask/today',
      tomorrow: 'time/mask/tomorrow'
    },
    fallbackTimeType: 'date-short'
  },
  'relative-date-long': {
    localeKeys: {
      yesterday: 'time/mask/yesterday',
      today: 'time/mask/today',
      tomorrow: 'time/mask/tomorrow'
    },
    fallbackTimeType: 'date-long'
  },
  'relative-date-with-time-short': {
    localeKeys: {
      yesterday: 'time/mask/dateWithTime/short/yesterday',
      today: 'time/mask/dateWithTime/short/today',
      tomorrow: 'time/mask/dateWithTime/short/tomorrow'
    },
    fallbackTimeType: 'date-with-time-short'
  },
  'relative-date-with-time-long': {
    localeKeys: {
      yesterday: 'time/mask/dateWithTime/long/yesterday',
      today: 'time/mask/dateWithTime/long/today',
      tomorrow: 'time/mask/dateWithTime/long/tomorrow'
    },
    fallbackTimeType: 'date-with-time-long'
  },
  // TODO(danjoh): Is this a bad name for it?
  'relative-date-with-time-long-date-fallback': {
    localeKeys: {
      yesterday: 'time/mask/dateWithTime/long/yesterday',
      today: 'time/mask/dateWithTime/long/today',
      tomorrow: 'time/mask/dateWithTime/long/tomorrow'
    },
    fallbackTimeType: 'day-with-time'
  }
};

/**
 * Create a readable time label from an ISO time string.
 */
export function createTimeLabel({
  type,
  time,
  translate,
  transform
}: {
  type: TTimeType;
  time: string;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const timeLocale = createTimeLocale(translate);
  const timeObject = yrTime.create(time).locale(timeLocale);

  const mask = translate(TIME[type]);
  const label = timeObject.format(mask);

  return transformIfNeeded(label, transform);
}

/**
 * Create a readable time label with hour from an ISO time string.
 */
export function createTimeWithHourLabel({
  type,
  time,
  translate,
  transform
}: {
  type: TTimeWithHourTimeType;
  time: string;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const label = translate('time/dateWithHour', {
    date: createTimeLabel({ type, time, translate }),
    time: createTimeLabel({ type: 'hour', time, translate })
  });

  return transformIfNeeded(label, transform);
}

/**
 * Create a readable relative time label from an ISO time string.
 */
export function createRelativeTimeLabel({
  type,
  time,
  isFirstRender,
  relativeTodayOnly = false,
  translate,
  transform
}: {
  type: TRelativeTimeType;
  time: string;
  isFirstRender: boolean;
  relativeTodayOnly?: boolean;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const relativeTime = RELATIVE_TIME[type];
  const timeLocale = createTimeLocale(translate);
  const timeObject = yrTime.create(time).locale(timeLocale);
  const timezoneOffset = timeObject.offset();

  // We only want to render a relative time in the browser.
  // We do this after the first render so the server and the first render in the browser
  // renders the same non-relative time.
  if (isFirstRender === false) {
    // Create a current time object with the same time zone as the time we were given
    // This is necessary because we only want to print "today"
    // if the given time is today in that time zone.
    const currentTime = yrTime.create().offset(timezoneOffset);

    const numberOfDaysSinceCurrentDay = timeObject.diff(currentTime, 'date');

    let dayDifference: 'yesterday' | 'today' | 'tomorrow' | undefined;
    switch (numberOfDaysSinceCurrentDay) {
      case -1: {
        if (relativeTodayOnly === false) {
          dayDifference = 'yesterday';
        }

        break;
      }

      case 0: {
        dayDifference = 'today';
        break;
      }

      case 1: {
        if (relativeTodayOnly === false) {
          dayDifference = 'tomorrow';
        }

        break;
      }
    }

    if (dayDifference !== undefined) {
      const mask = translate(relativeTime.localeKeys[dayDifference]);
      const label = timeObject.format(mask);

      return transformIfNeeded(label, transform);
    }
  }

  // Fallback to a regular time label
  return createTimeLabel({
    type: relativeTime.fallbackTimeType,
    time,
    transform,
    translate
  });
}

export function createTimeWithHourIntervalLabel({
  type,
  from,
  to,
  translate,
  transform
}: {
  type: TTimeWithHourIntervalTimeType;
  from: string;
  to: string;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const label = translate('time/dateWithHourInterval', {
    date: createTimeLabel({ type, time: from, translate }),
    from: createTimeLabel({ type: 'hour', time: from, translate }),
    to: createTimeLabel({ type: 'hour', time: to, translate })
  });

  return transformIfNeeded(label, transform);
}

export function createRelativeTimeWithHourIntervalLabel({
  type,
  from,
  to,
  isFirstRender,
  relativeTodayOnly = false,
  translate,
  transform
}: {
  type: TRelativeTimeWithHourIntervalTimeType;
  from: string;
  to: string;
  isFirstRender: boolean;
  relativeTodayOnly?: boolean;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const label = translate('time/dateWithHourInterval', {
    date: createRelativeTimeLabel({ type, time: from, isFirstRender, relativeTodayOnly, translate }),
    from: createTimeLabel({ type: 'hour', time: from, translate }),
    to: createTimeLabel({ type: 'hour', time: to, translate })
  });

  return transformIfNeeded(label, transform);
}

/**
 * Creates a readable interval label from two ISO time strings.
 */
export function createTimeIntervalLabel({
  type,
  from,
  to,
  translate,
  transform
}: {
  type: TIntervalTimeType;
  from: string;
  to: string;
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  const timeLocale = createTimeLocale(translate);
  const fromTimeObject = yrTime.create(from).locale(timeLocale);
  const toTimeObject = yrTime.create(to).locale(timeLocale);

  if (type === 'month-with-year-long') {
    const mask = translate('time/interval/monthWithYear/mask');
    const label = translate('time/interval/monthWithYear', {
      from: fromTimeObject.format(mask),
      to: toTimeObject.format(mask)
    });

    return transformIfNeeded(label, transform);
  }

  if (type === 'day-with-date-long') {
    const mask = translate('time/interval/dayWithDate/mask');
    const label = translate('time/interval/dayWithDate', {
      from: fromTimeObject.format(mask),
      to: toTimeObject.format(mask)
    });

    return transformIfNeeded(label, transform);
  }

  if (type === 'date-short') {
    const mask = translate('time/mask/date/short');
    const label = translate('time/interval', {
      from: fromTimeObject.format(mask),
      to: toTimeObject.format(mask)
    });

    return transformIfNeeded(label, transform);
  }

  const mask = translate('time/interval/hour/mask');
  const label = translate('time/interval/hour', {
    from: fromTimeObject.format(mask),
    to: toTimeObject.format(mask)
  });

  return transformIfNeeded(label, transform);
}

/**
 * Creates a readable hour interval label from a single ISO time string.
 * The label will either be for the previous hour up to the given time,
 * or the next hour starting from the given time,
 * depending on the `direction` parameter.
 */
export function createHourIntervalLabelFromTime({
  time,
  direction,
  translate,
  transform
}: {
  time: string;
  direction: 'previous-hour' | 'next-hour';
  translate: ITranslateFunction;
  transform?: 'sentence-case';
}) {
  let fromYrTime = yrTime.create(time);
  let toYrTime = yrTime.create(time);

  if (direction === 'next-hour') {
    toYrTime = toYrTime.add(1, 'hour');
  } else {
    fromYrTime = fromYrTime.subtract(1, 'hour');
  }

  return createTimeIntervalLabel({
    type: 'hour',
    from: fromYrTime.timeString,
    to: toYrTime.timeString,
    translate,
    transform
  });
}

// Convenience method to make return statements in createTimeLabel a bit more readable
const transformIfNeeded = (label: string, transform?: 'sentence-case') => {
  return transform === 'sentence-case' ? sentenceCase(label) : label;
};

/**
 * Find the day most of start and end overlap,
 * and return the earliest time of that day that falls within start and end.
 */
export function findDayStartTime(start: string, end: string) {
  const startTime = yrTime.create(start);
  const endTime = yrTime.create(end);

  // Find the exact middle between the start and end time.
  const startEndDifference = endTime.diff(startTime, 'milliseconds');
  const middleTime = startTime.add(startEndDifference / 2, 'milliseconds');

  // If the exact middle is the same day as the start time,
  // the start time represents the start of the day.
  if (middleTime.isSame(startTime, 'day')) {
    return startTime;
  }

  // If we get here, start and end must overlap the time
  // we've definied the start of day to be in @nrk/yr-time,
  // and the day most covered by start and end is the day end falls within.
  // The start time is then best represented by the start of the day end falls within.
  return middleTime.startOf('day');
}

//Create time label with hours and miniutes from a number of seconds
export function createHoursAndMinutesLabelFromSeconds({
  seconds,
  translate
}: {
  seconds: number;
  translate: ITranslateFunction;
}) {
  const totalAmountOfMinutes = Math.floor(seconds / 60);
  const amountOfMinutes = totalAmountOfMinutes % 60;
  const amountOfHours = Math.floor(totalAmountOfMinutes / 60);
  const hoursLabel = translate('time/amount/hours/short', { hours: amountOfHours });
  const minutesLabel = translate('time/amount/minutes/medium', { minutes: amountOfMinutes });

  if (amountOfHours === 0) {
    return minutesLabel;
  }

  if (amountOfMinutes === 0) {
    return hoursLabel;
  }

  return `${hoursLabel} ${minutesLabel}`;
}

// Create time label with hours and minutes from a number of minutes
export function createHoursAndMinutesLabelFromMinutes({
  minutes,
  translate
}: {
  minutes: number;
  translate: ITranslateFunction;
}) {
  const amountOfHours = Math.floor(minutes / 60);
  const amountOfMinutes = minutes % 60;
  const hoursLabel = translate('time/amount/hours/short', { hours: amountOfHours });
  const minutesLabel = translate('time/amount/minutes/medium', { minutes: amountOfMinutes });

  if (amountOfHours === 0) {
    return minutesLabel;
  }

  if (amountOfMinutes === 0) {
    return hoursLabel;
  }

  return `${hoursLabel} ${minutesLabel}`;
}

// Create time label with minutes and seconds from a number of seconds
export function createMinutesAndSecondLabelFromSeconds({
  seconds,
  translate
}: {
  seconds: number;
  translate: ITranslateFunction;
}) {
  const amountOfSeconds = seconds % 60;
  const amountOfMinutes = Math.floor(seconds / 60);
  const secondsLabel = translate('time/amount/seconds/medium', { seconds: amountOfSeconds });
  const minutesLabel = translate('time/amount/minutes/medium', { minutes: amountOfMinutes });

  if (amountOfMinutes === 0) {
    return secondsLabel;
  }

  if (amountOfSeconds === 0) {
    return minutesLabel;
  }
  return `${minutesLabel} ${secondsLabel}`;
}

export function isSameDate({ date, dateToCompare }: { date: string; dateToCompare: string }) {
  return yrTime.create(date).isSame(yrTime.create(dateToCompare), 'date');
}
