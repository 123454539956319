import { Icon } from '@nrk/yr-icons';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useFetchLocation } from '../../data/locations/hooks';
import { useCurrentLocationId } from '../../lib/hooks';
import { useMapSubpageId } from '../../lib/hooks/useMapSubpageId';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { smoothScroll } from '../../lib/scroll';
import { ILocation } from '../../model/location';
import { locationSupportsRadarOrLightning } from '../../modelHelpers/locations';
import './LocationHeader.scss';
import { LocationHeader__MenuItem } from './LocationHeader__MenuItem';

export function LocationHeader() {
  const { currentPage } = useAppState();
  const { pageId } = currentPage.details;

  const locationId = useCurrentLocationId();
  const { data: location } = useFetchLocation({ locationId });
  const translate = useTranslate();
  const listRef = useRef<HTMLOListElement>(null);
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState(false);

  const mapSubpageId = useMapSubpageId();

  useEffect(() => {
    const listElement = listRef.current;

    if (listElement == null) {
      return;
    }

    // Show the scroll buttons when the location header is scrolled
    // a little, but use a little grace buffer so the buttons won't
    // be shown if the location header is only scrolled a few pixels.
    const SCROLL_BUTTON_GRACE_BUFFER = 15;

    const checkScrollPosition = throttle(() => {
      const totalWidth = listElement.scrollWidth;
      const visibleWidth = Math.round(listElement.getBoundingClientRect().width);

      const scrollLeftOffset = listElement.scrollLeft;
      const scrollRightOffset = totalWidth - visibleWidth - scrollLeftOffset;

      setShowLeftScrollButton(scrollLeftOffset > SCROLL_BUTTON_GRACE_BUFFER);
      setShowRightScrollButton(scrollRightOffset > SCROLL_BUTTON_GRACE_BUFFER);
    }, 50);

    // Check the scroll position as soon as this effect runs
    checkScrollPosition();

    listElement.addEventListener('scroll', checkScrollPosition);
    window.addEventListener('resize', checkScrollPosition);

    return () => {
      checkScrollPosition.cancel();

      listElement.removeEventListener('scroll', checkScrollPosition);
      window.removeEventListener('resize', checkScrollPosition);
    };
  }, [listRef, location]);

  const handleScrollButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const listElement = listRef.current;
      if (listElement == null) {
        return;
      }

      const listElementWidth = Math.round(listElement.getBoundingClientRect().width);
      const targetScrollLeft = event.currentTarget.value === 'left' ? 0 : listElement.scrollWidth - listElementWidth;

      smoothScroll(listElement, targetScrollLeft);
    },
    [listRef]
  );

  if (location == null) {
    return null;
  }

  return (
    <nav className="location-header" id="locationHeader" role="navigation">
      <div className="layout-container">
        <div className="location-header__content">
          <button
            className="location-header__scroll-left-button"
            disabled={showLeftScrollButton === false}
            value="left"
            aria-label={translate('locationHeader/scrollLeft')}
            // @ts-ignore We use this attribute indicate which element will scroll.
            // Based on `@nrk/core-scroll`.
            htmlFor="location-header__list"
            onClick={handleScrollButtonClick}
          >
            <Icon id="icon-chevron-left" size={3} />
          </button>

          <button
            className="location-header__scroll-right-button"
            disabled={showRightScrollButton === false}
            value="right"
            aria-label={translate('locationHeader/scrollRight')}
            // @ts-ignore We use this attribute indicate which element will scroll.
            // Based on `@nrk/core-scroll`.
            htmlFor="location-header__list"
            onClick={handleScrollButtonClick}
          >
            <Icon id="icon-chevron-right" size={3} />
          </button>

          <ol className="location-header__list" id="location-header__list" ref={listRef}>
            <LocationHeader__MenuItem
              currentPageId={pageId}
              pageId="forecast"
              subpageId="daily-table"
              location={location}
            />

            <LocationHeader__MenuItem currentPageId={pageId} pageId="other-conditions" location={location} />

            <LocationHeader__MenuItem
              currentPageId={pageId}
              pageId="map"
              subpageId={getMapSubpageId(mapSubpageId, location)}
              location={location}
            />

            {location.hasSubseasonalforecast && (
              <LocationHeader__MenuItem
                currentPageId={pageId}
                pageId="21-day-forecast"
                location={location}
                showNewTag={false}
              />
            )}

            {location.hasCoast && (
              <LocationHeader__MenuItem
                currentPageId={pageId}
                pageId="coast"
                subpageId="forecast"
                location={location}
              />
            )}

            <LocationHeader__MenuItem currentPageId={pageId} pageId="details" subpageId="table" location={location} />

            {/* The statistics page is only available for locations in Norway */}
            {location.isNorway && (
              <LocationHeader__MenuItem
                currentPageId={pageId}
                pageId="statistics"
                subpageId="graph"
                location={location}
              />
            )}
          </ol>
        </div>
      </div>
    </nav>
  );
}

// If a user has last visited the radar map type and 'radar' is stored in local storage,
// we want to default to the weather map type if a user views the map at a location where
// the radar map is not supported.
function getMapSubpageId(subpageId: string, location: ILocation) {
  if (isMapSubpageId(subpageId) === false) {
    return;
  }

  if (locationSupportsRadarOrLightning(location)) {
    return subpageId;
  }

  return subpageId !== 'radar' && subpageId !== 'lightning' ? subpageId : 'weather';
}

function isMapSubpageId(subpageId: string) {
  return ['radar', 'weather', 'wind', 'lightning', 'temperature'].includes(subpageId);
}
