import CoreSuggest from '@nrk/core-suggest/jsx';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { useGeolocation } from '../../contexts/GeolocationContext/GeolocationContext';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { useFetchSuggestLocations } from '../../data/suggest/hooks';
import { track } from '../../lib/analytics/track';
import { ILocation } from '../../model/location';
import { isSamePage } from '../../model/page';
import { focusPage } from '../../lib/helpers/accessibility';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ISearchQueryCoordinate, ISearchQueryString } from './Search';
import { Search__Coordinate } from './Search__Coordinate';
import { Search__SuggestedGeolocationButton } from './Search__SuggestedGeolocationButton';
import { Search__SuggestedLocation } from './Search__SuggestedLocation';
import { Search__SuggestedShowMore } from './Search__SuggestedShowMore';
import { Search__UserLocation } from './Search__UserLocation';

interface IProps {
  id: string;
  query?: ISearchQueryString | ISearchQueryCoordinate;
  coreSuggestRef: React.RefObject<HTMLDivElement>;
  expanded: boolean;
  onInputClear: () => void;
}

export const Search__Suggestions = (props: IProps) => {
  const { id, query, coreSuggestRef, expanded, onInputClear } = props;

  const { currentPage, history } = useAppState();
  const { startGeolocation, isSupported: isGeolocationSupported } = useGeolocation();
  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const { favouritedLocationIds, visitedLocationIds } = useLocationList();
  const newSuggestedLocationsResult = useFetchSuggestLocations({
    query: query?.type === 'query' ? query.query : undefined
  });
  const [suggestedLocations, setSuggestedLocations] = useState<ILocation[]>();

  useEffect(() => {
    // If we have no query clear the suggested locations
    // so we show the default list of user locations again.
    if (query == null) {
      setSuggestedLocations(undefined);
      return;
    }

    if (query.type === 'query') {
      if (newSuggestedLocationsResult.isSuccess) {
        setSuggestedLocations(newSuggestedLocationsResult.data);
      } else if (newSuggestedLocationsResult.isError) {
        // TODO(scb): Handle errors better
      }
    }
  }, [query, newSuggestedLocationsResult]);

  function handleGeolocationClick() {
    onInputClear();
    focusPage();

    startGeolocation();
  }

  function handleShowMoreClick(href: string, isCurrentPage: boolean) {
    history.push(href);
    onInputClear();

    // If user is already on the search page we focus the page to close core-suggest
    if (isCurrentPage) {
      focusPage();
      return;
    }

    if (query?.type === 'coordinate') {
      track.event({ category: 'search', action: 'coordinate', label: 'choose_more' });
    }
  }

  function handleLocationClick({ locationId, href }: { locationId: string; href: string }) {
    const nextPageParams = { pageId: 'forecast', locationId };

    // If user clicks link to the current page we just close core-suggest by focusing the page
    if (isSamePage(currentPage.details.params, nextPageParams)) {
      onInputClear();
      focusPage();
      return;
    }

    if (query?.type === 'coordinate') {
      track.event({ category: 'search', action: 'coordinate', label: 'choose_result' });
    }

    history.push(href);
    onInputClear();
  }

  let listItems: React.ReactNode[] = [];

  // If there is no query we show the geolocation button
  if (query == null) {
    listItems.push(
      <Search__SuggestedGeolocationButton
        key="geolocation"
        title={translate('nav/geolocation/listLabel')}
        enabled={isGeolocationSupported}
        onClick={handleGeolocationClick}
      />
    );
  }

  if (query != null && query.type === 'coordinate') {
    listItems.push(<Search__Coordinate key="coordinate" locationId={query.locationId} onClick={handleLocationClick} />);
  } else if (suggestedLocations != null) {
    suggestedLocations.forEach(location => {
      listItems.push(<Search__SuggestedLocation key={location.id} location={location} onClick={handleLocationClick} />);
    });
  } else {
    const locationIds = [...favouritedLocationIds, ...visitedLocationIds];
    for (const locationId of locationIds) {
      listItems.push(<Search__UserLocation key={locationId} locationId={locationId} onClick={handleLocationClick} />);
    }
  }

  // Since the search in our search page is giving more results
  // we always show the show more button if we have a query
  if (query != null) {
    const title = query.type === 'query' ? translate('nav/moreLocations') : translate('nav/searchByCoordinate');
    const queryString = query.type === 'query' ? query.query : query.locationId;
    const href = createPageUrl({ localeCode, pageId: 'search', search: `?q=${queryString}` });

    listItems.push(
      <Search__SuggestedShowMore
        key="show-more"
        title={title}
        onClick={handleShowMoreClick}
        href={href}
        isCurrentPage={isSamePage(currentPage.details.params, { pageId: 'search' })}
      />
    );
  }

  return (
    <CoreSuggest
      className="search__suggestions"
      id={id}
      hidden={true}
      onSuggestSelect={(event: CustomEvent) => {
        // We call preventDefault() here to avoid core-suggest setting the input field value
        // https://github.com/nrkno/core-components/issues/385
        event.preventDefault();
      }}
      onSuggestFilter={(event: CustomEvent) => {
        // We call preventDefault() here to avoid core-suggest filtering search result
        // The filtering removes the "show more" link
        // https://github.com/nrkno/core-components/issues/385
        event.preventDefault();
      }}
      forwardRef={coreSuggestRef}
    >
      {expanded ? <ol>{listItems}</ol> : null}
    </CoreSuggest>
  );
};
