import classNames from 'classnames';
import { WarningIconId } from '../../model/icon';
import './WarningIconImage.scss';

interface IProps {
  id: WarningIconId;
  className?: string;
  size?: number;
  alt?: string;
}

export function WarningIconImage(props: IProps) {
  const { id, className, size, alt } = props;

  return (
    <img
      className={classNames('warning-icon-image', className)}
      src={`/assets/images/svg/${id}.svg`}
      alt={alt}
      data-testid="warning-icon-image"
      data-id={id}
      style={size != null ? { width: size * 8, height: size * 8 } : undefined}
    />
  );
}
