import classNames from 'classnames';
import { numberToString } from '../../lib/format';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { createTemperatureDisplayValue, isTemperatureWarm } from '@nrk/yr-helpers';
import './Temperature.scss';

interface IProps {
  value?: number;
  className?: string;
  decimal?: boolean;
  tone?: 'primary' | 'secondary' | 'plain';
  /** - defaults to true
   *  - set to false if rendered in table  */
  renderScreenReaderLabel?: boolean;
}

export const Temperature = (props: IProps) => {
  const { className = '', value, decimal = true, tone = 'primary', renderScreenReaderLabel = true } = props;

  const translate = useTranslate();

  if (value == null) {
    return null;
  }

  const unitLong = translate('units/celsius/long');
  const label = translate('terminology/temperature/long');

  const displayValue = createTemperatureDisplayValue({ value, decimal, unit: 'celsius' });

  return (
    <span
      className={classNames('temperature', className, {
        [`temperature--warm-${tone}`]: isTemperatureWarm({ value, decimal, unit: 'celsius' }) === true,
        [`temperature--cold-${tone}`]: isTemperatureWarm({ value, decimal, unit: 'celsius' }) === false
      })}
      role="text"
      data-testid="temperature"
    >
      {renderScreenReaderLabel && <span className="temperature__label nrk-sr">{label}</span>}
      {numberToString(displayValue, translate, decimal, {
        caller: '<Temperature>',
        originalValue: value
      })}
      <span className="temperature__degree" title={unitLong} aria-label={unitLong}>
        °
      </span>
    </span>
  );
};
