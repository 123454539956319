import settings from '../../../app/settings';
import { template } from '../../../lib/helpers/url';
import { LocaleCode } from '../../../model/locale';
import { ILocation } from '../../../model/location';
import { IPosition } from '../../../model/position';

export async function getPosition() {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if ('geolocation' in navigator === false) {
      reject();
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      () => {
        reject();
      },
      {
        enableHighAccuracy: false,
        // Firefox is really slow if we set maximumAge to anything else than 0
        // It seems like Firefox has some serious issues with getting the position from cache.
        maximumAge: 0,
        timeout: 12000
      }
    );
  });
}

export async function getLocationByPosition({
  localeCode,
  position
}: {
  localeCode: LocaleCode;
  position: IPosition;
}): Promise<ILocation | undefined> {
  const { lat, lon } = position;
  const apiPath = template(settings.api.paths.searchByPosition, { localeCode, lat, lon });
  const apiUrl = `${settings.api.base.client}/${apiPath}`;

  const response = await fetch(apiUrl);
  if (response.ok === false) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  const locations = data?._embedded?.location;

  return locations != null ? locations[0] : undefined;
}
