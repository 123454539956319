import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { getLocationFromQueryCache, queryLocation } from '../../data/locations/queries';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class MapPageHandler implements IPageHandler {
  getSettings({ pageDetails }: { pageDetails: IPageDetails }) {
    const embedded = pageDetails.query.embedded;

    return {
      isModal: false,
      showHeader: embedded === false,
      showFooter: false,
      expandedSearchInput: false
    };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, locationId, subpageId: mapType } = pageDetails.params;

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    const locationName = location?.name;

    const title = translate(`meta/map/${mapType}/title`, { locationName });
    const ogTitle = translate(`meta/map/${mapType}/ogTitle`, { locationName });
    const description = translate(`meta/map/${mapType}/description`);

    return { title, ogTitle, description };
  }

  async getDataQueries({ pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { locationId, localeCode } = pageDetails.params;
    if (locationId == null) {
      throw new PageNotFoundError();
    }

    return [queryLocation({ localeCode, locationId })];
  }
}
