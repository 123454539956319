// @ts-ignore
import numberUtils from '@nrk/yr-number-utils';
import { numberToString } from '../../lib/format';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Elevation } from '../Elevation/Elevation';
import { Text } from '../Text/Text';
import { Translate } from '../Translate/Translate';

interface IProps {
  fromLocationName: string;
  meters: number;
  elevation: number;
}

export function DistanceFromLocationWithElevation(props: IProps) {
  const { fromLocationName, meters, elevation } = props;

  const translate = useTranslate();

  const km = numberUtils.round(meters / 1000, 1);
  const kmLabel = numberToString(km, translate);

  return (
    <Text size="4">
      <Translate
        id="grammar/kmDistanceFromWithElevation"
        values={{
          kilometers: kmLabel,
          locationName: fromLocationName,
          elevation: <Elevation elevation={elevation} />
        }}
      />
    </Text>
  );
}
