import { IPoint } from '../../model/point';

export const degreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

export const radiansToDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

export const rescale = (x: number, inMin: number, inMax: number, outMin: number, outMax: number) => {
  return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

// Clamp degrees between 0° and 360°
export function clampDegrees(degrees: number) {
  // Clamp degrees between -360° and 360°
  degrees = degrees % 360;

  // Clamp degrees between 0° and 360°
  if (degrees < 0) {
    degrees = 360 - Math.abs(degrees);
  }

  return degrees;
}

export function calculateAngleBetweenPoints(a: IPoint, b: IPoint) {
  const dx = b.x - a.x;
  const dy = b.y - a.y;

  const radians = Math.atan2(dy, dx);
  const degrees = radiansToDegrees(radians);

  return clampDegrees(degrees);
}

// Convert angle (0° and 360° where 0° is east, values increasing counter-clockwise)
// to a cartographical azimuth (0° and 360° where 0° is north, values increasing clockwise)
export function convertDegreesToCartographicalAzimuth(degrees: number) {
  return clampDegrees(-1 * degrees + 90);
}

/**
 * Normalize value within min-max to range [0,1]
 */
export function normalizeValueWithinRange(min: number, max: number, value: number) {
  return (value - min) / (max - min);
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}
