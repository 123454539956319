import {
  IAPINearestWaterTemperatures,
  IAPIWaterTemperature,
  IAPIWaterTemperaturesMaxMin,
  IAPIWaterTemperaturesReiseradioen
} from '../../model/api/waterTemperatures';
import {
  INearestWaterTemperature,
  IWaterTemperature,
  IWaterTemperaturesMaxMin,
  IWaterTemperaturesReiseradioen
} from '../../model/waterTemperatures';

export function parseWaterTemperatureRegion(data: IAPIWaterTemperature[]): IWaterTemperature[] {
  return data;
}

export function parseNearestWaterTemperatures(data: IAPINearestWaterTemperatures): INearestWaterTemperature[] {
  return data._embedded.nearestLocations;
}

export function parseWaterTemperaturesMaxMin(data: IAPIWaterTemperaturesMaxMin): IWaterTemperaturesMaxMin {
  return data._embedded;
}

export function parseWaterTemperaturesReiseradioen(
  data: IAPIWaterTemperaturesReiseradioen
): IWaterTemperaturesReiseradioen {
  return data._embedded;
}
