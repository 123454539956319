import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { queryCoastForecastByLocationId } from '../../data/coast/queries';
import { getLocationFromQueryCache } from '../../data/locations/queries';
import { queryObservationsByLocationId } from '../../data/observations/queries';
import { queryTideByLocationId } from '../../data/tide/queries';
import { queryWarningsByLocationId } from '../../data/warnings/queries';
import { queryWaterLevelByLocationId } from '../../data/waterLevel/queries';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

type TSubpageId = 'forecast' | 'hourly-table' | 'table' | 'graph';

const SUBPAGE_KEYS: { [key in TSubpageId]: string } = {
  forecast: 'forecast',
  'hourly-table': 'hourlyTable',
  graph: 'graph',
  table: 'table'
};

export class CoastPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, subpageId, locationId } = pageDetails.params;
    if (!isValidCoastSubpageId(subpageId) || locationId == null) {
      return undefined;
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    if (location == null) {
      return undefined;
    }

    const locationName = location.name;
    const subpageKey = SUBPAGE_KEYS[subpageId];

    return {
      title: translate(`meta/coast/${subpageKey}/title`, { locationName }),
      ogTitle: translate(`meta/coast/${subpageKey}/ogTitle`, { locationName }),
      description: translate(`meta/coast/${subpageKey}/description`)
    };
  }

  async getDataQueries({ pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, locationId } = pageDetails.params;
    if (locationId == null) {
      throw new PageNotFoundError();
    }

    return [
      queryTideByLocationId({ localeCode, locationId }),
      queryWaterLevelByLocationId({ localeCode, locationId }),
      queryCoastForecastByLocationId({ localeCode, locationId }),
      queryWarningsByLocationId({ localeCode, locationId }),
      queryObservationsByLocationId({ locationId })
    ];
  }
}
function isValidCoastSubpageId(subpageId?: string): subpageId is TSubpageId {
  if (subpageId == null) {
    return false;
  }

  return SUBPAGE_KEYS.hasOwnProperty(subpageId);
}
