import { useMemo } from 'react';
import { createTranslateFunction } from '../helpers/translations';
import { useLocaleCode } from './useLocaleCode';

export function useTranslate() {
  const localeCode = useLocaleCode();

  const translate = useMemo(() => {
    return createTranslateFunction(localeCode);
  }, [localeCode]);

  return translate;
}
