import { useTranslate } from '../../lib/hooks/useTranslate';
import { IconButton } from '../IconButton/IconButton';

interface IBaseProps {
  as: 'button' | 'link';
  buttonSize?: 'small' | 'large';

  // Disallow data attributes we use internally
  // to prevent anyone from trying to use these by accident
  'data-type'?: never;
  'data-size'?: never;
  'data-shape'?: never;
  'data-disabled'?: never;
}

interface IButtonProps extends IBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
  children?: never;
}

interface ILinkProps extends IBaseProps, React.LinkHTMLAttributes<HTMLAnchorElement> {
  as: 'link';
  href: string;
  children?: never;
}

export function CloseButton(props: IButtonProps | ILinkProps) {
  const translate = useTranslate();

  if (props.as === 'button') {
    const { as, buttonSize = 'small', className, ...htmlProps } = props;

    return (
      <IconButton
        as="button"
        buttonType="subtle"
        buttonSize={buttonSize}
        buttonShape="square"
        className={className}
        icon="icon-cross"
        ariaLabel={props['aria-label'] ?? translate('grammar/close')}
        {...htmlProps}
      />
    );
  }

  if (props.as === 'link') {
    const { as, buttonSize = 'small', className, ...htmlProps } = props;

    return (
      <IconButton
        as="link"
        buttonType="subtle"
        buttonSize={buttonSize}
        buttonShape="square"
        className={className}
        icon="icon-cross"
        ariaLabel={props['aria-label'] ?? translate('grammar/close')}
        {...htmlProps}
      />
    );
  }

  return null;
}
