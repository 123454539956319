import classNames from 'classnames';
import { useTranslate } from '../../lib/hooks/useTranslate';
import './LogoNrk.scss';

interface IProps {
  className?: string;
}

export function LogoNrk(props: IProps) {
  const { className } = props;
  const translate = useTranslate();

  // TODO(as): In order to change colors based on theme we have copied the entire SVG into this file instead of loading it with <img src="" />
  // In the future we should probably use @nrk/core-icons, or we could make a Logo component aswell in the yr-icons repo ( as we have done with Icon )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('logo-nrk', className)}
      width="68"
      height="24"
      viewBox="0 0 68 24"
      role="img"
      aria-label={translate('logoNrk/label')}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.662 4.234C18.24 2.077 16.352.451 14.09.451H8.783L14.113 24h8.97l-4.42-19.766zM0 24h8.237V.45H.001V24zm23.677 0h8.253V.45h-8.253V24zM37.174 0c-2.582 0-4.675 2.11-4.675 4.715s2.093 4.723 4.675 4.723c2.588 0 4.689-2.118 4.689-4.723 0-2.604-2.101-4.714-4.69-4.714zm5.206 24h8.26V.45h-8.26V24zm18.634-10.677c-.496-.835-.52-1.35-.046-2.158L67.514.451h-9.08s-5.457 8.914-6.203 10.152c-.74 1.238-.706 2.008.031 3.28C53.008 15.15 58.434 24 58.434 24h9.08s-6.427-10.546-6.5-10.678z"
        clipRule="evenodd"
      />
    </svg>
  );
}
