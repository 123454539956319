import { Icon } from '@nrk/yr-icons';
import { useState } from 'react';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { ILocation } from '../../model/location';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton } from '../Clickable/Clickable';
import { LocationDetailsPanel } from '../LocationDetailsPanel/LocationDetailsPanel';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

interface IProps {
  location: ILocation;
}

export const PageHeader__Location = (props: IProps) => {
  const { location } = props;

  const translate = useTranslate();
  const { favouritedLocationIds, addFavouritedLocation, removeFavouritedLocation } = useLocationList();
  const [showLocationDetailsPanel, setShowLocationDetailsPanel] = useState(false);

  const isFavourite = favouritedLocationIds.some(favouriteLocationId => favouriteLocationId === location.id);

  function handleToggleFavourite() {
    if (isFavourite) {
      removeFavouritedLocation({
        locationId: location.id,
        locationName: location.name,
        translate
      });
    } else {
      addFavouritedLocation({
        locationId: location.id,
        locationName: location.name,

        translate
      });
    }
  }

  return (
    <div className="page-header__location">
      <Heading level="h1" size="2" className="page-header__location-header" tabIndex={-1} id="location-heading">
        <span className="page-header__location-name"> {location ? location.name : ''}</span>

        {isFavourite ? (
          <Icon
            className="page-header__location-star page-header__location-star--filled"
            id="icon-star-filled"
            size={2}
          />
        ) : (
          <Icon className="page-header__location-star page-header__location-star--empty" id="icon-star" size={2} />
        )}
      </Heading>
      {
        // Hack: Force VoiceOver to read the full content, by using a span with role="text"
        <Text size="5" className="page-header__location-details" role="text">
          <LocationRegion location={location} />
        </Text>
      }

      <ClickableButton
        className="page-header__location-button"
        id="page-header__location-button"
        onClick={() => {
          setShowLocationDetailsPanel(true);
        }}
      >
        {translate('header/locationButtonLabel')}
      </ClickableButton>

      {showLocationDetailsPanel ? (
        <LocationDetailsPanel
          openedById="page-header__location-button"
          location={location}
          isFavourite={isFavourite}
          onToggleFavourite={handleToggleFavourite}
          onClose={() => {
            setShowLocationDetailsPanel(false);
          }}
        />
      ) : null}
    </div>
  );
};
