import { TIconId } from '@nrk/yr-icons';
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { initialState, reducer } from './helpers/reducer';

interface IProps {
  children: React.ReactNode;
}

export type TToastType = 'ERROR' | 'NOTIFICATION';

export interface IToastContent {
  iconId?: TIconId;
  title?: string;
  text: string;
}

interface IContext {
  id?: number;
  type?: TToastType;
  content?: IToastContent;
  addToast: ({ type, content }: { type: TToastType; content: IToastContent }) => void;
  removeToast: () => void;
}

const ToastContext = createContext<IContext | undefined>(undefined);

export function ToastProvider(props: IProps) {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const addToast = useCallback(({ type, content }: { type: TToastType; content: IToastContent }) => {
    dispatch({ type, content });
  }, []);

  const removeToast = useCallback(() => {
    dispatch({ type: 'REMOVE' });
  }, []);

  const value = useMemo(() => {
    const { id, type, content } = state;

    return {
      id,
      type,
      content,
      addToast,
      removeToast
    };
  }, [state, addToast, removeToast]);

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}

export function useToast() {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
