import { useTranslate } from '../../lib/hooks/useTranslate';
import { IconButton } from '../IconButton/IconButton';

interface IProps {
  buttonSize?: 'small' | 'large';
  id?: string;
  className?: string;
  ariaLabel?: string;
  onClick: () => void;
}

export const InfoButton = (props: IProps) => {
  const { buttonSize = 'small', id, className, ariaLabel, onClick } = props;
  const translate = useTranslate();

  return (
    <IconButton
      as="button"
      buttonSize={buttonSize}
      buttonShape="rounded"
      id={id}
      className={className}
      icon="icon-i"
      ariaLabel={ariaLabel ?? translate('infoButton/ariaLabel')}
      onClick={onClick}
    />
  );
};
