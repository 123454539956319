import { forwardRef } from 'react';
import { Card__Empty } from './Card__Empty';
import { ErrorState } from '../ErrorState/ErrorState';
import './Card.scss';
import classNames from 'classnames';

// Reexported components
export { Card__LinkFooter as CardLinkFooter } from './Card__LinkFooter';
export { Card__Header as CardHeader } from './Card__Header';

interface ICardProps {
  type?: 'card';
  footer?: React.ReactNode;
  children?: React.ReactNode;
  testId?: string;
  contentMargin?: boolean;
  className?: string;
}

interface IEmptyCardProps {
  type: 'empty';
  children: React.ReactNode;
  renderFooter?: true;
  renderHeader?: false;
  contentMargin?: boolean;
  className?: string;
}

interface IErrorCardProps {
  type: 'error';
  errorMessage?: string;
  errorSize?: 'small' | 'large';
  children?: React.ReactNode;
  footer?: React.ReactNode;
  contentMargin?: boolean;
  className?: string;
}

type IProps = ICardProps | IErrorCardProps | IEmptyCardProps;

export const Card = forwardRef((props: IProps, ref: React.Ref<HTMLDivElement>) => {
  const { type = 'card', contentMargin = true, className } = props;

  if (type === 'empty') {
    const emptyChildren = 'children' in props ? props.children : undefined;
    const renderFooter = 'renderFooter' in props ? props.renderFooter : undefined;
    const renderHeader = 'renderHeader' in props ? props.renderHeader : undefined;

    return (
      <Card__Empty renderFooter={renderFooter} renderHeader={renderHeader} contentMargin={contentMargin}>
        {emptyChildren}
      </Card__Empty>
    );
  }

  const { children, errorMessage, errorSize, footer, testId } = getProps(props);

  return (
    <div ref={ref} className={classNames('card', className)} data-testid={testId}>
      <div className={classNames('card__content', { 'card__content--with-margin': contentMargin })}>
        {type === 'error' && (
          <div>
            {children}
            {errorMessage != null ? <ErrorState size={errorSize} message={errorMessage} /> : null}
          </div>
        )}
        {type === 'card' && children}
      </div>

      {footer != null ? footer : null}
    </div>
  );
});

function getProps(props: IProps) {
  const children = 'children' in props ? props.children : undefined;
  const errorMessage = 'errorMessage' in props ? props.errorMessage : undefined;
  const errorSize = 'errorSize' in props ? props.errorSize : undefined;
  const footer = 'footer' in props ? props.footer : undefined;
  const testId = 'testId' in props ? props.testId : undefined;

  return {
    children,
    testId,
    errorMessage,
    errorSize,
    footer
  };
}
