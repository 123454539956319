import { groupForecastIntervalsByDay } from '../../lib/helpers/intervals';
import { IAPICoastForecast } from '../../model/api/coast';
import { ICoastForecast } from '../../model/coast';

export function parseCoastForecast(data: IAPICoastForecast): ICoastForecast {
  const uniqueDaysFromDayIntervals = data.dayIntervals.map(interval => {
    return { start: interval.start, end: interval.end };
  });

  const intervalsGroupedByDay = groupForecastIntervalsByDay(
    data.shortIntervals,
    data.longIntervals,
    uniqueDaysFromDayIntervals
  );

  return {
    created: data.created,
    distanceFromLocation: data.distanceFromLocation,
    url: data.url,
    displayUrl: data.displayUrl,
    dayIntervals: data.dayIntervals,
    shortIntervals: data.shortIntervals,
    longIntervals: data.longIntervals,
    intervalsGroupedByDay
  };
}
