import { IAirQuality } from '../../model/airQuality';
import { IApiAirQuality } from '../../model/api/airQuality';

export function parseAirQuality(data: IApiAirQuality): IAirQuality | undefined {
  if (data._embedded.status.code === 'Unavailable') {
    return undefined;
  }

  return data._embedded;
}
