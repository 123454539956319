import { ILocation } from '../../../model/location';
import { IPosition } from '../../../model/position';

type IState = IStateIdle | IStateGetPosition | IStateGetLocation | IStateRedirect | IStateError;

interface IStateIdle {
  type: 'IDLE';
}

interface IStateGetPosition {
  type: 'GET_POSITION';
}

interface IStateGetLocation {
  type: 'GET_LOCATION';
  position: IPosition;
}

interface IStateRedirect {
  type: 'REDIRECT';
  location: ILocation;
}

interface IStateError {
  type: 'ERROR';
}

type TAction =
  | { type: 'IDLE' }
  | { type: 'GET_POSITION' }
  | { type: 'GET_LOCATION'; position: IPosition }
  | { type: 'REDIRECT'; location: ILocation }
  | { type: 'ERROR' };

export const initialState: IState = { type: 'IDLE' };

export function reducer(_state: IState, action: TAction): IState {
  switch (action.type) {
    case 'IDLE':
      return { type: 'IDLE' };

    case 'GET_POSITION':
      return { type: 'GET_POSITION' };

    case 'GET_LOCATION':
      return { type: 'GET_LOCATION', position: action.position };

    case 'REDIRECT':
      return { type: 'REDIRECT', location: action.location };

    case 'ERROR':
      return { type: 'ERROR' };

    default:
      throw new Error();
  }
}
