import { isTab } from '../../lib/keyboard';

export function initializeListeners() {
  window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt, false);

  detectIsTabbing();
  detectIsHovering();
}

function onBeforeInstallPrompt(event: Event) {
  // Disable banner prompt
  event.preventDefault();
  return false;
}

// Detect if the user is interacting with the page using the tab key and add a global class name,
function detectIsTabbing() {
  const html = document.documentElement;
  let isTabbing = false;

  function handleKeyDown(event: KeyboardEvent) {
    if (isTabbing === true) {
      return;
    }

    if (isTab(event) === false) {
      return;
    }

    isTabbing = true;
    html.classList.add('is-tabbing');
  }

  // If the user clicks anywhere using a mouse they're no longer tabbing the page
  function handleMouseDown() {
    if (isTabbing === false) {
      return;
    }

    isTabbing = false;
    html.classList.remove('is-tabbing');
  }

  document.addEventListener('keydown', handleKeyDown, false);
  document.addEventListener('mousedown', handleMouseDown, false);
}

// Detect if the user is hovering using a mouse or not and add a global class name.
function detectIsHovering() {
  const html = document.documentElement;

  // If pointer events are not supported just fall back to assuming the user is always hovering
  if ('PointerEvent' in window === false) {
    html.classList.add('is-hovering');
    return;
  }

  function handlePointerMove(event: PointerEvent) {
    if (event.pointerType === 'mouse') {
      html.classList.add('is-hovering');
    } else {
      html.classList.remove('is-hovering');
    }
  }

  document.addEventListener('pointermove', handlePointerMove, false);
}
