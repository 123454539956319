import { useMemo } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useFetchForecastByLocationId } from '../../data/forecast/hooks';
import { createRelativeTimeWithHourIntervalLabel } from '../../lib/helpers/time';
import { IMountainPassLocation } from '../../model/mountainpass';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { RouteForecastItem } from '../RouteForecastItem/RouteForecastItem';
import './RouteForecastList.scss';
import { RouteForecastList__EmptyHeaders } from './RouteForecastList__EmptyHeaders';
import { Text } from '../Text/Text';

interface IProps {
  locations: IMountainPassLocation[];
}

export const RouteForecastList = (props: IProps) => {
  const { locations } = props;

  const translate = useTranslate();
  const { isFirstRender } = useAppState();

  const { data: firstLocationForecast } = useFetchForecastByLocationId({ locationId: locations[0].id });

  const headingTimeLabels = useMemo(() => {
    if (firstLocationForecast == null) {
      return [];
    }

    const intervals = firstLocationForecast.longIntervals.slice(0, 4);
    const timeLabels = intervals.map(interval =>
      createRelativeTimeWithHourIntervalLabel({
        type: 'relative-date-short',
        from: interval.start,
        to: interval.end,
        isFirstRender,
        translate,
        transform: 'sentence-case'
      })
    );

    return timeLabels;
  }, [firstLocationForecast, translate, isFirstRender]);

  return (
    <div className="route-forecast-list escape-layout-container-padding">
      <div className="route-forecast-list__headers" aria-hidden={true}>
        <div className="route-forecast-list__header-gap" />
        {headingTimeLabels.length === 0 && <RouteForecastList__EmptyHeaders />}
        {headingTimeLabels.length > 0 &&
          headingTimeLabels.map(timeLabel => {
            return (
              <Text size="5" color="secondary" key={timeLabel} className="route-forecast-list__header">
                {timeLabel}
              </Text>
            );
          })}
      </div>
      <ol className="route-forecast-list__locations">
        {locations.map(location => {
          return <RouteForecastItem key={location.id} location={location} />;
        })}
      </ol>
    </div>
  );
};
