export interface StringMap<V> {
  [key: string]: V;
}

// We can't map over `A[] | B[]), but we can map over `Array<A | B>`.
// See https://mattferderer.com/typescript-cannot-invoke-an-expression-whose-type-lacks-a-call-signature
// and https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-3.html#caveats
export type UnionArray<A, B> = Array<A | B>;

// Check if key is a valid key of the given object.
// See https://dev.to/kingdaro/indexing-objects-in-typescript-1cgi
export function isKeyOfObject<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}
