import produce from 'immer';
import { AriaLevel } from '../../../model/aria';

interface IState {
  text: string;
  level: AriaLevel;
}

type TAction = { type: 'ADD'; text: string; level?: AriaLevel };

export const initialState: IState = { text: '', level: 'polite' };

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'ADD':
      return produce(state, draft => {
        draft.level = action.level ?? 'polite';
        draft.text = action.text;
      });

    default:
      throw new Error();
  }
}
