import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { prefix } from '../../lib/style';
import './WindArrow.scss';

interface IProps {
  direction?: number;
  speed: number;
  className?: string;
  size?: number;
}

export function WindArrow(props: IProps) {
  const { direction, className = '', speed, size = 3 } = props;
  if (direction == null) {
    return null;
  }

  // If direction is present but speed is calm, we do not want
  // to show the direction-arrow, but we want it to take up space
  // so things around the arrow does not get skewed around
  // The magic value 0.3 is the same as calm in
  // "src/lib/helpers/windDescriptionHelpers.ts".
  // See https://confluence.nrk.no/display/YRNO/Vindpil+og+Vindretning
  if (speed <= 0.3) {
    return (
      <div
        className={classNames(className, {
          'wind-arrow': true
        })}
      ></div>
    );
  }

  const directionStyle = prefix('transform', `translate(-50%, -50%) rotate(${direction}deg)`);

  return (
    <div
      className={classNames(className, {
        'wind-arrow': true
      })}
      data-testid="wind-arrow"
      aria-hidden={true}
    >
      <div className="wind-arrow__arrow" style={directionStyle}>
        <Icon id="icon-arrow" size={size} />
      </div>
    </div>
  );
}
