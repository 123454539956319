import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { IHelpOptionLink, IHelpOptionModal } from '../../model/help';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableButton, ClickableLink } from '../Clickable/Clickable';
import { Text } from '../Text/Text';

interface ILinkProps extends IHelpOptionLink {
  type: 'link';
  trackLabel: string;
  onClick: () => void;
}

interface IModalProps extends IHelpOptionModal {
  type: 'modal';
  trackLabel: string;
  onClick: () => void;
}

export function Clippy__Option(props: ILinkProps | IModalProps) {
  const { trackLabel, icon, keys } = props;

  const translate = useTranslate();

  return (
    <div className={classNames('clippy__option', { 'clippy__option--has-subtitle': keys.subtitle != null })}>
      <Icon className="clippy__option-icon" id={icon} size={3} />
      {props.type === 'link' ? (
        <ClickableLink
          data-testid="clippy/option"
          className="clippy__option-title"
          href={translate(props.keys.url)}
          isExternal={true}
          onClick={props.onClick}
          analytics={{ category: 'clippy', action: 'open_link', label: trackLabel }}
        >
          <Text size="4" weight="bold">
            {translate(keys.title)}
          </Text>
        </ClickableLink>
      ) : (
        <ClickableButton
          data-testid="clippy/option"
          className="clippy__option-title"
          onClick={props.onClick}
          analytics={{ category: 'clippy', action: 'open_modal', label: trackLabel }}
        >
          <Text size="4" weight="bold">
            {translate(keys.title)}
          </Text>
        </ClickableButton>
      )}

      {keys.subtitle != null && (
        <Text as="p" size="5" color="secondary">
          {translate(keys.subtitle)}
        </Text>
      )}

      <Icon
        className="clippy__option-type-icon"
        id={props.type === 'link' ? 'icon-new-window' : 'icon-chevron-right'}
        size={2}
      />
    </div>
  );
}
