import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiLocationCameras } from '../../model/api/locationCameras';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';

export function queryCamerasByLocationId({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['locationCameras', localeCode, locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IApiLocationCameras>({
      path: template(settings.api.paths.locationCameras, { localeCode, locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
