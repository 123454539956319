import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPIForecast } from '../../model/api/forecast';
import { api } from '../api';
import { parseForecast } from './parse';

export function queryForecastByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['forecast', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IAPIForecast>({
      path: template(settings.api.paths.forecast, { locationId })
    });

    return parseForecast({ locationId, data: response });
  }

  return {
    queryKey,
    queryFn
  };
}
