import { useAriaLive } from '../../contexts/AriaLiveContext/AriaLiveContext';

export function AriaLive() {
  const { text, level } = useAriaLive();

  return (
    <div id="live" className="nrk-sr" aria-live={level}>
      {text}
    </div>
  );
}
