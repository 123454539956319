import { useEffect, useState } from 'react';

export function useTimestampInterval(interval: number) {
  const [timestamp, setTimestamp] = useState<number>();

  useEffect(() => {
    let timeoutID: ReturnType<typeof setTimeout>;

    function tick() {
      setTimestamp(Date.now());

      timeoutID = setTimeout(tick, interval);
    }

    timeoutID = setTimeout(tick, interval);

    return () => {
      if (timeoutID != null) {
        clearTimeout(timeoutID);
      }
    };
  }, [interval]);

  return timestamp;
}
