import { IAPISearch } from '../../model/api/search';
import { parseLocation } from '../../data/locations/parse';

export function parseSearch(data: IAPISearch) {
  if (data._embedded == null) {
    return [];
  }

  return data._embedded.location.map(location => parseLocation(location));
}
