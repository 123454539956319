import { ILocation } from '../../model/location';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { Elevation } from '../Elevation/Elevation';
import { Text } from '../Text/Text';

type ILocationRegion = Pick<
  ILocation,
  'category' | 'name' | 'elevation' | 'country' | 'region' | 'subregion' | 'isNorway' | 'isCoordinate'
>;

interface IProps {
  location: ILocationRegion;
  textColor?: 'primary' | 'secondary';
}

export function LocationRegion(props: IProps) {
  const { location, textColor = 'primary' } = props;

  const localeCode = useLocaleCode();
  const isNorwegianOrSami = localeCode === 'nb' || localeCode === 'nn' || localeCode === 'sme';

  let parts: string[] = [];

  if (location.isCoordinate && location.category) {
    parts = [location.category];
  } else {
    // Add the name first even though we're not going to include it in the final string.
    // Adding it to the parts array makes it easier to filter out repeats later.
    parts.push(location.name);

    if (location.subregion) {
      parts.push(location.subregion);
    }

    if (location.region) {
      parts.push(location.region);
    }

    if (location.country) {
      // Don't include the country if it's Norway and the language is Norwegian or Sami
      if (location.isNorway === false || (location.isNorway && isNorwegianOrSami === false)) {
        parts.push(location.country);
      }
    }

    // Remove repeating parts
    parts = parts.filter((item, idx) => {
      // Always exclude the location name and avoid any repeats
      return idx >= 1 && item !== parts[idx - 1];
    });

    // Add the category to the start
    if (location.category) {
      parts.unshift(location.category);
    }

    if (parts.length > 1) {
      // Wrap the final part in parentheses
      parts[parts.length - 1] = `(${parts[parts.length - 1]})`;

      // Add a comma separator between each part before the final part
      for (let i = 0; i < parts.length - 2; i++) {
        parts[i] = `${parts[i]},`;
      }
    }
  }

  if (parts.length == null && location.elevation == null) {
    return null;
  }

  let regionString = parts.join(' ');

  if (regionString !== '' && location.elevation != null) {
    regionString = `${regionString}, `;
  }

  return (
    <Text as="span" size="5" color={textColor} data-testid="location-region">
      {regionString}
      {location.elevation != null && <Elevation elevation={location.elevation} textSize="5" textColor={textColor} />}
    </Text>
  );
}
