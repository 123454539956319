import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import './IconButton.scss';
import { forwardRef } from 'react';

interface IBaseProps {
  buttonType?: 'highlight' | 'primary' | 'critical' | 'subtle';
  buttonSize?: 'small' | 'large';
  buttonShape?: 'square' | 'rounded';
  hideBorder?: boolean;

  icon: TIconId;
  ariaLabel: string;

  // Disallow data attributes we use internally
  // to prevent anyone from trying to use these by accident
  'data-type'?: never;
  'data-size'?: never;
  'data-shape'?: never;
  'data-disabled'?: never;
}

interface IButtonProps extends IBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
  children?: never;
}

interface ILinkProps extends IBaseProps, React.LinkHTMLAttributes<HTMLAnchorElement> {
  as: 'link';
  isExternal?: boolean;
  href: string;
  children?: never;
}

interface ILabelProps extends IBaseProps, React.LinkHTMLAttributes<HTMLLabelElement> {
  as: 'label';
  htmlFor: string;
  children?: never;
}

export const IconButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement | HTMLLabelElement,
  IButtonProps | ILinkProps | ILabelProps
>((props, ref) => {
  if (props.as === 'button') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'rounded',
      hideBorder = false,
      className,
      icon,
      ariaLabel,
      disabled,
      ...htmlProps
    } = props;

    return (
      <button
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        {...htmlProps}
        className={classNames('icon-button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        <Icon id={icon} size={buttonSize === 'small' ? 2 : 3} />
      </button>
    );
  }

  if (props.as === 'link') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'rounded',
      hideBorder = false,
      className,
      icon,
      ariaLabel,
      isExternal,
      ...htmlProps
    } = props;
    const { href, ...otherHtmlProps } = htmlProps;

    // This is written as an object we can spread on the link element to make eslint happy
    // so it doesn't think we're adding `target="_blank"` without a corresponding `rel="noopener noreferrer"` attribute.
    const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : undefined;

    return (
      <a
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        href={href}
        {...otherHtmlProps}
        className={classNames('icon-button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
        {...externalLinkProps}
      >
        <span className="nrk-sr">{ariaLabel}</span>
        <Icon id={icon} size={buttonSize === 'small' ? 2 : 3} />
      </a>
    );
  }

  if (props.as === 'label') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'rounded',
      hideBorder = false,
      className,
      icon,
      ariaLabel,
      ...htmlProps
    } = props;
    const { htmlFor, ...otherHtmlProps } = htmlProps;

    return (
      <label
        ref={ref as React.ForwardedRef<HTMLLabelElement>}
        htmlFor={htmlFor}
        {...otherHtmlProps}
        className={classNames('icon-button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
      >
        <span className="nrk-sr">{ariaLabel}</span>
        <Icon id={icon} size={buttonSize === 'small' ? 2 : 3} />
      </label>
    );
  }

  return null;
});
