import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { ILocalStorageMenu } from '../../components/MapLayerSelector/MapTypeSelector';
import { getLocalStorageObject } from '../helpers/localStorage';
import { useCurrentLocationId } from '../hooks';

export function useMapSubpageId(): 'radar' | 'weather' | 'wind' | 'lightning' | 'temperature' {
  const locationId = useCurrentLocationId();
  // The first time a user visits the map we want to show the the weather layer for standalone map
  // and the radar layer for the map when a location is set.
  const initialMapSubpageId = locationId != null ? 'radar' : 'weather';
  const [mapSubpageId, setMapSubpageId] = useState(initialMapSubpageId);
  const { currentPage } = useAppState();
  const { pageId, subpageId } = currentPage.details;

  // Get the user's last viewed map type from local storage
  useEffect(() => {
    const menu = getLocalStorageMenu();
    if (menu == null) {
      return;
    }

    setMapSubpageId(menu.lastViewedMapType);
  }, []);

  // When a user is changing the map type in the MapTypeSelector, local storage will be updated
  // so we also need to update mapSubpageId.
  useEffect(() => {
    if (pageId !== 'map' || subpageId == null) {
      return;
    }

    const menu = getLocalStorageMenu();
    if (menu == null) {
      return;
    }

    setMapSubpageId(menu.lastViewedMapType);
  }, [pageId, subpageId]);

  return mapSubpageId as 'radar' | 'weather' | 'wind' | 'lightning' | 'temperature';
}

function isMapSubpageId(subpageId: string) {
  return ['radar', 'weather', 'wind', 'lightning', 'temperature'].includes(subpageId);
}

function getLocalStorageMenu(): ILocalStorageMenu | undefined {
  const menu = getLocalStorageObject<ILocalStorageMenu>('menu');
  if (menu == null) {
    return undefined;
  }

  if (isMapSubpageId(menu.lastViewedMapType) === false) {
    return undefined;
  }

  return menu;
}
