import { useAppState } from '../../app/contexts/AppStateContext';
import { useFetchExtremeEventList } from '../../data/extreme/hooks';
import './ExtremeWarningsBanner.scss';
import { ExtremeWarningsBanner__Item } from './ExtremeWarningsBanner__Item';

export function ExtremeWarningsBanner() {
  const { currentPage } = useAppState();
  const { locationId } = currentPage.details.params;

  const { data: extremeEventList } = useFetchExtremeEventList({ locationId });

  if (extremeEventList == null) {
    return null;
  }

  return (
    <div aria-live="assertive" className="extreme-warnings-banner" data-testid={'extreme-warnings-banner'}>
      {extremeEventList.extremeWarnings.map(event => {
        return <ExtremeWarningsBanner__Item key={event.incidentId} event={event} />;
      })}
    </div>
  );
}
