import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import { createPageUrl } from '../../app/redirects';
import { useLocationList } from '../../contexts/LocationListContext/LocationListContext';
import { ILocation } from '../../model/location';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { ClickableLink } from '../Clickable/Clickable';
import { LocationRegion } from '../LocationRegion/LocationRegion';
import { Text } from '../Text/Text';

type TLocationType = 'favourite' | 'visited' | 'default';

const SUGGESTED_LOCATION_ICON: { [Key in TLocationType]: TIconId } = {
  favourite: 'icon-star-filled',
  visited: 'icon-clock',
  default: 'icon-location'
};

interface IProps {
  location: ILocation;
  onClick: (params: { locationId: string; href: string }) => void;
}

export const Search__SuggestedLocation = (props: IProps) => {
  const { location, onClick } = props;

  const localeCode = useLocaleCode();
  const { favouritedLocationIds, visitedLocationIds } = useLocationList();

  let type: TLocationType = 'default';

  if (favouritedLocationIds.includes(location.id)) {
    type = 'favourite';
  } else if (visitedLocationIds.includes(location.id)) {
    type = 'visited';
  }

  const href = createPageUrl({
    localeCode,
    pageId: 'forecast',
    subpageId: 'daily-table',
    locationId: location.id,
    urlPath: location.urlPath
  });

  return (
    <li className="search__suggested-item">
      <ClickableLink
        href={href}
        onClick={event => {
          event.preventDefault();
          onClick({ locationId: location.id, href });
        }}
        className={classNames('search__suggested-link', {
          'search__suggested-link--favourite': type === 'favourite'
        })}
      >
        <Icon id={SUGGESTED_LOCATION_ICON[type]} className="search__suggested-icon" />
        <span className="search__suggested-content">
          <Text size="4" weight="bold" color="primary" className="search__suggested-title">
            {location.name}
          </Text>
          <Text size="5" color="secondary" className="search__suggested-subtitle">
            <LocationRegion location={location} textColor="secondary" />
          </Text>
        </span>
      </ClickableLink>
    </li>
  );
};
