/**
 * Prefix css style 'property'
 * @param {String} property
 * @param {String} value
 * @param {Object} [styleObj]
 * @returns {Object}
 */
export function prefix(property, value, styleObj = {}) {
  const ucProperty = property.charAt(0).toUpperCase() + property.slice(1);
  const props = [property, 'Webkit' + ucProperty, 'Moz' + ucProperty, 'O' + ucProperty, 'ms' + ucProperty];

  props.forEach(prop => {
    styleObj[prop] = value;
  });

  return styleObj;
}
