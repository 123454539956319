import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiMountainPassResponse } from '../../model/api/mountainpass';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';

export function queryBirkebeinerRouteByRouteId({ localeCode, routeId }: { localeCode: LocaleCode; routeId?: string }) {
  const queryKey = ['birkebeinerRoute', routeId, localeCode];

  async function queryFn() {
    if (routeId == null) {
      return;
    }

    // TODO(as): Should there be a seperate Birkebeiner interface?
    // Or we could rename it to a generic "Route" response.
    const response = await api<IApiMountainPassResponse>({
      path: template(settings.api.paths.birkebeiner, { localeCode, routeId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
