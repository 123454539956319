import settings from '../../app/settings';
import { template } from '../../lib/string';
import { ICelestialEventsMultipleDays, ICelestialEventsSingleDay } from '../../model/celestialEvents';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseCelestialEventsMultipleDays, parseCelestialEventsSingleDay } from './parse';

export function queryCelestialEventsSingleDayByLocationId({
  locationId,
  localeCode
}: {
  locationId?: string;
  localeCode: LocaleCode;
}) {
  const queryKey = ['celestialEventsSingleDay', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<ICelestialEventsSingleDay>({
      path: template(settings.api.paths.celestialEventsSingleDay, { locationId, localeCode })
    });

    return parseCelestialEventsSingleDay(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryCelestialEventsMultipleDaysByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['celestialEventsMultipleDays', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<ICelestialEventsMultipleDays>({
      path: template(settings.api.paths.celestialEventsMultipleDays, { locationId })
    });

    return parseCelestialEventsMultipleDays(response);
  }

  return {
    queryKey,
    queryFn
  };
}
