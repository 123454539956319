import { useTranslate } from '../../lib/hooks/useTranslate';
import './Loader.scss';

interface IProps {
  type?: 'positioned' | 'block';
}

export function Loader(props: IProps) {
  const { type = 'positioned' } = props;

  const translate = useTranslate();
  const loadingText = translate('loader/loading');

  return (
    <div className={`loader loader--${type}`} data-testid="loader">
      <div className="loader__content">
        <span className="nrk-sr">{loadingText}</span>
        <span className="loader__animation-bounce" />
        <span className="loader__animation-bounce" />
        <span className="loader__animation-bounce" />
      </div>
    </div>
  );
}
