import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAuroraForecast } from '../../model/auroraForecast';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseAuroraForecast } from './parse';

export function queryAuroraForecastByLocationId({
  localeCode,
  locationId
}: {
  localeCode: LocaleCode;
  locationId?: string;
}) {
  const queryKey = ['auroraForecast', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IAuroraForecast>({
      path: template(settings.api.paths.auroraForecast, { localeCode, locationId })
    });

    return parseAuroraForecast(response);
  }

  return {
    queryKey,
    queryFn
  };
}
