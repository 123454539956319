export const Search__SuggestedEmpty = () => {
  return (
    <li className="search__suggested-item">
      <span className="search__suggested-link">
        <span className="search__suggested-icon" />
        <span className="search__suggested-content">
          <span className="search__suggested-title"></span>
          <span className="search__suggested-subtitle" />
        </span>
      </span>
    </li>
  );
};
