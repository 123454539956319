import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiPollenResponse } from '../../model/api/pollen';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parsePollen } from './parse';

export function queryPollenByLocationId({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['pollen', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IApiPollenResponse>({
      path: template(settings.api.paths.pollen, { localeCode, locationId })
    });

    return parsePollen(response);
  }

  return {
    queryKey,
    queryFn
  };
}
