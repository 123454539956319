import classNames from 'classnames';
import { Loader } from '../Loader/Loader';
import { useAnimationSafeAfterFirstRender } from '../../lib/hooks';
import { useState } from 'react';
import './AppBusyStatus.scss';

interface IProps {}

export function AppBusyStatus(_props: IProps) {
  const [active, setActive] = useState(false);

  useAnimationSafeAfterFirstRender(() => {
    setActive(true);
  });

  return (
    <div className={classNames('app-busy-status', { 'app-busy-status--active': active })} data-testid="app-busy-status">
      <Loader />
    </div>
  );
}
