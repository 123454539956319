import { IPosition } from '../model/position';

const numberWithPeriodSeparator = /-?\d+(?:\.\d*)?/;
const numberWithCommaSeparator = /-?\d+(?:,\d*)?/;
const coordinateWithPeriodNumberSeparator = new RegExp(
  `^\\s*(${numberWithPeriodSeparator.source})(?:°|°?,\\s*|°?\\s+)(${numberWithPeriodSeparator.source})°?\\s*$`
);
const coordinateWithCommaNumberSeparator = new RegExp(
  `^\\s*(${numberWithCommaSeparator.source})(?:°|°?\\s+)(${numberWithCommaSeparator.source})°?\\s*$`
);

export function parseDecimalDegrees(input: string) {
  const match = input.match(coordinateWithPeriodNumberSeparator) || input.match(coordinateWithCommaNumberSeparator);
  if (match == null) {
    return undefined;
  }

  let inputLat = match[1];
  let inputLon = match[2];
  if (inputLat == null || inputLon == null) {
    return undefined;
  }

  // Convert potential comma separator to period separator
  inputLat = inputLat.replace(',', '.');
  inputLon = inputLon.replace(',', '.');

  const position: IPosition = {
    lat: parseFloat(inputLat),
    lon: parseFloat(inputLon)
  };

  if (position.lat < -90 || position.lat > 90) {
    return undefined;
  }

  if (position.lon < -180 || position.lon > 180) {
    return undefined;
  }

  return position;
}
