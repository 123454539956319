import { TYrTimeMonths, TYrTimeWeekdays, YrLocale } from '@nrk/yr-time';
import { useMemo } from 'react';
import { ITranslateFunction } from '../../model/translate';
import { useTranslate } from './useTranslate';

export function useTimeLocale() {
  const translate = useTranslate();

  const timeLocale = useMemo(() => {
    return createTimeLocale(translate);
  }, [translate]);

  return timeLocale;
}

// Create a time locale object we can send to `@nrk/yr-time`.
// We don't need`daysShortStandalone` or `monthsShortStandalone`
// in the languages we currently support (en, nb, nn, and sme).
export const createTimeLocale = (translate: ITranslateFunction): YrLocale => {
  return {
    daySlots: {
      night: translate('time/daySlots/night'),
      morning: translate('time/daySlots/morning'),
      afternoon: translate('time/daySlots/afternoon'),
      evening: translate('time/daySlots/evening')
    },
    daysShort: translate('time/daysShort').split('\n') as TYrTimeWeekdays,
    days: translate('time/days').split('\n') as TYrTimeWeekdays,
    daysStandalone: translate('time/daysStandalone').split('\n') as TYrTimeWeekdays,
    monthsShort: translate('time/monthsShort').split('\n') as TYrTimeMonths,
    months: translate('time/months').split('\n') as TYrTimeMonths,
    monthsStandalone: translate('time/monthsStandalone').split('\n') as TYrTimeMonths,
    today: translate('time/today'),
    tomorrow: translate('time/tomorrow'),
    tonight: translate('time/tonight')
  };
};
