import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useFetchLocation } from '../locations/hooks';
import { useQueryWrapper } from '../query';
import { queryWarnings, queryWarningsByLocationId } from './queries';

export function useFetchWarnings() {
  const localeCode = useLocaleCode();

  return useQueryWrapper(queryWarnings({ localeCode }));
}

export function useFetchWarningsByLocationId({ locationId }: { locationId?: string }) {
  const localeCode = useLocaleCode();
  const { data: location } = useFetchLocation({ locationId });

  return useQueryWrapper({
    ...queryWarningsByLocationId({ localeCode, locationId }),

    // Only execute this query if the location supports warnings
    enabled: location != null && location.hasWarnings
  });
}
