import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';
import './Elevation.scss';

interface IProps {
  elevation?: number;
  /** includes parantheses defaults to false */
  includeParentheses?: boolean;
  textSize?: '4' | '5';
  textColor?: 'primary' | 'secondary';
}

export const Elevation = (props: IProps) => {
  const { elevation, includeParentheses = false, textSize = '4', textColor = 'primary' } = props;
  const translate = useTranslate();

  if (elevation == null) {
    return null;
  }

  return (
    <Text color={textColor} size={textSize} className="elevation">
      {includeParentheses && '('}
      {translate('units/elevation', { elevation })}{' '}
      <abbr title={translate('units/metersAboveSealevel/long')}>{translate('units/metersAboveSealevel/short')}</abbr>
      {includeParentheses && ')'}
    </Text>
  );
};
