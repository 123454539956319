import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiCurrentHour } from '../../model/api/currentHour';
import { api } from '../api';

export function queryCurrentHourByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['currenthour', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IApiCurrentHour>({
      path: template(settings.api.paths.currenthour, { locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
