import classNames from 'classnames';
import noScroll from 'no-scroll';
import { useEffect, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { IPosition } from '../../model/position';
import { parseDecimalDegrees } from '../../lib/coordinate';
import { positionToDecimalDegrees } from '../../lib/format';
import { focusPage } from '../../lib/helpers/accessibility';
import { useDebounceValue } from '../../lib/hooks/useDebounceValue';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { ESCAPE } from '../../lib/keyboard';
import { track } from '../../lib/analytics/track';
import './Search.scss';
import { Search__Input } from './Search__Input';
import { Search__Suggestions } from './Search__Suggestions';

const MQ_MAX_WIDTH_600 = '(max-width: 600px)';

export interface ISearchQueryString {
  type: 'query';
  query: string;
}

export interface ISearchQueryCoordinate {
  type: 'coordinate';
  locationId: string;
  position: IPosition;
}

interface IProps {
  searchId: string;
  searchInputId: string;
  onToggleSearchInput?: (open: boolean) => void;
}

export function Search(props: IProps) {
  const { searchId, searchInputId, onToggleSearchInput } = props;

  const { history } = useAppState();
  const localeCode = useLocaleCode();
  const searchElementRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const coreSuggestRef = useRef<HTMLDivElement>(null);
  const cancelSearchRef = useRef<HTMLButtonElement>(null);
  const clearSearchRef = useRef<HTMLButtonElement>(null);
  const [showClearSearchButton, setShowClearSearchButton] = useState(false);
  const [isAriaExpanded, setIsAriaExpanded] = useState(false);
  const [query, setQuery] = useDebounceValue<ISearchQueryString | ISearchQueryCoordinate>({ wait: 200 });

  // We want to make sure noScroll is turned off if this component somehow is removed without closing it.
  useEffect(() => {
    return () => {
      noScroll.off();
    };
  }, []);

  useEffect(() => {
    const currentSearchElementRef = searchElementRef.current;

    const stopEventPropagation = (event: FocusEvent) => {
      // We need to stop event propagation to avoid core-suggest closing when we are clearing input or focusing the cancel button
      if (event.target === cancelSearchRef.current || event.target === clearSearchRef.current) {
        event.stopPropagation();
      }
    };

    if (currentSearchElementRef) {
      currentSearchElementRef.addEventListener('focusin', stopEventPropagation);
    }

    return () => {
      if (currentSearchElementRef) {
        currentSearchElementRef.removeEventListener('focusin', stopEventPropagation);
      }
    };
  }, []);

  // Avoic scrolling the site when you have search suggestions open on smaller devices
  // We deactivate scroll so that the user is only able to scroll the suggested search list.
  useEffect(() => {
    const isSmallDevice = window.matchMedia(MQ_MAX_WIDTH_600).matches;

    if (isSmallDevice === true) {
      if (isAriaExpanded === true) {
        noScroll.on();
      } else {
        noScroll.off();
      }
    }
  }, [isAriaExpanded]);

  /**
   * Hook for telling us if core-suggest currently is open or not
   * We set up a MutationObserver on the SearchInput to check if the attribute aria-expanded is toggled
   * isAriaExpanded is set to the current state of aria-expanded attribute on the search input element.
   * We use this mainly to position the search input correctly on smaller devices when the search element is active.
   */
  useEffect(() => {
    const currentSearchInputElement = searchInputRef.current;

    if (currentSearchInputElement == null) {
      return;
    }

    const observeAriaExpanded = (mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'aria-expanded' &&
          mutation.target === currentSearchInputElement
        ) {
          if (currentSearchInputElement.getAttribute('aria-expanded') === 'true') {
            setIsAriaExpanded(true);

            if (onToggleSearchInput) {
              onToggleSearchInput(true);
            }
          } else {
            setIsAriaExpanded(false);

            if (onToggleSearchInput) {
              onToggleSearchInput(true);
            }
          }
        }
      });
    };

    const observer = new MutationObserver(observeAriaExpanded);

    observer.observe(currentSearchInputElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [onToggleSearchInput]);

  useEffect(() => {
    const currentCoreSuggestElement = coreSuggestRef.current;

    // When clicking inside core suggest but not on a suggested item, the focus is moved to body
    // To avoid the search being closed when this occurs we set the focus to the search input instead
    const focusSearchInput = () => {
      if (searchInputRef.current != null) {
        searchInputRef.current.focus();
      }
    };

    if (currentCoreSuggestElement) {
      currentCoreSuggestElement.addEventListener('click', focusSearchInput);
    }

    return () => {
      if (currentCoreSuggestElement) {
        currentCoreSuggestElement.removeEventListener('click', focusSearchInput);
      }
    };
  });

  useEffect(() => {
    const currentSearchInputRef = searchInputRef.current;

    function handleKeyDown(event: KeyboardEvent) {
      if (event.keyCode !== ESCAPE) {
        return;
      }

      // Only focus page to close the input if it is already expanded
      if (currentSearchInputRef && currentSearchInputRef.getAttribute('aria-expanded') === 'true') {
        // We focus the page so that core-suggest closes properly
        focusPage();
      }
    }

    document.body.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [searchInputRef]);

  useEffect(() => {
    setShowClearSearchButton(query != null);
  }, [query]);

  const onInputClear = () => {
    if (searchInputRef.current) {
      setQuery(undefined);
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
  };

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    const position = parseDecimalDegrees(query);

    if (position != null) {
      const locationId = positionToDecimalDegrees({ position, type: 'locationId' });
      setQuery({ type: 'coordinate', locationId, position });
    } else if (query.length > 0) {
      setQuery({ type: 'query', query });
    }
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (query != null) {
      onInputClear();

      if (query.type === 'coordinate') {
        history.push(
          createPageUrl({ localeCode, pageId: 'forecast', subpageId: 'daily-table', locationId: query.locationId })
        );
        track.event({ category: 'search', action: 'coordinate', label: 'choose_result' });
      } else {
        history.push(createPageUrl({ localeCode, pageId: 'search', search: `?q=${query.query}` }));
      }
    }
  };

  const action = createPageUrl({ localeCode, pageId: 'search' });

  return (
    <div className={classNames('search', { 'search--expanded': isAriaExpanded })} ref={searchElementRef}>
      <form action={action} onSubmit={(event: React.FormEvent<HTMLFormElement>) => onFormSubmit(event)}>
        <input className="search__input-submit" tabIndex={-1} aria-hidden={true} type="submit" />
        <Search__Input
          id={searchInputId}
          suggestedId={searchId}
          searchInputRef={searchInputRef}
          onInputChange={onInputChange}
          onInputClear={onInputClear}
          cancelSearchRef={cancelSearchRef}
          clearSearchRef={clearSearchRef}
          showClearSearchButton={showClearSearchButton}
        />
        <Search__Suggestions
          id={searchId}
          query={query}
          onInputClear={onInputClear}
          coreSuggestRef={coreSuggestRef}
          expanded={isAriaExpanded}
        />
      </form>
    </div>
  );
}
