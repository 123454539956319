import { useTranslate } from '../../lib/hooks/useTranslate';
import { EmptyText } from '../EmptyText/EmptyText';

export const RouteForecastItem__EmptyForecastCells = () => {
  const translate = useTranslate();

  return (
    <>
      <p className="nrk-sr">{translate('routeForecastItem/fetchingForecast')}</p>
      <ol className="route-forecast-item__forecast-list" aria-hidden>
        <li className="route-forecast-item__forecast-container">
          <EmptyText type="span" length={10} className="route-forecast-item__interval" />
          <EmptyText type="span" length={10} className="route-forecast-item__forecast--empty" />
        </li>

        <li className="route-forecast-item__forecast-container">
          <EmptyText type="span" length={10} className="route-forecast-item__interval" />
          <EmptyText type="span" length={10} className="route-forecast-item__forecast--empty" />
        </li>

        <li className="route-forecast-item__forecast-container">
          <EmptyText type="span" length={10} className="route-forecast-item__interval" />
          <EmptyText type="span" length={10} className="route-forecast-item__forecast--empty" />
        </li>

        <li className="route-forecast-item__forecast-container">
          <EmptyText type="span" length={10} className="route-forecast-item__interval" />
          <EmptyText type="span" length={10} className="route-forecast-item__forecast--empty" />
        </li>
      </ol>
    </>
  );
};
