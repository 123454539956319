import { testReducedMotion, testScrollBehavior } from './browserFeatures';

const SMOOTH_SCROLL_PIXELS_PER_SECOND = 1000;

export function smoothScroll(element: HTMLElement, x: number) {
  const elementRect = element.getBoundingClientRect();
  const elementWidth = Math.round(elementRect.width);

  // Clamp the target x and y in case the user has asked to scroll further
  // than is possible.
  const targetX = Math.min(element.scrollWidth - elementWidth, x);
  const targetY = element.scrollTop;

  // Scroll instantly for users who have indicated they prefer reduced motion
  if (testReducedMotion()) {
    element.scroll(targetX, targetY);
    return;
  }

  // Use native smooth scrolling if supported
  if (testScrollBehavior()) {
    element.scroll({ top: targetY, left: targetX, behavior: 'smooth' });
    return;
  }

  const startX = element.scrollLeft;
  const horizontalDirection = targetX > startX ? 1 : -1;
  let startTimestamp: number | undefined;

  function scrollFrame(timestamp: number) {
    if (startTimestamp == null) {
      startTimestamp = timestamp;
    }

    const elapsed = timestamp - startTimestamp;
    const elapsedInSeconds = elapsed / 1000;

    const deltaX = SMOOTH_SCROLL_PIXELS_PER_SECOND * elapsedInSeconds * horizontalDirection;

    let newX = Math.round(startX + deltaX);

    // Clamp the new position so we never overshoot the target
    if (horizontalDirection === 1) {
      newX = Math.min(newX, targetX);
    } else {
      newX = Math.max(newX, targetX);
    }

    element.scroll(newX, targetY);

    // Schedule a new animation frame if we haven't reached the target yet
    if (newX !== targetX) {
      window.requestAnimationFrame(scrollFrame);
    }
  }

  window.requestAnimationFrame(scrollFrame);
}
