/**
 * Determine if 'ancestor' is an ancestor of 'element'
 */
export function isAncestor(ancestor: HTMLElement, element: HTMLElement) {
  let el: Node | null = element;

  if (ancestor === element) {
    return true;
  }

  if (element.parentNode) {
    // tslint:disable-next-line: no-conditional-assignment
    while ((el = el.parentNode)) {
      if (el === ancestor) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Select all elements matching 'selector', scoped to optional 'context'
 */
export function selectAll(selector: string): HTMLElement[] {
  // Set document as default
  return Array.prototype.slice.call(document.querySelectorAll(selector));
}

export function findAncestorLink(element: HTMLElement) {
  let currentNode: Node | null = element;

  while (currentNode != null) {
    if (currentNode instanceof HTMLAnchorElement) {
      return currentNode;
    }

    currentNode = currentNode.parentNode;
  }

  return;
}
