import { useEffect, useState } from 'react';

interface IQuery {
  /**
   * The media query string we match against,
   * e.g. `(min-width: 500px)`
   */
  mediaQuery?: string;
  /**
   * Default value before the media query has run
   */
  defaultValue?: boolean;
}

export function useMediaQueryState(query: IQuery) {
  const [result, setResult] = useState<boolean | undefined>(query.defaultValue);

  useEffect(() => {
    if (query.mediaQuery == null || 'matchMedia' in window === false) {
      return;
    }

    const mediaQueryList = window.matchMedia(query.mediaQuery);

    function checkMediaQueryMatches() {
      setResult(mediaQueryList.matches);
    }

    // Check immediately
    checkMediaQueryMatches();

    // Not all browsers that support "matchMedia" support the change event.
    // See https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/change_event
    if ('addEventListener' in mediaQueryList === false) {
      return;
    }

    mediaQueryList.addEventListener('change', checkMediaQueryMatches);

    return () => {
      mediaQueryList.addEventListener('change', checkMediaQueryMatches);
    };
  }, [query]);

  return result;
}
