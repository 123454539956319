import { captureMessage } from '../../lib/errorReporter';
import { groupForecastIntervalsByDay } from '../../lib/helpers/intervals';
import { IAPIForecast } from '../../model/api/forecast';
import { IForecast } from '../../model/forecast';

export function parseForecast({ locationId, data }: { locationId: string; data: IAPIForecast }): IForecast {
  const uniqueDaysFromDayIntervals = data.dayIntervals.map(interval => {
    return { start: interval.start, end: interval.end };
  });

  const intervalsGroupedByDay = groupForecastIntervalsByDay(
    data.shortIntervals,
    data.longIntervals,
    uniqueDaysFromDayIntervals
  );

  // Validate that we get the correct number of weather symbols for each period.
  // We have had a bug where we suspect we receive more than 4 six hour symbols from the API when changing from or to summer time.
  // See https://nrknyemedier.atlassian.net/browse/YR-4513
  // TODO(scb): [2023-04-01] By this date we've changed from/to summer time and should know if this bug is still an issue or not
  // See https://nrknyemedier.atlassian.net/browse/YR-5184
  for (const dayInterval of data.dayIntervals) {
    const twentyFourHourSymbolsLength = dayInterval.twentyFourHourSymbol == null ? 0 : 1;
    const twelveHourSymbolsLength = dayInterval.twelveHourSymbols.length;
    const sixHourSymbolsLength = dayInterval.sixHourSymbols.length;

    if (twentyFourHourSymbolsLength !== 1 || twelveHourSymbolsLength !== 2 || sixHourSymbolsLength !== 4) {
      // Only report 1% of each error so we don't flood Sentry if this error occurs
      if (Math.random() * 100 === 0) {
        captureMessage('Incorrect number of symbols in day interval', {
          extra: {
            locationId,
            twentyFourHourSymbolsLength,
            twelveHourSymbolsLength,
            sixHourSymbolsLength
          }
        });
      }

      // We don't need an error for each incorrect day interval so stop checking when we have found one error
      break;
    }
  }

  return {
    created: data.created,
    dayIntervals: data.dayIntervals,
    shortIntervals: data.shortIntervals,
    longIntervals: data.longIntervals,
    intervalsGroupedByDay
  };
}
