import {
  IAPIObservationsDay,
  IAPIObservationsLast13Months,
  IAPIObservationsLast25Hours,
  IAPIObservationsLast30Days,
  IAPIObservationsNoData,
  IAPIObservationsYear
} from '../../model/api/observations';
import { LocaleCode } from '../../model/locale';
import { TStatisticsPeriod, TStatisticsPeriodMonth } from '../../model/statistics';
import { api } from '../api';
import { createApiPath } from './helpers';
import { parseObservations } from './parse';

export function queryStatistics({
  localeCode,
  locationId,
  period
}: {
  localeCode: LocaleCode;
  locationId?: string;
  period: TStatisticsPeriod;
}) {
  // TODO(scb): Make this more readable
  const newPeriod: Exclude<TStatisticsPeriod, TStatisticsPeriodMonth> =
    period.type === 'month'
      ? {
          type: 'year',
          time: period.time.split('-')[0]
        }
      : period;

  // TODO(scb): Make this more readable
  const queryKey =
    'time' in newPeriod
      ? ['statistics', localeCode, locationId, newPeriod.type, newPeriod.time]
      : ['statistics', localeCode, locationId, newPeriod.type];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const apiPath = createApiPath({ locationId, period: newPeriod });

    const response = await api<
      | IAPIObservationsNoData
      | IAPIObservationsYear
      | IAPIObservationsLast13Months
      | IAPIObservationsLast30Days
      | IAPIObservationsDay
      | IAPIObservationsLast25Hours
    >({
      path: apiPath
    });

    return parseObservations(response);
  }

  return {
    queryKey,
    queryFn
  };
}
