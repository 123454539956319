import settings from './settings';

interface IApiBaseOverride {
  pathId: keyof typeof settings.api.paths;
  apiBase: string;
}

// To use a different server for one of the paths in "settings.api.paths"
// we can define an override here. This is necessary when testing a new API branch
// which only supports one specific path.
// Example override:
// {
//   pathId: 'tide',
//   apiBase: 'https://yr-api-waterlevel-dev.dev.yr.azure.nrk.cloud/api/v0'
// }

const serverOverrides: IApiBaseOverride[] = [];

const pathTemplateRegexes: { [key: string]: RegExp | undefined } = {};

// Create a regex for each API path template
for (const [id, path] of Object.entries(settings.api.paths)) {
  const pattern = path
    // Escape question marks
    .replace(/\?/g, '\\?')
    // Replace placeholders like {id} with [^/]+
    // From: locations/{id}/tide?language={localeCode}
    // To: locations/[^/]+/tide?language=[^/]+
    .replace(/\{.+?\}/g, '[^/]+');

  pathTemplateRegexes[id] = new RegExp(pattern);
}

// Because we fetch data from the API in code used both on the server and in
// the browser we use this helper function to get the correct API base for each.
// The browser fetches data from a relative path while the server needs an absolute URL.
// This helper is also responsible for returning a different absolute URL on the server
// if we have defined a server override in settings.
export function getApiBase(path: string) {
  if (process.env.RUNTIME_ENV === 'test') {
    return settings.api.base.server;
  }

  if (__BROWSER__) {
    return settings.api.base.client;
  }

  if (serverOverrides.length > 0) {
    for (const override of serverOverrides) {
      const pathTemplateRegex = pathTemplateRegexes[override.pathId];
      if (pathTemplateRegex == null) {
        continue;
      }

      if (pathTemplateRegex.test(path)) {
        return override.apiBase;
      }
    }
  }

  return settings.api.base.server;
}
