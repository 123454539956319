import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPITide } from '../../model/api/coast';
import { LocaleCode } from '../../model/locale';
import { parseTide } from './parse';
import { api } from '../api';

export function queryTideByLocationId({ localeCode, locationId }: { localeCode: LocaleCode; locationId: string }) {
  const queryKey = ['tide', localeCode, locationId];

  async function queryFn() {
    const response = await api<IAPITide>({
      path: template(settings.api.paths.tide, { localeCode, locationId })
    });

    return parseTide(response);
  }

  return {
    queryKey,
    queryFn
  };
}
