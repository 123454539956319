import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { initialState, reducer } from './helpers/reducer';

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  theme: 'dark' | 'light';
  // getTheme: () => 'dark' | 'light';
  setTheme: ({ theme }: { theme: 'dark' | 'light' }) => void;
}

const ThemeContext = createContext<IContext | undefined>(undefined);

export function ThemeProvider(props: IProps) {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const setTheme = useCallback(({ theme }: { theme: 'light' | 'dark' }) => {
    dispatch({ type: 'ADD', theme });
  }, []);

  const value = useMemo(() => {
    const { theme } = state;

    return {
      theme,
      setTheme
    };
  }, [state, setTheme]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
