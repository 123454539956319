import { useFetchLocation } from '../../data/locations/hooks';
import { useCurrentLocationId } from '../../lib/hooks';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { track } from '../../lib/analytics/track';
import './SkipToContent.scss';

export const SkipToContent = () => {
  const locationId = useCurrentLocationId();
  const { data: location } = useFetchLocation({ locationId });

  const translate = useTranslate();

  return (
    <div className="skip-to-content">
      <Button
        buttonSize="large"
        buttonType="highlight"
        as="link"
        className="skip-to-content__link"
        href={location ? '#location-heading' : '#page-content'}
        onClick={() => track.event({ category: 'accessibility', action: 'skip_to_content' })}
      >
        {translate('skipToContent/label')}
      </Button>
    </div>
  );
};
