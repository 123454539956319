import { ClickableLink } from '../Clickable/Clickable';
import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';

interface IProps {
  text: string;
  link: {
    url: string;
    text: string;
  };
}

export const Card__LinkFooter = (props: IProps) => {
  const { text, link } = props;

  return (
    <div className="card__link-footer">
      <p className="card__link-footer-link-text">
        <Text size="5">{text}</Text>{' '}
        <Text size="5" isLink={true}>
          <ClickableLink
            data-app-tracking-source="card_footer"
            href={link.url}
            isExternal={true}
            className="card__link-footer-link"
          >
            {link.text}
            <Icon className="card__link-footer-icon" id="icon-new-window" size={2} />
          </ClickableLink>
        </Text>
      </p>
    </div>
  );
};
