import { useFetchLocation } from '../../data/locations/hooks';
import { Search__SuggestedEmpty } from './Search__SuggestedEmpty';
import { Search__SuggestedLocation } from './Search__SuggestedLocation';

interface IProps {
  locationId: string;
  onClick: (params: { locationId: string; href: string }) => void;
}

export function Search__UserLocation(props: IProps) {
  const { locationId, onClick } = props;

  const { data: location } = useFetchLocation({ locationId });

  if (location == null) {
    return <Search__SuggestedEmpty />;
  }

  return <Search__SuggestedLocation location={location} onClick={onClick} />;
}
