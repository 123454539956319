import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import './Button.scss';
import { Button__Content } from './Button__Content';
import { forwardRef } from 'react';

interface IBaseProps {
  buttonType?: 'highlight' | 'primary' | 'critical' | 'subtle';
  buttonSize?: 'small' | 'large';
  buttonShape?: 'square' | 'rounded';
  hideBorder?: boolean;
  ariaLabel?: string;
  iconBefore?: TIconId;
  iconAfter?: TIconId;

  // Disallow data attributes we use internally
  // to prevent anyone from trying to use these by accident
  'data-type'?: never;
  'data-size'?: never;
  'data-shape'?: never;
  'data-disabled'?: never;
}

interface IButtonProps extends IBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  as: 'button';
}

interface ILinkProps extends IBaseProps, React.LinkHTMLAttributes<HTMLAnchorElement> {
  as: 'link';
  isExternal?: boolean;
  href: string;
}

interface ILabelProps extends IBaseProps, React.LabelHTMLAttributes<HTMLLabelElement> {
  as: 'label';
  htmlFor: string;
}

interface ISelectProps extends IBaseProps, React.SelectHTMLAttributes<HTMLSelectElement> {
  as: 'select';
  label: string;
  iconAfter?: never; // The select button already has a chevron icon after the label
}

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement | HTMLLabelElement | HTMLSelectElement,
  IButtonProps | ILinkProps | ILabelProps | ISelectProps
>((props, ref) => {
  if (props.as === 'button') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'square',
      hideBorder = false,
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      disabled,
      children,
      ...htmlProps
    } = props;

    return (
      <button
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        {...htmlProps}
        disabled={disabled}
        className={classNames('button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
        aria-label={ariaLabel}
      >
        <Button__Content iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </button>
    );
  }

  if (props.as === 'link') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'square',
      hideBorder = false,
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      isExternal,
      children,
      ...htmlProps
    } = props;
    const { href, ...otherHtmlProps } = htmlProps;

    // This is written as an object we can spread on the link element to make eslint happy
    // so it doesn't think we're adding `target="_blank"` without a corresponding `rel="noopener noreferrer"` attribute.
    const externalLinkProps = isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : undefined;

    return (
      <a
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        {...otherHtmlProps}
        href={href}
        className={classNames('button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
        {...externalLinkProps}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </a>
    );
  }

  if (props.as === 'label') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'square',
      hideBorder = false,
      className,
      ariaLabel,
      iconBefore,
      iconAfter,
      children,
      ...htmlProps
    } = props;
    const { htmlFor, ...otherHtmlProps } = htmlProps;

    return (
      <label
        ref={ref as React.ForwardedRef<HTMLLabelElement>}
        {...otherHtmlProps}
        htmlFor={htmlFor}
        className={classNames('button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore} iconAfter={iconAfter}>
          {children}
        </Button__Content>
      </label>
    );
  }

  if (props.as === 'select') {
    const {
      as,
      buttonType = 'primary',
      buttonSize = 'small',
      buttonShape = 'square',
      hideBorder = false,
      className,
      label,
      ariaLabel,
      iconBefore,
      disabled,
      children,
      ...htmlProps
    } = props;

    return (
      <label
        className={classNames('button', className)}
        data-type={buttonType}
        data-hide-border={hideBorder}
        data-size={buttonSize}
        data-shape={buttonShape}
        data-disabled={disabled}
      >
        <Button__Content ariaLabel={ariaLabel} iconBefore={iconBefore}>
          {label}
        </Button__Content>

        <Icon id="icon-chevron-down" size={3} />

        <select
          ref={ref as React.ForwardedRef<HTMLSelectElement>}
          {...htmlProps}
          disabled={disabled}
          className="button__select"
        >
          {children}
        </select>
      </label>
    );
  }

  return null;
});
