import settings from '../../app/settings';
import { template } from '../../lib/string';
import { api } from '../api';
import { parseObservations } from './parse';

export function queryObservationsByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['observations', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<any>({
      path: template(settings.api.paths.observations, { locationId })
    });

    return parseObservations(response);
  }

  return {
    queryKey,
    queryFn
  };
}
