import { Icon } from '@nrk/yr-icons';
import { Text } from '../Text/Text';
import './ErrorState.scss';

interface IErrorProps {
  size?: 'small' | 'large';
  message: string;
}

export function ErrorState(props: IErrorProps) {
  const { size = 'large', message } = props;

  return (
    <div className={`error-state error-state--${size}`}>
      <Icon className="error-state__icon" id="icon-error" size={3} />
      <Text as="p" size="5" color="error">
        {message}
      </Text>
    </div>
  );
}
