import classNames from 'classnames';
import { BASE_SIZE } from '../../styles/yr/variables/_variables';
import './Heading.scss';

export interface IProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {
  size: '1' | '2' | '3' | '4' | '5';
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  hidden?: boolean;
  className?: string;
  color?: 'primary' | 'negative' | 'inherit';
  marginBottom?: 1 | 2 | 3;
  marginTop?: 1 | 2 | 3;
  children: React.ReactNode;
}

export function Heading(props: IProps) {
  const {
    size,
    level: Tag,
    hidden,
    className,
    color = 'primary',
    marginBottom,
    marginTop,
    children,
    ...htmlProps
  } = props;

  const marginBottomInPx = marginBottom != null ? `${BASE_SIZE * marginBottom}px` : undefined;
  const marginTopInPx = marginTop != null ? `${BASE_SIZE * marginTop}px` : undefined;

  return (
    <Tag
      className={classNames(`header-${size}`, { 'nrk-sr': hidden }, `heading--color-${color}`, className)}
      style={{
        marginBottom: marginBottomInPx,
        marginTop: marginTopInPx
      }}
      {...htmlProps}
    >
      {children}
    </Tag>
  );
}
