import { ITranslateFunction } from '../model/translate';

export type TDailyPeriod = 'night' | 'morning' | 'afternoon' | 'evening';
export const DAILY_PERIODS: TDailyPeriod[] = ['night', 'morning', 'afternoon', 'evening'];

export function getSymbolDescription({
  id,
  translate,
  period
}: {
  id?: string;
  translate: ITranslateFunction;
  period?: TDailyPeriod;
}) {
  if (id == null) {
    return '';
  }

  const symbolLabel = id ? translate(`symbols/s${id.slice(0, 2)}`) : '';
  const periodLabel = period ? translate(`dailyWeatherList/headings/${period}`) : '';

  return period ? `${periodLabel}: ${symbolLabel}` : symbolLabel;
}
