import { IPosition } from '../model/position';
import { ITranslateFunction } from '../model/translate';
import { captureMessage } from './errorReporter';
import { padNumber } from './string';

// Convert a position to a DMS (degrees, minutes, and seconds) string
export function positionToDms(
  position: { lat?: number; lon?: number },
  divider: string,
  translate: ITranslateFunction
) {
  divider = divider || ' ';

  if (typeof position === 'object' && position.lat != null && position.lon != null) {
    const lat = decimalPositionToObject(position.lat);
    const lon = decimalPositionToObject(position.lon);

    return (
      padNumber(lat.degrees, 2, '0') +
      '°' +
      padNumber(lat.minutes, 2, '0') +
      '′' +
      padNumber(lat.seconds, 2, '0') +
      '″' +
      translate(`direction/${lat.degrees < 0 ? 's' : 'n'}/short`).toUpperCase() +
      divider +
      padNumber(lon.degrees, 2, '0') +
      '°' +
      padNumber(lon.minutes, 2, '0') +
      '′' +
      padNumber(lon.seconds, 2, '0') +
      '″' +
      translate(`direction/${lon.degrees < 0 ? 'w' : 'e'}/short`).toUpperCase()
    );
  }

  return '';
}

// Convert a position to a comma separated lat/long string
export function positionToDecimalDegrees({
  position,
  type = 'default'
}: {
  position: IPosition;
  type?: 'default' | 'locationId' | 'name';
}) {
  // Coordinate location ids should have 3 decimals and no space between lat and lon
  if (type === 'locationId') {
    const lat = position.lat.toFixed(3);
    const lon = position.lon.toFixed(3);

    return `${lat},${lon}`;
  }
  if (type === 'name') {
    const lat = position.lat.toFixed(3);
    const lon = position.lon.toFixed(3);

    return `${lat}, ${lon}`;
  }

  return `${position.lat}, ${position.lon}`;
}

export function numberToString(
  num: number,
  translate: ITranslateFunction,
  forceDecimal = false,
  debug?: { caller: string; originalValue: number; parentCaller?: string; api?: string; locationId?: string }
): string {
  if (num == null) {
    return '-';
  }

  if (!forceDecimal && num % 1 === 0) {
    return num.toString();
  }

  const decimal = translate('grammar/decimal');

  const splitNumber = forceDecimal ? num.toFixed(1).split('.') : num.toString().split('.');

  if (splitNumber[1] == null) {
    let numToString = '(pending)';

    try {
      numToString = num.toString();
    } catch (error) {
      if (num === null) {
        numToString = '(null)';
      } else if (num === undefined) {
        numToString = '(undefined)';
      } else {
        numToString = '(unknown)';
      }
    }

    captureMessage('numberToString() failed to split number in two', {
      extra: {
        url: typeof window !== 'undefined' ? window.location.href : undefined,
        localeCode: translate('localeCode'),
        num,
        isNaN: Number.isNaN(num),
        typeofNum: typeof num,
        numToString,
        forceDecimal,
        caller: debug?.caller,
        originalValue: debug?.originalValue,
        originalIsNaN: debug != null ? Number.isNaN(debug.originalValue) : undefined,
        originalTypeofValue: debug != null ? typeof debug.originalValue : undefined,
        parentOfCaller: debug != null ? debug.parentCaller : undefined,
        apiFailing: debug != null ? debug.api : undefined,
        locationId: debug != null ? debug.locationId : undefined
      }
    });

    return '-';
  }

  return `${splitNumber[0]}${decimal}${splitNumber[1].slice(0, 1)}`;
}

interface IPositionStringObject {
  degrees: number;
  minutes: number;
  seconds: number;
}

function decimalPositionToObject(position: number | string): IPositionStringObject {
  if (typeof position === 'string') {
    position = parseFloat(position);
  }

  const val = Math.abs(position);
  const pos = {
    degrees: Math.floor(val),
    minutes: Math.floor(val * 60) % 60,
    seconds: Math.floor((val * 3600) % 60)
  };

  return pos;
}
