import { QueryClient } from '@tanstack/react-query';
import { queryRegionNames } from '../../data/regions/queries';
import { queryWarnings } from '../../data/warnings/queries';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class WeatherWarningsPageHandler implements IPageHandler {
  getSettings() {
    return {
      isModal: false,
      showHeader: true,
      showFooter: true,
      expandedSearchInput: false
    };
  }

  getMetaData(_queryClient: QueryClient, _pageDetails: IPageDetails, translate: ITranslateFunction) {
    const title = translate('meta/weatherWarnings/title');
    const ogTitle = translate('meta/weatherWarnings/ogTitle');
    const description = translate('meta/weatherWarnings/description');

    return { title, ogTitle, description };
  }

  async getDataQueries({ pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode } = pageDetails.params;

    return [queryWarnings({ localeCode }), queryRegionNames({ localeCode })];
  }
}
