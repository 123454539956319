import {
  IObservationStation,
  IObservedPrecipitation,
  IObservedSnowDepth,
  IObservedTemperature,
  IObservedWind,
  ObservationCardViewType
} from '../../model/observations';
import { parseLocation } from '../locations/parse';

export function parseObservations(data: any): IObservationStation[] {
  if (data._embedded && data._embedded.stations) {
    const stations = data._embedded.stations;

    return stations
      .filter((observationStation: any) => isObservationStationWithData(observationStation))
      .map((observationStation: any) => {
        const observations = observationStation.observations;
        const newVar: IObservationStation = {
          location: parseLocation(observationStation.location),
          distance: observationStation.distance,
          stationId: observationStation.stationId,
          temperature: getObservedTemperature(observations.temperature),
          precipitation: getObservedPrecipitation(observations.precipitation),
          wind: getObservedWind(observations.wind),
          snowDepth: getObservedSnowDepth(observations.snow),
          supported: getSupported(observationStation.observationElementsSupported)
        };
        return newVar;
      });
  } else {
    return [];
  }
}

function isObservationStationWithData(observationStation: any) {
  return (
    observationStation.location &&
    observationStation.distance !== undefined &&
    observationStation.distance >= 0 &&
    observationStation.observations &&
    observationStation.observationElementsSupported &&
    observationStation.stationId
  );
}

function getSupported(observationStationElementsSupported: string[]): ObservationCardViewType[] {
  const supported: { [key: string]: boolean } = { temperature: false, precipitation: false, wind: false };

  observationStationElementsSupported.forEach(element => {
    if (element === 'Precipitation1Hour') {
      supported.precipitation = true;
    } else if (element === 'SnowDepth') {
      supported.precipitation = true;
    } else if (element === 'AirTemperature') {
      supported.temperature = true;
    } else if (element === 'WindGust10Minutes') {
      supported.wind = true;
    } else if (element === 'WindGust1Hour') {
      supported.wind = true;
    } else if (element === 'WindDirection') {
      supported.wind = true;
    } else if (element === 'WindSpeed') {
      supported.wind = true;
    }
  });

  return Object.keys(supported).reduce((accumulator, key) => {
    if (supported[key]) {
      accumulator.push(key as ObservationCardViewType);
    }
    return accumulator;
  }, [] as ObservationCardViewType[]);
}

function getObservedTemperature(temperature: any): IObservedTemperature | undefined {
  if (temperature === undefined || !('intervals' in temperature)) {
    return undefined;
  }

  temperature.intervals.forEach((interval: any) => {
    if (typeof interval.value === 'object') {
      interval.value = undefined;
    }
  });

  const intervals = temperature.intervals;
  return { intervals };
}

function getObservedPrecipitation(precipitation: any): IObservedPrecipitation | undefined {
  if (precipitation === undefined || !('intervals' in precipitation)) {
    return undefined;
  }

  precipitation.intervals.forEach((interval: any) => {
    if (typeof interval.value === 'object') {
      interval.value = undefined;
    }
  });
  const intervals = precipitation.intervals;
  return { intervals };
}

function getObservedWind(wind: any): IObservedWind | undefined {
  if (wind === undefined || !('intervals' in wind)) {
    return undefined;
  }

  const intervals = wind.intervals.map((interval: any) => {
    if (!interval.gust || !(typeof interval.gust === 'number')) {
      return {
        ...interval,
        gust: undefined
      };
    }

    return interval;
  });

  return { intervals };
}

function getObservedSnowDepth(snow: any): IObservedSnowDepth | undefined {
  if (typeof snow === 'object' && !snow.depth && !snow.time) {
    return undefined;
  } else {
    return snow;
  }
}
