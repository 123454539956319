import produce from 'immer';

interface IState {
  isBusy: boolean;
}

type TAction = { type: 'SET'; isBusy: boolean };

export const initialState: IState = { isBusy: false };

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'SET':
      return produce(state, draft => {
        draft.isBusy = action.isBusy;
      });

    default:
      throw new Error();
  }
}
