import classNames from 'classnames';
import { ITranslateFunction } from '../../model/translate';
import { numberToString } from '../../lib/format';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { WindArrow } from '../WindArrow/WindArrow';
import { createWindDisplayValue, isWindExtreme } from '@nrk/yr-helpers';
import './Wind.scss';

interface IWindBase {
  value: number;
  type?: 'wind-only' | 'wind-and-gust';
  direction?: number;
  decimal?: boolean;
  displayUnit?: boolean;
  displayArrow?: boolean;
  tone?: 'primary' | 'secondary' | 'plain';
  className?: string;
  valueClassName?: string;
  unitClassName?: string;
  screenReaderLabel?: string;
  debug?: { caller: string; api: string; locationId?: string };
}

interface IWind extends IWindBase {
  type?: 'wind-only';
}

interface IWindWithGust extends IWindBase {
  windGust?: number;
  type: 'wind-and-gust';
}

type TWindProps = IWindWithGust | IWind;
/**
 * @param screenReaderLabel - Use this if you want to replace the screenReaders
 * representation of the wind content
 */
export const Wind = (props: TWindProps) => {
  const {
    value,
    type = 'wind-only',
    direction,
    decimal = true,
    displayUnit = true,
    displayArrow = false,
    tone = 'plain',
    className,
    valueClassName,
    unitClassName,
    screenReaderLabel,
    debug
  } = props;

  const translate = useTranslate();

  const unitShort = translate('units/metersPerSecond/short');
  const unitLong = translate('units/metersPerSecond/long');

  const displayValue = createWindDisplayValue({ value, decimal, unit: 'mps' });
  const isExtreme = isWindExtreme({ value: displayValue });

  return (
    <span
      className={classNames(
        'wind',
        {
          'wind--extreme': isExtreme,
          'wind--display-arrow': displayArrow
        },
        className
      )}
      data-tone={tone}
    >
      {screenReaderLabel != null && (
        <span className="nrk-sr" data-testid="wind__screen-reader-label">
          {screenReaderLabel}
        </span>
      )}

      <span
        className="wind__container"
        role="text"
        aria-hidden={screenReaderLabel != null}
        data-testid="wind__aria-wrapper"
      >
        <span className={classNames('wind__value', valueClassName)}>
          {numberToString(displayValue, translate, decimal, {
            caller: '<Wind>',
            originalValue: value,
            parentCaller: debug != null ? debug.caller : undefined,
            api: debug != null ? debug.api : undefined,
            locationId: debug != null ? debug.locationId : undefined
          })}
        </span>

        {type === 'wind-and-gust' && renderGust(props as IWindWithGust, translate)}

        {displayUnit && (
          <abbr className={classNames('wind__unit', unitClassName)} title={unitLong}>
            {unitShort}
          </abbr>
        )}

        {displayArrow && (
          <div className="wind__arrow">
            <WindArrow direction={direction} speed={displayValue} />
          </div>
        )}
      </span>
    </span>
  );
};

function renderGust(props: IWindWithGust, translate: ITranslateFunction) {
  const { windGust, decimal = true } = props;
  if (windGust == null) {
    return null;
  }

  const displayValue = createWindDisplayValue({ value: windGust, decimal, unit: 'mps' });
  const isGustExtreme = isWindExtreme({ value: displayValue });

  return (
    <span
      data-testid="wind__gust"
      className={classNames('wind__gust', {
        'wind__gust--extreme': isGustExtreme
      })}
    >
      {` (${numberToString(displayValue, translate, decimal, {
        caller: '<Wind> > renderGust()',
        originalValue: windGust
      })})`}
    </span>
  );
}
