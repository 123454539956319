import { IAPILocation } from './location';
import { TPrecipitationType } from '../precipitation';
import { IWeatherSymbol } from '../weatherSymbol';

export interface IAPIObservationsNoData {
  noData: true;
  location: IAPILocation;
  _links: {
    self: {
      href: string;
    };
  };
}

export interface IAPIObservationsYear extends IAPIObservationsStation {
  historical: {
    units: IAPIObservationsUnits;
    type: 'Year';
    name: string;
    summary: {
      temperature: {
        max?: {
          times: string[];
          value: number;
        };
        min?: {
          times: string[];
          value: number;
        };
      };
      precipitation: {
        wettestMonth?: {
          times?: string[];
          value: number;
        };
        wettestDay?: {
          times?: string[];
          value: number;
        };
        daysWithPrecipitation?: number;
        total?: {
          value: number;
        };
      };
      wind: {
        maxWind?: {
          times?: string[];
          value: number;
        };
        maxGust?: {
          times?: string[];
          value: number;
        };
      };
      snow: {
        maxSnowDepth?: {
          times?: string[];
          value: number;
        };
      };
    };
    months: IAPIObservationsHistoricalMonth[];
  };
  _links: {
    self: {
      href: string;
    };
  };
}

interface IAPIObservationsHistoricalMonth {
  start: string;
  end: string;
  summary: {
    temperature: {
      max?: {
        times: string[];
        value: number;
      };
      min?: {
        times: string[];
        value: number;
      };
      mean?: {
        value: number;
      };
      normal?: {
        value: number;
      };
      deviation?: number;
    };
    precipitation: {
      wettestDay?: {
        times?: string[];
        value: number;
      };
      daysWithPrecipitation?: number;
      total?: {
        value: number;
      };
      normal?: {
        value: number;
      };
    };
    wind: {
      maxWind?: {
        times?: string[];
        value: number;
      };
      maxGust?: {
        times?: string[];
        value: number;
      };
    };
    snow: {
      maxSnowDepth?: {
        times?: string[];
        value: number;
      };
    };
  };
  days: IAPIObservationsHistoricalDayInMonth[];
}

export interface IAPIObservationsDay extends IAPIObservationsStation {
  historical: {
    units: IAPIObservationsUnits;
    type: 'Day';
    name: string;
    summary: {
      temperature: {
        max?: {
          times: string[];
          value: number;
        };
        min?: {
          times: string[];
          value: number;
        };
        mean?: {
          value: number;
        };
        normal?: {
          value: number;
        };
        deviation?: number;
      };
      precipitation: {
        wettestHour?: {
          times?: string[];
          value: number;
        };
        total?: {
          value: number;
        };
      };
      wind: {
        maxWind?: {
          times?: string[];
          value: number;
        };
        maxGust?: {
          times?: string[];
          value: number;
        };
      };
      snow: {
        maxSnowDepth?: {
          times?: string[];
          value: number;
        };
      };
      relativeHumidity: {
        max?: {
          times: string[];
          value: number;
        };
      };
    };
    days: IAPIObservationsHistoricalDay[];
  };
  _links: {
    self: {
      href: string;
    };
  };
}

export interface IAPIObservationsLast13Months extends IAPIObservationsStation {
  historical: {
    units: IAPIObservationsUnits;
    type: 'Year';
    name: 'lastYear';
    summary: {
      temperature: {
        max?: {
          times: string[];
          value: number;
        };
        min?: {
          times: string[];
          value: number;
        };
      };
      precipitation: {
        wettestMonth?: {
          times?: string[];
          value: number;
        };
        wettestDay?: {
          times?: string[];
          value: number;
        };
        daysWithPrecipitation?: number;
        total?: {
          value: number;
        };
      };
      wind: {
        maxWind?: {
          times?: string[];
          value: number;
        };
        maxGust?: {
          times?: string[];
          value: number;
        };
      };
      snow: {
        maxSnowDepth?: {
          times?: string[];
          value: number;
        };
      };
    };
    months: IAPIObservationsHistoricalMonth[];
  };
  _links: {
    self: {
      href: string;
    };
  };
}

export interface IAPIObservationsLast30Days extends IAPIObservationsStation {
  historical: {
    units: IAPIObservationsUnits;
    type: 'Month';
    name: 'last30Days';
    summary: {
      temperature: {
        max?: {
          times: string[];
          value: number;
        };
        min?: {
          times: string[];
          value: number;
        };
      };
      precipitation: {
        wettestDay?: {
          times?: string[];
          value: number;
        };
        daysWithPrecipitation?: number;
        total?: {
          value: number;
        };
      };
      wind: {
        maxWind?: {
          times?: string[];
          value: number;
        };
        maxGust?: {
          times?: string[];
          value: number;
        };
      };
      snow: {
        maxSnowDepth?: {
          times?: string[];
          value: number;
        };
      };
    };
    months: IAPIObservationsHistoricalMonth[];
  };
  _links: {
    self: {
      href: string;
    };
  };
}

export interface IAPIObservationsLast25Hours extends IAPIObservationsStation {
  historical: {
    units: IAPIObservationsUnits;
    type: 'Day';
    name: 'last25Hours';
    summary: {
      temperature: {
        max?: {
          times: string[];
          value: number;
        };
        min?: {
          times: string[];
          value: number;
        };
      };
      precipitation: {
        total?: {
          value: number;
        };
      };
      wind: {
        maxWind?: {
          times?: string[];
          value: number;
        };
        maxGust?: {
          times?: string[];
          value: number;
        };
      };
      snow: {
        maxSnowDepth?: {
          times?: string[];
          value: number;
        };
      };
    };
    days: IAPIObservationsHistoricalLast25Hours[];
  };
  _links: {
    self: {
      href: string;
    };
  };
}

interface IAPIObservationsHistoricalDayData {
  temperature: {
    min?: number;
    max?: number;
    mean?: number;
    normal?: number;
  };
  wind: {
    maxGust?: number;
    meanSpeed?: number;
    maxSpeed?: number;
  };
  snow: {
    depth?: number;
  };
  precipitation: {
    total?: number;
    type?: TPrecipitationType;
  };
  meanCloudCover: {
    value?: number;
  };
}

interface IAPIObservationsHistoricalDayInMonth extends IAPIObservationsHistoricalDayData {
  time: string;
}

interface IAPIObservationsHistoricalDay extends IAPIObservationsHistoricalDayData {
  time: string;
  hours: IAPIObservationsHistoricalHour[];
}

interface IAPIObservationsHistoricalLast25Hours {
  time: string;
  hours: IAPIObservationsHistoricalHour[];
}

interface IAPIObservationsHistoricalHour {
  time: string;
  temperature: {
    min?: number;
    max?: number;
    value?: number;
  };
  wind: {
    maxGust?: number;
    meanSpeed?: number;
    direction?: number;
  };
  snow: {
    depth?: number;
  };
  precipitation: {
    total?: number;
  };
  humidity: {
    value?: number;
  };
  symbol?: IWeatherSymbol;
}

interface IAPIObservationsStation {
  name: string;
  stationId: string;
  distance: number;
  validFrom: string;
  supported: IAPIObservationsSupported;
  location: IAPILocation;
}

interface IAPIObservationsUnits {
  temperature: 'C';
  wind: 'm/s';
  snow: 'cm';
  precipitation: 'mm';
}

interface IAPIObservationsSupported {
  temperature: {
    minMax: boolean;
    mean: boolean;
    normal: {
      start: number;
      end: number;
      supported: boolean;
    };
  };
  precipitation: {
    total: boolean;
    normal: {
      start: number;
      end: number;
      supported: boolean;
    };
  };
  snow: {
    depth: boolean;
  };
  wind: {
    max: boolean;
    mean: boolean;
  };
  humidity: {
    value: boolean;
  };
  meanCloudCover: {
    value: boolean;
  };
}

export function isObservationsYear(
  data:
    | IAPIObservationsYear
    | IAPIObservationsLast13Months
    | IAPIObservationsLast30Days
    | IAPIObservationsDay
    | IAPIObservationsLast25Hours
): data is IAPIObservationsYear {
  return data.historical.type === 'Year' && data.historical.name !== 'lastYear';
}

export function isObservationsLast13Months(
  data:
    | IAPIObservationsYear
    | IAPIObservationsLast13Months
    | IAPIObservationsLast30Days
    | IAPIObservationsDay
    | IAPIObservationsLast25Hours
): data is IAPIObservationsLast13Months {
  return data.historical.type === 'Year' && data.historical.name === 'lastYear';
}

export function isObservationsDay(
  data:
    | IAPIObservationsYear
    | IAPIObservationsLast13Months
    | IAPIObservationsLast30Days
    | IAPIObservationsDay
    | IAPIObservationsLast25Hours
): data is IAPIObservationsDay {
  return data.historical.type === 'Day' && data.historical.name !== 'last25Hours';
}
