import { ClickableLink } from '../Clickable/Clickable';
import { LogoNrk } from '../LogoNrk/LogoNrk';

export function ServedBy__NRKLogo() {
  return (
    <ClickableLink
      className="served-by__logo-link served-by__logo-link--nrk"
      href="http://www.nrk.no"
      data-app-tracking-source="header"
      isExternal={true}
    >
      <LogoNrk className="served-by__logo" />
    </ClickableLink>
  );
}
