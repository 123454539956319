import { useEffect, useState } from 'react';
import { useAriaLive } from '../../contexts/AriaLiveContext/AriaLiveContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Notification } from '../Notification/Notification';
import './OfflineNotification.scss';

export function OfflineNotification() {
  const translate = useTranslate();
  const [isOnline, setIsOnline] = useState(true);
  const { addAriaLive } = useAriaLive();

  useEffect(() => {
    function handleNetworkStatus() {
      const newIsOnline = 'onLine' in window.navigator ? window.navigator.onLine : true;

      setIsOnline(newIsOnline);

      addAriaLive({
        text: newIsOnline ? translate('app/networkStatus/online') : translate('app/networkStatus/offline')
      });
    }

    window.addEventListener('online', handleNetworkStatus, false);
    window.addEventListener('offline', handleNetworkStatus, false);

    return () => {
      window.removeEventListener('online', handleNetworkStatus, false);
      window.removeEventListener('offline', handleNetworkStatus, false);
    };
  }, [addAriaLive, translate]);

  if (isOnline === false) {
    return (
      <div className="offline-notification" aria-hidden={true}>
        <Notification text={translate('app/networkStatus/offline')} icon={{ id: 'icon-offline', type: 'icon' }} />
      </div>
    );
  }

  return null;
}
