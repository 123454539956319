import { template } from '../lib/helpers/url';
import settings from './settings';
import { matchPath } from 'react-router';
import { LocaleCode } from '../model/locale';
import { IPageParams, IPage } from '../model/page';
import { createPathTemplate, pages, TResourceId } from './pages';

interface ICreatePageUrlOptions {
  localeCode: LocaleCode;
  pageId: string;
  subpageId?: string;
  locationId?: string;
  routeId?: string;
  urlPath?: string;
  search?: string;
  hash?: string;
}

export function createPageUrl({
  localeCode,
  pageId,
  subpageId,
  locationId,
  routeId,
  urlPath,
  search,
  hash
}: ICreatePageUrlOptions) {
  let localePageId;
  let localeSubpageId;
  const allPages = [...pages.pages, ...pages.locations, ...pages.pagesWithUrlPath, ...pages.routeForecasts];

  for (const page of allPages) {
    if (page.id === pageId) {
      localePageId = page.path[localeCode];

      if (page.subpages != null) {
        for (const subpage of page.subpages) {
          // If we have a generic subpage path (e.g. extreme page)
          // we use the given subpageId as localeSubpageId
          if (subpage.path[localeCode] === '*') {
            localeSubpageId = subpageId;
            break;
          }

          if (subpage.id === subpageId) {
            localeSubpageId = subpage.path[localeCode];
            break;
          }
        }
      }

      break;
    }
  }

  if (localePageId == null) {
    throw new Error(`Can't find page with pageId ${pageId}`);
  }

  let urlTemplate = '/{localeCode}/{pageId}';

  if (subpageId != null) {
    urlTemplate = `${urlTemplate}/{subpageId}`;

    if (localeSubpageId == null) {
      throw new Error(`Can't find page with subpageId ${subpageId}`);
    }
  }

  if (locationId != null) {
    urlTemplate = `${urlTemplate}/{locationId}`;
  }

  if (routeId != null) {
    urlTemplate = `${urlTemplate}/{routeId}`;
  }

  if (urlPath != null) {
    urlTemplate = `${urlTemplate}/{urlPath}`;
  }

  if (search != null && search !== '') {
    if (search.charAt(0) !== '?') {
      throw new Error('Search string must begin with the character "?"');
    }

    urlTemplate = `${urlTemplate}{search}`;
  }

  if (hash != null && hash !== '') {
    if (hash.charAt(0) !== '#') {
      throw new Error('hash string must begin with the character "#"');
    }

    urlTemplate = `${urlTemplate}{hash}`;
  }

  return template(urlTemplate, {
    localeCode,
    pageId: localePageId,
    subpageId: localeSubpageId,
    locationId,
    routeId,
    urlPath,
    search,
    hash
  });
}

export function translatePageUrl({ localeCode, page }: { localeCode: LocaleCode; page: IPage }) {
  const { pageId, subpageId, subpagePath, params, search } = page.details;
  const { locationId, urlPath, routeId } = params;

  return createPageUrl({
    localeCode,
    pageId,
    // When the subpage path is '*' we want to use the subpage id from the params
    // instead so that /en/extreme/Kaia will be correctly translated to
    // /nb/ekstrem/Kaia.
    subpageId: subpagePath === '*' ? params.subpageId : subpageId,
    locationId,
    urlPath,
    routeId,
    // Don't include search when changing language on the statistics page untill we find a way to localize search parameters
    // See: https://nrknyemedier.atlassian.net/browse/YR-3200
    search: pageId !== 'statistics' ? search : undefined
  });
}

// When pages move around, either due to fixing a typo in the URL or a change
// of navigation, we should redirect the old URLs to the new URL.
// These redirects should ideally never be removed because people may have old
// bookmarks or links pointing to the old URLs.
// If we ever do want to remove any of these old redirects we should create a temporary
// "This page has been moved to [new URL]" page we can show to users for a few months
// so they can update their bookmarks or links instead of just getting a 404 page.
// If we ever implement a "This page has been moved to [new URL]" page feature,
// we should only show it for URLs that have already been redirected for enough
// time for Google and other search engines to have discovered the new URL.
const redirectPages: IRedirectPage[] = [
  // The content of the list/daily page was moved in 2017
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'list',
          subpageId: 'daily'
        },
        nb: {
          pageId: 'liste',
          subpageId: 'dag'
        },
        nn: {
          pageId: 'liste',
          subpageId: 'dag'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'daily-table'
    }
  },
  // The content of the list/hourly page was moved in 2017
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'list',
          subpageId: 'hourly'
        },
        nb: {
          pageId: 'liste',
          subpageId: 'time'
        },
        nn: {
          pageId: 'liste',
          subpageId: 'time'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'hourly-table'
    }
  },
  // The content of the graph/daily page was moved in 2017 or 2018.
  // We currently only have a detailed graph, but in the future we will have a meteogram
  // which will be the closest equivelant of the old graph on the graph/daily page.
  // If the meteogram ends up being moved to forecast/meteogram we will need to update
  // this redirect.
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'graph',
          subpageId: 'daily'
        },
        nb: {
          pageId: 'graf',
          subpageId: 'dag'
        },
        nn: {
          pageId: 'graf',
          subpageId: 'dag'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'graph'
    }
  },
  // The content of the graph/hourly page was moved in 2017 or 2018.
  // In the future the detailed hourly graph will be moved to details/graph
  // and this redirect should be updated.
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'graph',
          subpageId: 'hourly'
        },
        nb: {
          pageId: 'graf',
          subpageId: 'time'
        },
        nn: {
          pageId: 'graf',
          subpageId: 'time'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'graph'
    }
  },
  // The content of the overview/daily page was moved in 2017 or 2018
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'overview',
          subpageId: 'daily'
        },
        nb: {
          pageId: 'oversikt',
          subpageId: 'dag'
        },
        nn: {
          pageId: 'oversikt',
          subpageId: 'dag'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'daily-table'
    }
  },
  // The content of the overview/hourly page was moved in 2017 or 2018
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'overview',
          subpageId: 'hourly'
        },
        nb: {
          pageId: 'oversikt',
          subpageId: 'time'
        },
        nn: {
          pageId: 'oversikt',
          subpageId: 'time'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'hourly-table'
    }
  },
  // We used to have a detailed/graph page in 2017 or so but its contents was moved to forecast/*.
  // The detailed graph was moved to details/graph in 2021 so we need this redirect because of the
  // "detailed" > "details" difference.
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'detailed',
          subpageId: 'graph'
        },
        nb: {
          pageId: 'detaljert',
          subpageId: 'graf'
        },
        nn: {
          pageId: 'detaljert',
          subpageId: 'graf'
        }
      }
    },
    newParams: {
      pageId: 'details',
      subpageId: 'graph'
    }
  },
  // The content of the detailed/table page was moved in 2017 or 2018.
  // The detailed table was later moved to its current location of details/table.
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'detailed',
          subpageId: 'table'
        },
        nb: {
          pageId: 'detaljert',
          subpageId: 'tabell'
        },
        nn: {
          pageId: 'detaljert',
          subpageId: 'tabell'
        }
      }
    },
    newParams: {
      pageId: 'details',
      subpageId: 'table'
    }
  },
  // The graph on the statistics page was moved to a subpage in 2020
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'statistics'
        },
        nb: {
          pageId: 'historikk'
        },
        nn: {
          pageId: 'historikk'
        }
      }
    },
    newParams: {
      pageId: 'statistics',
      subpageId: 'graph'
    }
  },
  // nn/værvarsel/daglig-tabell was moved to nn/vêrvarsel/daglig-tabell in 2020
  // because the old URL had a typo.
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        nn: {
          pageId: 'værvarsel',
          subpageId: 'daglig-tabell'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'daily-table'
    }
  },
  // These URLs were updated because "time-tabell" should be "timetabell"
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        nb: {
          pageId: 'værvarsel',
          subpageId: 'time-tabell'
        },
        nn: {
          pageId: 'vêrvarsel',
          subpageId: 'time-tabell'
        }
      }
    },
    newParams: {
      pageId: 'forecast',
      subpageId: 'hourly-table'
    }
  },
  // The detailed hourly table was moved from the forecast page to details/table
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'forecast',
          subpageId: 'hourly-detailed-table'
        },
        nb: {
          pageId: 'værvarsel',
          subpageId: 'time-detaljert-tabell'
        },
        nn: {
          pageId: 'vêrvarsel',
          subpageId: 'time-detaljert-tabell'
        }
      }
    },
    newParams: {
      pageId: 'details',
      subpageId: 'table',
      // The old forecast/hourly-detailed-table page included the day index as `?i=1`
      // which we do not need on the new details/table page which renders all tables on one page.
      search: ''
    }
  },
  {
    // The detailed hourly table was moved from the forecast page to details/table.
    // This redirects the forecast/detailed-hourly-table URLs which we briefly
    // redirected the old forecast/hourly-detailed-table URLs to before we
    // created the new details/table page.
    // TODO(scb) [2021-06-10]: Since these URLs only were in production for a few days
    //   they should be safe to remove after a few months.
    oldParams: {
      resourceId: 'locationId',
      locales: {
        en: {
          pageId: 'forecast',
          subpageId: 'detailed-hourly-table'
        },
        nb: {
          pageId: 'værvarsel',
          subpageId: 'detaljert-timetabell'
        },
        nn: {
          pageId: 'vêrvarsel',
          subpageId: 'detaljert-timetabell'
        }
      }
    },
    newParams: {
      pageId: 'details',
      subpageId: 'table',
      // The old forecast/detailed-hourly-table page included the day index as `?i=1`
      // which we do not need on the new details/table page which renders all tables on one page.
      search: ''
    }
  },
  {
    // These URLs were updated because "time-tabell" should be "timetabell"
    oldParams: {
      resourceId: 'locationId',
      locales: {
        nb: {
          pageId: 'kyst',
          subpageId: 'time-tabell'
        },
        nn: {
          pageId: 'kyst',
          subpageId: 'time-tabell'
        }
      }
    },
    newParams: {
      pageId: 'coast',
      subpageId: 'hourly-table'
    }
  },
  {
    // Redirect from old mountain-passes url to new subpageId-supported url
    oldParams: {
      resourceId: 'routeId',
      locales: {
        nb: {
          pageId: 'fjelloverganger'
        },
        en: {
          pageId: 'mountain-passes'
        },
        nn: {
          pageId: 'fjellovergangar'
        },
        sme: {
          pageId: 'várregeainnut'
        }
      }
    },
    newParams: {
      pageId: 'mountain-passes',
      subpageId: 'south'
    }
  },
  {
    oldParams: {
      locales: {
        nb: {
          pageId: 'søk',
          subpageId: 'kart'
        },
        nn: {
          pageId: 'søk',
          subpageId: 'kart'
        },
        en: {
          pageId: 'search',
          subpageId: 'map'
        },
        sme: {
          pageId: 'oza',
          subpageId: 'kárta'
        }
      }
    },
    newParams: {
      pageId: 'standalone-map',
      subpageId: 'weather'
    }
  },
  // We removed standalone-map search page winter of 2023
  {
    oldParams: {
      locales: {
        nb: {
          pageId: 'kart',
          subpageId: 'søk'
        },
        nn: {
          pageId: 'kart',
          subpageId: 'søk'
        },
        en: {
          pageId: 'map',
          subpageId: 'search'
        },
        sme: {
          pageId: 'kárta',
          subpageId: 'oza'
        }
      }
    },
    newParams: {
      pageId: 'standalone-map',
      subpageId: 'weather'
    }
  },
  // We renamed nearby page to other-conditions the summer of 2023
  {
    oldParams: {
      resourceId: 'locationId',
      locales: {
        nb: {
          pageId: 'i-nærheten'
        },
        nn: {
          pageId: 'i-nærleiken'
        },
        en: {
          pageId: 'nearby'
        },
        sme: {
          pageId: 'lahkosiin'
        }
      }
    },
    newParams: {
      pageId: 'other-conditions'
    }
  }
];

const redirectRoutes: IGeneratedRedirectRoute[] = [];

function getRedirectRoutes() {
  if (redirectRoutes.length > 0) {
    return redirectRoutes;
  }

  const localeCodes = settings.locale.validCodes as LocaleCode[];

  redirectPages.forEach(page => {
    const { oldParams, newParams } = page;
    for (const localeCode of localeCodes) {
      if (oldParams.locales[localeCode] != null) {
        redirectRoutes.push({
          oldPathTemplate: createPathTemplate({
            localeCode,
            pageId: oldParams.locales[localeCode]!.pageId,
            subpageId: oldParams.locales[localeCode]!.subpageId,
            resourceId: oldParams.resourceId,
            hasUrlPath: true
          }),
          newParams
        });
      }
    }
  });

  return redirectRoutes;
}

interface IGeneratedRedirectParams {
  oldParams: IPageParams;
  newParams: {
    pageId: string;
    subpageId?: string;
    search?: string;
  };
}

export function getRedirectPageRoute(decodedPathname: string): IGeneratedRedirectParams | undefined {
  const routes = getRedirectRoutes();

  for (const route of routes) {
    const match = matchPath<IPageParams>(decodedPathname, {
      path: route.oldPathTemplate,
      exact: true,
      strict: false
    });

    if (match != null) {
      return {
        oldParams: match.params,
        newParams: route.newParams
      };
    }
  }

  return undefined;
}

interface IRedirectPage {
  oldParams: {
    resourceId?: TResourceId;
    locales: {
      en?: {
        pageId: string;
        subpageId?: string;
      };
      nb?: {
        pageId: string;
        subpageId?: string;
      };
      nn?: {
        pageId: string;
        subpageId?: string;
      };
      sme?: {
        pageId: string;
        subpageId?: string;
      };
    };
  };
  newParams: {
    pageId: string;
    subpageId?: string;
    search?: string;
  };
}

interface IGeneratedRedirectRoute {
  oldPathTemplate: string;
  newParams: {
    pageId: string;
    subpageId?: string;
    search?: string;
  };
}
