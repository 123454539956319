import { EmptyText } from '../EmptyText/EmptyText';

export const RouteForecastList__EmptyHeaders = () => {
  return (
    <>
      <span className="route-forecast-list__header">
        <EmptyText type="span" length={13} />
      </span>
      <span className="route-forecast-list__header">
        <EmptyText type="span" length={13} />
      </span>
      <span className="route-forecast-list__header">
        <EmptyText type="span" length={13} />
      </span>
      <span className="route-forecast-list__header">
        <EmptyText type="span" length={13} />
      </span>
    </>
  );
};
