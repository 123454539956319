import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

/**
 * Use lodash's `debounce` to call the given callback when the viewport is resized.
 * The callback is also called when the hook is first run.
 * @param callback — The function to debounce.
 * If the callback function has dependencies it must be created using `useCallback()`.
 * @param wait — The number of milliseconds to delay.
 */
export function useDebouncedResize(callback: () => void, wait?: number) {
  const debouncedCallback = useMemo(() => debounce(callback, wait), [callback, wait]);

  // Call the callback once when the hook is first run
  // (or when the callback function changes)
  useEffect(callback, [callback]);

  useEffect(() => {
    window.addEventListener('resize', debouncedCallback);

    return () => {
      debouncedCallback.cancel();
      window.removeEventListener('resize', debouncedCallback);
    };
  }, [debouncedCallback]);
}
