import classNames from 'classnames';
import { getSymbolDescription, TDailyPeriod } from '../../modelHelpers/weatherSymbol';
import { useTranslate } from '../../lib/hooks/useTranslate';
import './WeatherSymbol.scss';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';

interface IProps {
  id?: string;
  className?: string;
  period?: TDailyPeriod;
  appearance?: 'default' | 'shadows';
}

export function WeatherSymbol(props: IProps) {
  const { id, className, period, appearance = 'default' } = props;

  const { theme } = useTheme();

  const translate = useTranslate();

  if (id == null) {
    return <div className={classNames('weather-symbol', className)}></div>;
  }

  const imageLabel = getSymbolDescription({ id, translate, period });

  return (
    <div className={classNames('weather-symbol', className)}>
      <img
        className="weather-symbol__img"
        data-testid="weather-symbol"
        src={`/assets/images/weather-symbols/${theme}-mode/${appearance}/svg/${id}.svg`}
        alt={imageLabel}
        width="100"
        height="100"
      />
    </div>
  );
}
