import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { queryAirQualityByLocationId } from '../../data/airQuality/queries';
import { queryAuroraForecastByLocationId } from '../../data/auroraForecast/queries';
import { queryCamerasByLocationId } from '../../data/cameras/queries';
import { queryForecastByLocationId } from '../../data/forecast/queries';
import { getLocationFromQueryCache } from '../../data/locations/queries';
import { queryObservationsByLocationId } from '../../data/observations/queries';
import { queryPollenByLocationId } from '../../data/pollen/queries';
import { queryCelestialEventsSingleDayByLocationId } from '../../data/celestialEvents/queries';
import { queryNearestWaterTemperaturesByLocationId } from '../../data/waterTemperatures/queries';
import { TDataQuery } from '../../model/data';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class OtherConditionsPageHandler implements IPageHandler {
  getSettings(_props: { pageDetails: IPageDetails }) {
    return { isModal: false, showHeader: true, showFooter: true, expandedSearchInput: false };
  }

  getMetaData(queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { localeCode, locationId } = pageDetails.params;

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });
    const locationName = location?.name;

    const title = translate('meta/otherConditions/title', { locationName });
    const ogTitle = translate('meta/otherConditions/ogTitle', { locationName });
    const description = translate('meta/otherConditions/description', { locationName });

    return { title, ogTitle, description };
  }

  async getDataQueries({ queryClient, pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { locationId, localeCode } = pageDetails.params;
    if (locationId == null) {
      throw new PageNotFoundError();
    }

    const location = getLocationFromQueryCache({ localeCode, locationId, queryClient });

    const queries: TDataQuery[] = [
      queryForecastByLocationId({ locationId }),
      queryObservationsByLocationId({ locationId }),
      queryCelestialEventsSingleDayByLocationId({ localeCode, locationId })
    ];

    if (location != null) {
      if (location.hasCameras) {
        queries.push(queryCamerasByLocationId({ localeCode, locationId }));
      }

      if (location.hasAirQuality) {
        queries.push(queryAirQualityByLocationId({ localeCode, locationId }));
      }

      if (location.hasPollen) {
        queries.push(queryPollenByLocationId({ localeCode, locationId }));
      }

      if (location.hasWaterTemperatures) {
        queries.push(queryNearestWaterTemperaturesByLocationId({ localeCode, locationId }));
      }

      if (location.hasAuroraForecast) {
        queries.push(queryAuroraForecastByLocationId({ localeCode, locationId }));
      }
    }

    return queries;
  }
}
