import { useId, useEffect, useState } from 'react';
import { useMediaQueryState } from '../../lib/hooks/useMediaQueryState';
import { Heading } from '../Heading/Heading';
import { InfoBadgeButton } from '../InfoBadgeButton/InfoBadgeButton';
import { InfoButton } from '../InfoButton/InfoButton';
import { Text } from '../Text/Text';

type TInfoButton = {
  type: 'button';
  testId?: string;
  ariaLabel?: string;
  renderInfoPanel: (openedById: string, onClose: () => void) => React.ReactNode;
};

type TInfoButtonBadge = {
  type: 'badge';
  testId?: string;
  label: string;
  ariaLabel?: string;
  /**
   * The minimum viewport width where the badge should be shown as a badge.
   * If the viewport is smaller than this a regular info button is shown instead.
   */
  minViewportWidth?: number;
  renderInfoPanel: (openedById: string, onClose: () => void) => React.ReactNode;
};

interface IProps {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  infoButton?: TInfoButton | TInfoButtonBadge;
}

export function Card__Header(props: IProps) {
  const { title, subtitle, infoButton } = props;

  const infoButtonId = useId();
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [visibleInfoButtonType, setVisibleInfoButtonType] = useState<'button' | 'badge'>();

  // This media query is true when we should show the info button badge
  const showInfoButtonBadgeMediaQuery = useMediaQueryState({
    mediaQuery:
      infoButton?.type === 'badge' && infoButton.minViewportWidth != null
        ? `(min-width: ${infoButton.minViewportWidth}px)`
        : undefined,

    // We set the default value to false so we don't show the info button badge
    // before the media query has run.
    defaultValue: false
  });

  useEffect(() => {
    setVisibleInfoButtonType(showInfoButtonBadgeMediaQuery ? 'badge' : 'button');
  }, [showInfoButtonBadgeMediaQuery]);

  return (
    <div className="card__header" data-testid="card-header">
      <Heading level="h3" size="3" className="card__header-title">
        {title}
      </Heading>
      <Text as="p" size="5" className="card__header-subtitle">
        {subtitle}
      </Text>

      {infoButton != null && visibleInfoButtonType === 'button' && (
        <InfoButton
          className="card__header-info-button"
          id={infoButtonId}
          ariaLabel={infoButton.ariaLabel}
          data-testid={infoButton.testId}
          onClick={() => setShowInfoPanel(!showInfoPanel)}
        />
      )}

      {infoButton != null && visibleInfoButtonType === 'badge' && infoButton.type === 'badge' && (
        <InfoBadgeButton
          className="card__header-info-button"
          id={infoButtonId}
          aria-label={infoButton.ariaLabel}
          data-testid={infoButton.testId}
          onClick={() => setShowInfoPanel(!showInfoPanel)}
        >
          {infoButton.label}
        </InfoBadgeButton>
      )}

      {showInfoPanel &&
        infoButton != null &&
        infoButton.renderInfoPanel(infoButtonId, () => setShowInfoPanel(!showInfoPanel))}
    </div>
  );
}
