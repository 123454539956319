import { I18n, setupI18n, Messages } from '@lingui/core';
import { captureMessage } from '../errorReporter';
import { LocaleCode } from '../../model/locale';
import { ITranslateFunction } from '../../model/translate';
import { StringMap } from '../../model/ts-utils';

export const locales: StringMap<{ i18n: I18n; messages: Messages }> = {};

const i18nLocaleCodes = {
  nb: 'nb',
  nn: 'nn',
  en: 'en',
  sme: 'se'
};

export const initializeLocale = (localeCode: LocaleCode, messages: Messages) => {
  const i18n = setupI18n({
    missing: (language: string, id: string) => {
      // Only capture 1% of missing translation errors.
      // Combined with the sample rate of 10% we use when initializing
      // Sentry we only get 0.1% percent of missing translation errors.
      // This is more than enough since this error, when it happens,
      // will happen to almost all users.
      if (Math.random() * 100 === 0) {
        captureMessage(`Undefined translation key ${id} for ${language}`, { level: 'error' });
      }
      return '';
    }
  });

  i18n.load(i18nLocaleCodes[localeCode], messages);
  i18n.activate(i18nLocaleCodes[localeCode]);

  locales[localeCode] = { i18n, messages };
};

export function createTranslateFunction(localeCode: LocaleCode) {
  const translate: ITranslateFunction = (id: string, values?: {}) => locales[localeCode].i18n._(id, values);

  return translate;
}
