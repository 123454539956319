import { useTranslate } from '../../lib/hooks/useTranslate';
import { CardList } from '../CardList/CardList';
import { Heading } from '../Heading/Heading';
import { WebcamCard } from '../WebcamCard/WebcamCard';
import { IWebcamView } from '../WebcamModal/WebcamModal';

interface IProps {
  locationName?: string;
  cameras: Array<{
    name: string;
    distance?: number;
    elevation?: number;
    source?: string;
    attribution?: string;
    views: IWebcamView[];
  }>;
}

export function WebcamCardList(props: IProps) {
  const { locationName, cameras } = props;

  const translate = useTranslate();

  return (
    <div className="webcam-card-list">
      <Heading level="h2" size="2" marginBottom={2} marginTop={2}>
        {translate('webcamCardList/title')}
      </Heading>

      <CardList length={4}>
        {cameras.map((camera, index) => {
          return (
            <WebcamCard
              key={index}
              locationName={locationName}
              cameraName={camera.name}
              distance={camera.distance}
              elevation={camera.elevation}
              source={camera.source}
              attribution={camera.attribution}
              views={camera.views}
            />
          );
        })}
      </CardList>
    </div>
  );
}
