import { useAppState } from '../../app/contexts/AppStateContext';
import { track } from '../../lib/analytics/track';
import { AnalyticsTrackParameters } from '../../lib/analytics/model/analytics';
import { HTMLAttributeAnchorTarget } from 'react';

type buttonElementRef = React.RefObject<HTMLButtonElement>;
type linkElementRef = React.RefObject<HTMLLinkElement>;

interface IBaseProps {
  elementRef?: buttonElementRef | linkElementRef;
  analytics?: AnalyticsTrackParameters;
  replaceHistoryState?: boolean;
  htmlFor?: string;
  onClick?: (event?: any) => any;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  isExternal?: boolean;
}

interface IClickableButtonProps extends IBaseProps, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {}

export function ClickableButton(props: IClickableButtonProps) {
  return <Clickable {...props} tag="button" />;
}

interface IClickableLinkProps extends IBaseProps, Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  replaceHistoryState?: boolean;
  isExternal?: boolean;
}

/**
 * @param isExternal - if true adds `target="_blank" rel="noopener"` to anchor tag
 */
export function ClickableLink(props: IClickableLinkProps) {
  const { isExternal = false } = props;
  if (isExternal) {
    return <Clickable {...props} tag="a" target="_blank" rel="noopener" />;
  }
  return <Clickable {...props} tag="a" />;
}

interface IProps extends IBaseProps {
  tag: string;
}

function Clickable(props: IProps) {
  const { elementRef, onClick, tag = 'button', analytics } = props;

  const { history } = useAppState();

  const TagName = tag as keyof JSX.IntrinsicElements | React.ComponentType<any>;

  function onClickHandler(event: React.MouseEvent) {
    if (analytics) {
      track.event(analytics);
    }

    if (tag === 'a' && props.replaceHistoryState === true) {
      const href = event.currentTarget.getAttribute('href');

      if (href != null) {
        event.preventDefault();

        // Navigate to the link's href without pushing a new state to history.
        history.replace(href);
      }
    }

    if (onClick) {
      onClick(event);
    }
  }

  // Clone props so we can modify it safely
  const propsCopy: IProps & { ref?: buttonElementRef | linkElementRef } = { ...props };

  // Use our own `onClick` handler
  propsCopy.onClick = onClickHandler;

  // Pass the `ref` function on to the element as `ref`, not `elementRef`
  if (elementRef) {
    propsCopy.ref = elementRef;
  }

  // Don't pass invalid props on to the element
  // @ts-ignore We want to delete the "tag" property even though it is not optional
  delete propsCopy.tag;
  delete propsCopy.analytics;
  delete propsCopy.elementRef;
  delete propsCopy.replaceHistoryState;
  delete propsCopy.isExternal;

  return <TagName {...propsCopy} />;
}
