import { QueryClient } from '@tanstack/react-query';
import { PageNotFoundError } from '../../app/errors';
import { queryExtremeEvent } from '../../data/extreme/queries';
import { sentenceCase } from '../../lib/string';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class ExtremePageHandler implements IPageHandler {
  getSettings({ pageDetails }: { pageDetails: IPageDetails }) {
    const embedded = pageDetails.query.embedded;

    return {
      isModal: false,
      showHeader: embedded === false,
      showFooter: embedded === false,
      expandedSearchInput: false
    };
  }

  getMetaData(_queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { subpageId: eventKey } = pageDetails.params;
    if (eventKey == null) {
      return undefined;
    }

    const eventName = sentenceCase(eventKey);

    return {
      title: translate('meta/extreme/title', { eventName }),
      ogTitle: translate('meta/extreme/ogTitle', { eventName }),
      description: translate('meta/extreme/description')
    };
  }

  async getDataQueries({ pageDetails, queryClient }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode, subpageId: eventKey } = pageDetails.params;
    if (eventKey == null) {
      throw new PageNotFoundError();
    }

    // Attempt to fetch the extreme event using `queryClient.fetchQuery` instead of
    // `queryClient.prefetchQuery` to trigger an error.
    // TODO(scb): Do this properly, see https://nrknyemedier.atlassian.net/browse/YR-4771
    await queryClient.fetchQuery(queryExtremeEvent({ localeCode, eventKey }));

    return [queryExtremeEvent({ localeCode, eventKey })];
  }
}
