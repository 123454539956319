import classNames from 'classnames';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { noop } from '../../lib/noop';
import { Banner } from '../Banner/Banner';
import './PageRoot.scss';

interface IProps {
  className?: string;
  isContainer?: boolean;
  children?: React.ReactNode;
  renderChildren?: () => React.ReactNode | undefined;
  renderModalChildren?: () => React.ReactNode | undefined;
}

export function PageRoot(props: IProps) {
  const { className = '', isContainer = false, renderChildren = noop, renderModalChildren = noop } = props;

  const { currentPageSettings } = useAppState();
  const translate = useTranslate();
  const { isModal } = currentPageSettings;

  const children = props.children != null ? props.children : renderChildren();
  const modalChildren = renderModalChildren();

  return (
    <main className={classNames({ 'page-root': true, 'page-root--container': isContainer })}>
      <div className="page-root__static-banner page-root__static-banner--old-browser">
        <div className="layout-container layout-container--with-padding">
          <Banner>{translate('notifications/static/oldBrowser')}</Banner>
        </div>
      </div>
      <div className="page-root__static-banner page-root__static-banner--disabled-js">
        <div className="layout-container layout-container--with-padding">
          <Banner>{translate('notifications/static/disabledJavascript')}</Banner>
        </div>
      </div>

      <div id="page-content" className={`page-root__content ${className}`} aria-hidden={isModal}>
        {children}
      </div>

      <div id="page-modal" className="page-root__modal">
        {modalChildren}
      </div>
    </main>
  );
}
