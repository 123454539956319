import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createPageUrl } from '../../app/redirects';
import { useFetchForecastByLocationId } from '../../data/forecast/hooks';
import { useFetchWarningsByLocationId } from '../../data/warnings/hooks';
import { createRelativeTimeWithHourIntervalLabel } from '../../lib/helpers/time';
import { getDetailedWindDescriptionWithDirection } from '../../lib/helpers/windDescriptionHelpers';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IMountainPassLocation } from '../../model/mountainpass';
import { ClickableLink } from '../Clickable/Clickable';
import { Elevation } from '../Elevation/Elevation';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Temperature } from '../Temperature/Temperature';
import { WarningsIconGroup } from '../WarningsIconGroup/WarningsIconGroup';
import { Wind } from '../Wind/Wind';
import './RouteForecastItem.scss';
import { RouteForecastItem__EmptyForecastCells } from './RouteForecastItem__EmptyForecastCells';
import { Text } from '../Text/Text';
import { Heading } from '../Heading/Heading';

interface IRouteForecastItem {
  location: IMountainPassLocation;
}

export const RouteForecastItem = (props: IRouteForecastItem) => {
  const { location } = props;
  const locationId = location.id;

  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const { isFirstRender } = useAppState();

  const { data: forecast } = useFetchForecastByLocationId({ locationId });
  const { data: warningList } = useFetchWarningsByLocationId({ locationId });

  const forecastIntervals = forecast?.longIntervals.slice(0, 4);

  return (
    <li className="route-forecast-item" role="region">
      <div className="route-forecast-item__location-and-warning">
        <ClickableLink
          href={createPageUrl({
            localeCode,
            pageId: 'forecast',
            subpageId: 'daily-table',
            locationId: location.id,
            urlPath: location.urlPath
          })}
          className="route-forecast-item__location-link"
        >
          <Heading level="h3" size="4" className="route-forecast-item__location-name">
            {location.name}
          </Heading>
          <Elevation elevation={location.elevation} includeParentheses={true} textSize="5" />
        </ClickableLink>

        {warningList != null && warningList.warnings.length > 0 && (
          <WarningsIconGroup stack={true} warnings={warningList.warnings} />
        )}
      </div>
      <Text size="5" color="secondary" aria-hidden className="route-forecast-item__header">
        <span>{translate('grammar/time')}</span>
        <span className="route-forecast-item__header-weather">
          <span className="route-forecast-item__header-symbol"></span>
          <span className="route-forecast-item__header-temperature">{translate('terminology/temperature/short')}</span>
          <span className="route-forecast-item__header-wind">{`${translate(
            'terminology/windWithGust/long'
          )} ${translate('units/metersPerSecond/short')}`}</span>
        </span>
      </Text>
      {(forecastIntervals == null || forecastIntervals.length === 0) && <RouteForecastItem__EmptyForecastCells />}
      {forecastIntervals != null && forecastIntervals.length > 0 && (
        <ol className="route-forecast-item__forecast-list">
          {forecastIntervals.map(forecastInterval => {
            const sixHourSymbolCode = convertSymbolKeyToId(forecastInterval.symbolCode.next6Hours);
            return (
              <li key={forecastInterval.start} className="route-forecast-item__forecast-container" role="text">
                <div className="route-forecast-item__forecast-mobile-divider " />
                <Text size="5" color="secondary" className="route-forecast-item__interval">
                  {createRelativeTimeWithHourIntervalLabel({
                    type: 'relative-date-long',
                    from: forecastInterval.start,
                    to: forecastInterval.end,
                    isFirstRender,
                    translate,
                    transform: 'sentence-case'
                  })}
                </Text>
                <div className="route-forecast-item__forecast">
                  <div className="route-forecast-item__symbol">
                    <WeatherSymbol className="route-forecast-item__symbol-inner" id={sixHourSymbolCode} />
                  </div>
                  <div className="route-forecast-item__temperature">
                    <Text size="4" tabularNums={true}>
                      <Temperature value={forecastInterval.temperature.value} decimal={false} />
                    </Text>
                  </div>
                  <div className="route-forecast-item__wind">
                    <span className="nrk-sr">{translate('terminology/wind/long')}</span>
                    <Text size="4" tabularNums={true}>
                      <Wind
                        displayUnit={false}
                        value={forecastInterval.wind.speed}
                        type="wind-and-gust"
                        windGust={forecastInterval.wind.gust}
                        decimal={false}
                        direction={forecastInterval.wind.direction}
                        displayArrow={true}
                        screenReaderLabel={getDetailedWindDescriptionWithDirection(
                          forecastInterval.wind.speed,
                          forecastInterval.wind.direction,
                          translate,
                          forecastInterval.wind.gust
                        )}
                        debug={{ caller: 'RouteForecastItem', api: 'forecast', locationId: location.id }}
                      />
                    </Text>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      )}
    </li>
  );
};
