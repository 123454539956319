// Wait for the next animation frame before calling the callback.
// We use an rAF (requestAnimationFrame) within an rAF because an rAF requested
// in an event handler will be executed in the same frame, while an rAF queued
// in an rAF will be executed in the next frame.
// See https://medium.com/@paul_irish/requestanimationframe-scheduling-for-nerds-9c57f7438ef4
export function requestNextAnimationFrame(callback: () => void) {
  let raf1: number;
  let raf2: number;

  raf1 = requestAnimationFrame(() => {
    raf2 = requestAnimationFrame(() => {
      callback();
    });
  });

  return function cancelNextAnimationFrame() {
    cancelAnimationFrame(raf1);
    cancelAnimationFrame(raf2);
  };
}
