import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiSnowDepth } from '../../model/api/observations/snowDepths';
import { LocaleCode } from '../../model/locale';
import { parseSnowDepth } from './parse';
import { api } from '../api';

export function querySnowDepthsByRegionId({ localeCode, regionId }: { localeCode: LocaleCode; regionId: string }) {
  const queryKey = ['snowDepth', localeCode, regionId];

  async function queryFn() {
    const response = await api<IApiSnowDepth>({
      path: template(settings.api.paths.snowDepth, { localeCode, regionId })
    });

    return parseSnowDepth(response);
  }

  return {
    queryKey,
    queryFn
  };
}
