import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPISuggest } from '../../model/api/suggest';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseSearch } from './parse';

export function querySearchByQuery({ localeCode, query }: { localeCode: LocaleCode; query: string }) {
  const queryKey = ['searchByQuery', localeCode, query];

  async function queryFn() {
    const response = await api<IAPISuggest>({
      path: template(settings.api.paths.searchByQuery, { localeCode, query })
    });

    return parseSearch(response);
  }

  return {
    queryKey,
    queryFn
  };
}

export function querySearchByPosition({ localeCode, lat, lon }: { localeCode: LocaleCode; lat: number; lon: number }) {
  const queryKey = ['searchByPosition', localeCode, lat, lon];

  const accuracy = 1000;

  async function queryFn() {
    const response = await api<IAPISuggest>({
      path: template(settings.api.paths.searchByPosition, { localeCode, lat, lon, accuracy })
    });

    return parseSearch(response);
  }

  return {
    queryKey,
    queryFn
  };
}
