import { encode } from '../../lib/helpers/url';
import { IAPILocation, IApiLocationRegion } from '../../model/api/location';
import { ILocation } from '../../model/location';

export function parseLocation(location: IAPILocation): ILocation {
  return {
    id: location.id,
    name: location.name,
    position: location.position,
    coastalPoint: location.coastalPoint != null ? location.coastalPoint : undefined,
    isInOcean: location.isInOcean != null ? location.isInOcean : undefined,
    elevation: location.elevation,
    timeZone: location.timeZone,
    category: parseNamedObject(location.category),
    isCoordinate: location.category.id === 'CO01',
    countryID: location.country != null ? location.country.id : undefined,
    country: parseNamedObject(location.country),
    isNorway: location.country != null ? location.country.id === 'NO' : false,
    region: parseNamedObject(location.region),
    trimmedRegionId: parseRegionId(location.region),
    subregion: parseNamedObject(location.subregion),

    // Always encode urlpath
    urlPath: location.urlPath != null ? encode(location.urlPath) : '',

    // Parse HAL links to determine what features this location supports
    hasCurrentHour: location._links != null ? Boolean(location._links.currenthour) : false,
    hasNowcast: location._links != null ? Boolean(location._links.now) : false,
    hasWarnings: location._links != null ? Boolean(location._links.notifications) : false,
    hasExtremeEventList: location._links != null ? Boolean(location._links.extremeforecasts) : false,
    hasAirQuality: location._links != null ? Boolean(location._links.airqualityforecast) : false,
    hasAuroraForecast: location._links != null ? Boolean(location._links.auroraforecast) : false,
    hasPollen: location._links != null ? Boolean(location._links.pollen) : false,
    hasWaterTemperatures: location._links != null ? Boolean(location._links.watertemperatures) : false,
    hasCameras: location._links != null ? Boolean(location._links.cameras) : false,
    hasCoast: location._links != null ? Boolean(location._links.coast) : false,
    hasTide: location._links != null ? Boolean(location._links.tide) : false,
    hasObservations: location._links != null ? Boolean(location._links.observations) : false,
    hasSubseasonalforecast: location._links != null ? Boolean(location._links.subseasonalforecast) : false
  };
}

function parseNamedObject(namedObject?: { name: string }) {
  if (namedObject != null && typeof namedObject === 'object' && 'name' in namedObject) {
    return namedObject.name;
  }

  // If subregion does not have a name we return undefined
  return undefined;
}

function parseRegionId(region?: IApiLocationRegion): string | undefined {
  if (region != null && typeof region === 'object' && 'id' in region) {
    // Replace all `/` with a `-` for a URL safe region id
    let regionId = region.id.replace(/\//g, '-');

    return regionId;
  }

  // If region is undefined or does not have id, we want to return undefined
  return undefined;
}
