import { isValidElement, Fragment } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { sentenceCase } from '../../lib/string';

interface IProps {
  id: string;
  values?: { [key: string]: JSX.Element | string };
  splitLinebreaksAs?: 'li' | 'p' | 'br';
  transform?: 'sentence-case';
}

export function Translate(props: IProps) {
  const { id, values, splitLinebreaksAs = undefined, transform } = props;

  const translate = useTranslate();

  const valuesWithReactPlaceholderTags: { [id: string]: string } = {};

  if (values != null) {
    for (const [key, value] of Object.entries(values)) {
      if (isJsxElement(value)) {
        valuesWithReactPlaceholderTags[key] = `<${key}/>`;
      } else {
        valuesWithReactPlaceholderTags[key] = value;
      }
    }
  }

  const translation = translate(id, valuesWithReactPlaceholderTags);

  const translationLines = translation.split('\n');
  const resultLines = translationLines.map((translation, index) => {
    return transformStringWithReactComponents({
      index,
      translation: transform === 'sentence-case' ? sentenceCase(translation) : translation,
      values,
      splitLinebreaksAs
    });
  });

  return <>{resultLines}</>;
}

function transformStringWithReactComponents({
  index,
  translation,
  values,
  splitLinebreaksAs
}: {
  index: number;
  translation: string;
  values?: { [key: string]: JSX.Element | string };
  splitLinebreaksAs?: 'li' | 'p' | 'br';
}) {
  const result: Array<string | React.ReactNode> = [];

  if (values == null) {
    result.push(translation);
  } else {
    // Using String.prototype.split() with a regex with a capturing group
    // results in an array that includes the separator strings.
    const tokens: string[] = translation.split(/(<.+?\/>)/);

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      // If we have no values to replace
      if (values == null) {
        result.push(token);
        continue;
      }

      // Extract the key from the `<key/>` placeholder
      const keyMatches = token.match(/^<(.+)\/>$/);

      // If the current token isn't a placeholder we don't need to replace it with a value
      if (keyMatches == null) {
        result.push(token);
        continue;
      }

      const key = keyMatches[1];
      const value = values[key];

      // Print the placeholder if we don't have a value to replace it with
      if (value == null) {
        result.push(token);
        continue;
      }

      result.push(<Fragment key={i}>{value}</Fragment>);
    }
  }

  if (splitLinebreaksAs != null) {
    const TagName = splitLinebreaksAs as keyof JSX.IntrinsicElements | React.ComponentType<any>;

    if (TagName === 'br') {
      return (
        <Fragment key={index}>
          {result}
          <TagName />
        </Fragment>
      );
    }

    return <TagName key={index}>{result}</TagName>;
  }

  return result;
}

function isJsxElement(value: JSX.Element | string): value is JSX.Element {
  return isValidElement(value);
}
