import { ServedBy__NRKLogo } from './ServedBy__NRKLogo';
import { ServedBy__METLogo } from './ServedBy__METLogo';
import { useTranslate } from '../../lib/hooks/useTranslate';
import './ServedBy.scss';
import { Text } from '../Text/Text';

interface IProps {
  type?: 'text' | 'logo';
}

export const ServedBy = (props: IProps) => {
  const { type = 'text' } = props;

  const translate = useTranslate();

  if (type === 'text') {
    return (
      <div className="served-by">
        <Text className="served-by__text" as="p" size="4">
          {translate('global/servedBy/text')}
        </Text>
      </div>
    );
  }

  return (
    <div className="served-by">
      <Text size="5" className="served-by__logo-text">
        {translate('global/servedBy/header')}
      </Text>
      <ServedBy__NRKLogo />
      <ServedBy__METLogo />
    </div>
  );
};
