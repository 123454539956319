import { Icon, TIconId } from '@nrk/yr-icons';
import { ClickableLink } from '../Clickable/Clickable';
import { WarningIconImage } from '../WarningIconImage/WarningIconImage';
import { WarningIconId } from '../../model/icon';
import './Notification.scss';

interface INotificationWarningIcon {
  type: 'warning';
  id: WarningIconId;
}

interface INotificationIcon {
  type: 'icon';
  id: TIconId;
}

export interface IProps {
  text: string;
  icon: INotificationWarningIcon | INotificationIcon;
  href?: string;
}

export const Notification = (props: IProps) => {
  const { text, icon, href } = props;

  const notificationContent = (
    <div className="layout-container">
      <div className="notification__content">
        {icon.type === 'warning' ? (
          <WarningIconImage className="notification__icon" id={icon.id} size={4} />
        ) : (
          <Icon className="notification__icon" id={icon.id} size={4} />
        )}
        <div className="notification__text">{text}</div>
      </div>
    </div>
  );

  const notification = href ? (
    <ClickableLink href={href} className="notification__link" data-testid={`notification-link-${href}`}>
      {notificationContent}
    </ClickableLink>
  ) : (
    notificationContent
  );

  return (
    <div className="notification" data-testid="notification">
      {notification}
    </div>
  );
};
