import { sentenceCase } from '../../lib/string';
import { IExtremeEvent, IExtremeEventArea, IExtremeEventListItem } from '../../model/extreme';
import { StringMap } from '../../model/ts-utils';
import { IWarning } from '../../model/warning';

export function parseExtremeEvent(data: IExtremeEventListItem): IExtremeEvent | undefined {
  const extremeEvent = {
    incidentName: data.incidentName,
    areas: groupWarningsByArea(data.warnings)
  };

  return extremeEvent;
}
// Each warning can have multiple areas, and multiple warnings can
// include the same area. This function returns an object containing
// each unique area with a list of all warnings that includes that area.
function groupWarningsByArea(warnings: IWarning[]) {
  const areas: StringMap<IExtremeEventArea> = {};

  for (const warning of warnings) {
    if (warning.meta.areas == null) {
      continue;
    }

    for (const area of warning.meta.areas) {
      const areaName = sentenceCase(area);
      if (areas[areaName] == null) {
        areas[areaName] = {
          name: areaName,
          warnings: []
        };
      }

      areas[areaName].warnings.push(warning);
    }
  }

  return areas;
}
