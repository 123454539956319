import { ClickableLink } from '../Clickable/Clickable';

interface IProps {
  href: string;
  children: React.ReactNode;
}

export function PageFooter__Link(props: IProps) {
  const { children, href } = props;

  return (
    <ClickableLink href={href} data-app-tracking-source="footer" isExternal={true}>
      {children}
    </ClickableLink>
  );
}
