import { useQueryWrapper } from '../query';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { queryLocation } from './queries';

export function useFetchLocation({ locationId }: { locationId?: string }) {
  const localeCode = useLocaleCode();

  return useQueryWrapper({
    ...queryLocation({ localeCode, locationId }),

    // The query will not execute until locationId exists
    enabled: locationId != null
  });
}
