import { IApiAllMountainPassesResponse, IApiMountainPassResponse } from '../../model/api/mountainpass';
import { IAllMountainPasses, IMountainPassResponse, IMountainPassRoute } from '../../model/mountainpass';

export function parseAllMountainPasses(data: IApiAllMountainPassesResponse): IAllMountainPasses {
  const routes: {
    [key: string]: IMountainPassRoute[];
  } = {};
  data.routes.forEach(route => {
    if (routes[route.collection] != null) {
      routes[route.collection].push(route);
      return;
    }

    routes[route.collection] = [route];
  });

  return {
    routes
  };
}

export function parseMountainPass(data: IApiMountainPassResponse): IMountainPassResponse {
  const parsedRoadMessages = data.roadMessages?.map(roadMessage => {
    const parsedMessage = roadMessage.message.split('\n');

    return { ...roadMessage, message: parsedMessage };
  });
  return { ...data, roadMessages: parsedRoadMessages };
}
