import { default as time, default as yrTime } from '@nrk/yr-time';
import { IForecastLongInterval, IForecastShortInterval } from '../../model/forecast';
import { normalizeValueWithinRange } from './math';

export function forEachForecastIntervals<
  LongInterval extends { nominalStart: string; nominalEnd: string; end: string; start: string },
  ShortInterval extends { end: string; start: string }
>({
  shortIntervals,
  longIntervals,
  intervalCallback
}: {
  shortIntervals: ShortInterval[];
  longIntervals: LongInterval[];
  intervalCallback: (normalizedX: number, interval: ShortInterval | LongInterval) => void;
}) {
  const start = longIntervals[0].nominalStart;
  const end = longIntervals[longIntervals.length - 1].nominalStart;
  const lastShortIntervalStart = yrTime.create(shortIntervals[shortIntervals.length - 1].start);

  shortIntervals.forEach(interval => {
    const normalizedX = normalizeIntervalBetweenStartAndEnd({
      start,
      end,
      intervalStart: interval.start,
      intervalEnd: interval.end
    });

    intervalCallback(normalizedX, interval);
  });

  longIntervals.forEach(interval => {
    const intervalStartTime = yrTime.create(interval.start);
    // We only use the longIntervals that starts after the last short interval
    if (intervalStartTime.isAfter(lastShortIntervalStart)) {
      const normalizedX = normalizeIntervalBetweenStartAndEnd({
        start,
        end,
        intervalStart: interval.start,
        intervalEnd: interval.end
      });

      intervalCallback(normalizedX, interval);
    }
  });
}

export function forEachForecastLongIntervals<
  LongInterval extends { nominalStart: string; nominalEnd: string; end: string; start: string }
>({
  longIntervals,
  intervalCallback
}: {
  longIntervals: LongInterval[];
  intervalCallback: ({
    normalizedX,
    normalizedWidth,
    interval,
    isLastInterval
  }: {
    normalizedX: number;
    normalizedWidth: number;
    interval: LongInterval;
    isLastInterval: boolean;
  }) => void;
}) {
  const start = longIntervals[0].nominalStart;
  const end = longIntervals[longIntervals.length - 1].nominalStart;
  const normalizedWidth = 1 / longIntervals.length;

  longIntervals.forEach((interval, index) => {
    const isLastInterval = index === longIntervals.length - 1;
    const normalizedX = normalizeTimeBetweenStartAndEnd({
      start,
      end,
      time: interval.nominalStart
    });
    intervalCallback({ normalizedX, normalizedWidth, interval, isLastInterval });
  });
}

export function normalizeTimeBetweenStartAndEnd({ start, end, time }: { start: string; end: string; time: string }) {
  const timeStart = new Date(start).getTime();
  const timeEnd = new Date(end).getTime();
  const timeTime = new Date(time).getTime();

  return normalizeValueWithinRange(timeStart, timeEnd, timeTime);
}

export function normalizeIntervalBetweenStartAndEnd({
  start,
  end,
  intervalStart,
  intervalEnd
}: {
  start: string;
  end: string;
  intervalStart: string;
  intervalEnd: string;
}) {
  const timeStart = new Date(start).getTime();
  const timeEnd = new Date(end).getTime();
  const timeIntervalStart = new Date(intervalStart).getTime();
  const timeIntervalEnd = new Date(intervalEnd).getTime();
  const timeIntervalCenter = (timeIntervalStart + timeIntervalEnd) / 2;

  return normalizeValueWithinRange(timeStart, timeEnd, timeIntervalCenter);
}

export function groupForecastIntervalsByDay<
  LongInterval extends { nominalStart: string; nominalEnd: string; end: string; start: string },
  ShortInterval extends { end: string; start: string }
>(
  shortIntervals: ShortInterval[],
  longIntervals: LongInterval[],
  uniqueDaysFromDayIntervals: { start: string; end: string }[]
) {
  const forecastIntervalsByDay = uniqueDaysFromDayIntervals.map(day => {
    const { start, end } = day;
    const currentDay = time.create(start);

    const currentDayShortIntervals = shortIntervals.filter(interval =>
      filterForecastIntervalsByDay(interval, currentDay)
    );

    const currentDayLongIntervals = longIntervals.filter(interval =>
      filterForecastIntervalsByDay(interval, currentDay)
    );

    if (currentDayShortIntervals.length === 0) {
      return { start, end, intervals: currentDayLongIntervals };
    }
    if (currentDayShortIntervals.length === 24) {
      return { start, end, intervals: currentDayShortIntervals };
    }
    const currentDayMergedIntervals: Array<ShortInterval | LongInterval> = [...currentDayShortIntervals];

    currentDayLongIntervals.forEach(longInterval => {
      let startTimeIsAlreadyInList = currentDayMergedIntervals.some(mergedInterval => {
        return longInterval.start === mergedInterval.start;
      });
      if (startTimeIsAlreadyInList === false) {
        currentDayMergedIntervals.push(longInterval);
      }
    });

    return { start, end, intervals: currentDayMergedIntervals };
  });

  return forecastIntervalsByDay;
}

function filterForecastIntervalsByDay<
  LongInterval extends { nominalStart: string; nominalEnd: string; end: string; start: string },
  ShortInterval extends { end: string; start: string }
>(interval: ShortInterval | LongInterval, day: time.YrTime) {
  const currentInterval = time.create(interval.start);
  if (day.isSame(currentInterval, 'day')) {
    return true;
  }
  return false;
}

export function isForecastLongInterval(
  interval: IForecastShortInterval | IForecastLongInterval
): interval is IForecastLongInterval {
  return 'nominalStart' in interval === true;
}
