import { useEffect, useState } from 'react';

/**
 * Debounce the given value
 * @param value The value to debounce.
 * @param wait The number of milliseconds to delay.
 */
export function useDebounceValue<T>({ initialValue, wait }: { initialValue?: T; wait: number }) {
  const [value, setValue] = useState<T | undefined>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), wait);

    return () => {
      clearTimeout(timer);
    };
  }, [value, wait]);

  return [debouncedValue, setValue] as const;
}
