import { IApiRegions } from '../../model/api/region';
import { IRegion } from '../../model/region';

export function parseRegions(data: IApiRegions): IRegion[] {
  return data.regions;
}

export function parseRegionNames(data: IApiRegions): string[] {
  return data.regions.map(region => region.name);
}
