import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useFetchLocation } from '../locations/hooks';
import { useQueryWrapper } from '../query';
import { queryExtremeEvent, queryExtremeEventList } from './queries';

export function useFetchExtremeEventList({ locationId }: { locationId?: string }) {
  const localeCode = useLocaleCode();
  const { data: location } = useFetchLocation({ locationId });

  return useQueryWrapper({
    ...queryExtremeEventList({ localeCode, locationId }),

    // Only execute this query if the location supports extreme event list
    enabled: location != null && location.hasExtremeEventList
  });
}

export function useFetchExtremeEvent({ eventKey }: { eventKey?: string }) {
  const localeCode = useLocaleCode();

  return useQueryWrapper({
    ...queryExtremeEvent({ localeCode, eventKey }),

    // The query will not execute until eventKey exists
    enabled: eventKey != null
  });
}
