import { IAPIWaterLevel } from '../../model/api/coast';
import { IWaterLevel } from '../../model/coast';

export function parseWaterLevel(data: IAPIWaterLevel): IWaterLevel | undefined {
  if (data._embedded.hours.length === 0) {
    return undefined;
  }

  return data._embedded;
}
