import produce from 'immer';

interface IState {
  theme: 'dark' | 'light';
}

type TAction = { type: 'ADD'; theme: 'dark' | 'light' };

export const initialState: IState = { theme: 'light' };

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'ADD':
      return produce(state, draft => {
        draft.theme = action.theme;
      });

    default:
      throw new Error();
  }
}
