import { LoadableComponent } from '@loadable/component';
import { Location } from 'history';
import { QueryClient } from '@tanstack/react-query';
import { TDataQuery } from './data';
import { LocaleCode } from './locale';
import { IPageMetaData } from './metaTags';
import { ITranslateFunction } from './translate';

export interface IPageParams {
  url: string;
  localeCode: LocaleCode;
  pageId: string;
  subpageId?: string;
  locationId?: string;
  routeId?: string;
  urlPath?: string;
}

// The home page does not have a `pageId` parameter.
export interface IUrlParams extends Omit<IPageParams, 'pageId'> {
  pageId?: string;
}

export interface IPageQuery {
  embedded: boolean;
  debug?: boolean;
  i: number;
  q?: string;
  lat?: number;
  lon?: number;
  lang?: LocaleCode;
  zoom?: number;
}

export interface IPage {
  location: Location;
  details: IPageDetails;
}

export interface IPageDetails {
  key: string;
  name: string;
  component: LoadableComponent<object>;
  pathTemplate: string;
  pageId: string;
  subpageId?: string;
  pagePath: string;
  subpagePath?: string;
  params: IPageParams;
  query: IPageQuery;
  search: string;
  handler: IPageHandler;
  hasLocaleCode: boolean;
}

export interface IPageHandlerGetDataQueriesOptions {
  queryClient: QueryClient;
  pageDetails: IPageDetails;
}

interface IPageHandlerGetSettingsOptions {
  pageDetails: IPageDetails;
}

export interface IPageHandler {
  getSettings: (options: IPageHandlerGetSettingsOptions) => IPageSettings;
  getMetaData: (
    queryClient: QueryClient,
    pageDetails: IPageDetails,
    translate: ITranslateFunction
  ) => IPageMetaData | undefined;
  getDataQueries?: (options: IPageHandlerGetDataQueriesOptions) => Promise<TDataQuery[]>;
}
export interface IPageSettings {
  isModal: boolean;
  showHeader: boolean;
  showFooter: boolean;
  expandedSearchInput: boolean;
}

export function isSamePage(
  //  Added urlPath, to make sure it scrolls to top on navigate from regionlist
  oldPageParams: { pageId: string; locationId?: string; routeId?: string; urlPath?: string },
  newPageParams: { pageId: string; locationId?: string; routeId?: string; urlPath?: string }
) {
  return (
    oldPageParams.pageId === newPageParams.pageId &&
    oldPageParams.locationId === newPageParams.locationId &&
    oldPageParams.routeId === newPageParams.routeId &&
    oldPageParams.urlPath === newPageParams.urlPath
  );
}

export function getLocationIdFromParams(params: IPageParams) {
  const { locationId } = params;

  if (locationId == null) {
    throw new Error('Missing "locationId"');
  }

  return locationId;
}
