import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IExtremeEventList, IExtremeEventListItem } from '../../model/extreme';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseExtremeEvent } from './parse';

export function queryExtremeEventList({ localeCode, locationId }: { localeCode: LocaleCode; locationId?: string }) {
  const queryKey = ['extremeEventList', localeCode, locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IExtremeEventList>({
      path: template(settings.api.paths.extremeEventList, { localeCode, locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}

export function queryExtremeEvent({ localeCode, eventKey }: { localeCode: LocaleCode; eventKey?: string }) {
  const queryKey = ['extremeEvent', localeCode, eventKey];

  async function queryFn() {
    if (eventKey == null) {
      return;
    }

    const response = await api<IExtremeEventListItem>({
      path: template(settings.api.paths.extremeEvent, { localeCode, eventKey })
    });

    return parseExtremeEvent(response);
  }

  return {
    queryKey,
    queryFn
  };
}
