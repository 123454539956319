import produce from 'immer';
import { TToastType, IToastContent } from '../ToastContext';

interface IState {
  id?: number;
  type?: TToastType;
  content?: IToastContent;
}

type TAction =
  | { type: 'ERROR'; content: IToastContent }
  | { type: 'NOTIFICATION'; content: IToastContent }
  | { type: 'REMOVE' };

export const initialState: IState = {};

let id = 0;

export function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case 'ERROR':
      return produce(state, draft => {
        // Do not render a new error on top of an old one
        if (draft.type !== 'ERROR') {
          draft.id = id++;
          draft.type = 'ERROR';
          draft.content = action.content;
        }
      });

    case 'NOTIFICATION':
      return produce(state, draft => {
        // Do not render a notification on top of an error
        if (draft.type !== 'ERROR') {
          draft.id = id++;
          draft.type = 'NOTIFICATION';
          draft.content = action.content;
        }
      });

    case 'REMOVE':
      return produce(state, draft => {
        draft.id = undefined;
        draft.type = undefined;
        draft.content = undefined;
      });

    default:
      throw new Error();
  }
}
