import { Icon, TIconId } from '@nrk/yr-icons';

interface IProps {
  ariaLabel?: string;
  iconBefore?: TIconId;
  iconAfter?: TIconId;
  children: React.ReactNode;
}

export function Button__Content(props: IProps) {
  const { ariaLabel, iconBefore, iconAfter, children } = props;

  return (
    <>
      {ariaLabel != null ? <span className="nrk-sr">{ariaLabel}</span> : null}

      {iconBefore != null ? <Icon id={iconBefore} size={2} /> : null}
      <span className="button__content-text" aria-hidden={ariaLabel != null ? true : undefined}>
        {children}
      </span>
      {iconAfter != null ? <Icon className="button__icon-after" id={iconAfter} size={2} /> : null}
    </>
  );
}
