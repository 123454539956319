import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

/**
 * Debounce the given callback using lodash's `debounce`.
 * @param callback The function to debounce.
 * If the callback function has dependencies it must be created using `useCallback()`.
 * @param wait The number of milliseconds to delay.
 */
export function useDebouncedCallback(callback: () => void, wait?: number) {
  const debouncedCallback = useMemo(() => debounce(callback, wait), [callback, wait]);

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  return debouncedCallback;
}
