import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPICoastForecast } from '../../model/api/coast';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseCoastForecast } from './parse';

export function queryCoastForecastByLocationId({
  localeCode,
  locationId
}: {
  localeCode: LocaleCode;
  locationId: string;
}) {
  const queryKey = ['coastForecast', localeCode, locationId];

  async function queryFn() {
    const response = await api<IAPICoastForecast>({
      path: template(settings.api.paths.coastForecast, { localeCode, locationId })
    });

    return parseCoastForecast(response);
  }

  return {
    queryKey,
    queryFn
  };
}
