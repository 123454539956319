import classNames from 'classnames';
import './Text.scss';

export interface IProps
  extends React.HtmlHTMLAttributes<HTMLElement>,
    React.AnchorHTMLAttributes<HTMLElement>,
    Pick<React.ThHTMLAttributes<HTMLElement>, 'colSpan' | 'scope'> {
  size: '1' | '2' | '3' | '4' | '5' | '6';
  weight?: 'normal' | 'bold';
  as?: 'p' | 'span' | 'div' | 'th' | 'td' | 'table' | 'a' | 'ul' | 'caption' | 'legend' | 'li';
  className?: string;
  isLink?: boolean;
  tabularNums?: boolean;
  children?: React.ReactNode;
  color?: 'primary' | 'secondary' | 'error' | 'negative' | 'link' | 'inherit';
}

export function Text(props: IProps) {
  const {
    size,
    as: Tag = 'span',
    weight = 'normal',
    className,
    color = 'primary',
    tabularNums = false,
    isLink = false,
    children,
    ...htmlProps
  } = props;

  const colorClassName = isLink ? 'text--color-link' : `text--color-${color}`;
  return (
    <Tag
      className={classNames(
        `text-${size}`,
        colorClassName,
        {
          'text--is-link': isLink,
          'text-tabular-nums': tabularNums,
          'text-weight-normal': weight === 'normal',
          'text-weight-bold': weight === 'bold'
        },
        className
      )}
      {...htmlProps}
    >
      {children}
    </Tag>
  );
}
