import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPIWaterLevel } from '../../model/api/coast';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseWaterLevel } from './parse';

export function queryWaterLevelByLocationId({
  localeCode,
  locationId
}: {
  localeCode: LocaleCode;
  locationId: string;
}) {
  const queryKey = ['waterLevel', localeCode, locationId];

  async function queryFn() {
    const response = await api<IAPIWaterLevel>({
      path: template(settings.api.paths.waterLevel, { localeCode, locationId })
    });

    return parseWaterLevel(response);
  }

  return {
    queryKey,
    queryFn
  };
}
