import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPITwentyOneDayForecast } from '../../model/api/twentyOneDayForecast';
import { api } from '../api';

export function queryTwentyOneDayForecastByLocationId({ locationId }: { locationId?: string }) {
  const queryKey = ['twentyOneDayForecast', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }
    const response = await api<IAPITwentyOneDayForecast>({
      path: template(settings.api.paths.subseasonalforecast, { locationId })
    });

    return response;
  }

  return {
    queryKey,
    queryFn
  };
}
