import {
  IAPIObservationsDay,
  IAPIObservationsLast13Months,
  IAPIObservationsLast25Hours,
  IAPIObservationsLast30Days,
  IAPIObservationsNoData,
  IAPIObservationsYear,
  isObservationsDay,
  isObservationsLast13Months,
  isObservationsYear
} from '../../model/api/observations';
import {
  IStatisticsDay,
  IStatisticsLast13Months,
  IStatisticsLast24Hours,
  IStatisticsLast30Days,
  IStatisticsYear
} from '../../model/statistics';
import { parseLocation } from '../locations/parse';

export function parseObservations(
  data:
    | IAPIObservationsNoData
    | IAPIObservationsYear
    | IAPIObservationsLast13Months
    | IAPIObservationsLast30Days
    | IAPIObservationsDay
    | IAPIObservationsLast25Hours
) {
  if ('noData' in data) {
    return undefined;
  }

  if (isObservationsWithMonths(data)) {
    return parseObservationsWithMonths(data);
  }

  return parseObservationsWithDay(data);
}

function parseObservationsWithMonths(
  data: IAPIObservationsYear | IAPIObservationsLast13Months | IAPIObservationsLast30Days
): IStatisticsYear | IStatisticsLast13Months | IStatisticsLast30Days {
  const station = {
    name: data.name,
    stationId: data.stationId,
    distance: data.distance,
    validFrom: data.validFrom,
    location: parseLocation(data.location)
  };

  const supported = data.supported;

  const historical = {
    units: data.historical.units,
    summary: data.historical.summary,
    months: data.historical.months
  };

  if (isObservationsYear(data)) {
    return {
      type: 'year',
      time: data.historical.name,
      station,
      supported,
      historical
    };
  }

  if (isObservationsLast13Months(data)) {
    return {
      type: 'last-13-months',
      station,
      supported,
      historical
    };
  }

  return {
    type: 'last-30-days',
    station,
    supported,
    historical
  };
}

function parseObservationsWithDay(
  data: IAPIObservationsDay | IAPIObservationsLast25Hours
): IStatisticsDay | IStatisticsLast24Hours {
  const station = {
    name: data.name,
    stationId: data.stationId,
    distance: data.distance,
    validFrom: data.validFrom,
    location: parseLocation(data.location)
  };

  const supported = data.supported;

  if (isObservationsDay(data)) {
    return {
      type: 'day',
      time: data.historical.name,
      station,
      supported,
      historical: {
        units: data.historical.units,
        summary: data.historical.summary,
        days: data.historical.days
      }
    };
  }

  return {
    type: 'last-24-hours',
    station,
    supported,
    historical: {
      units: data.historical.units,
      summary: data.historical.summary,
      days: data.historical.days
    }
  };
}

function isObservationsWithMonths(
  data:
    | IAPIObservationsYear
    | IAPIObservationsLast13Months
    | IAPIObservationsLast30Days
    | IAPIObservationsDay
    | IAPIObservationsLast25Hours
): data is IAPIObservationsYear | IAPIObservationsLast13Months | IAPIObservationsLast30Days {
  return data.historical.type === 'Year' || data.historical.type === 'Month';
}
