import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IAPISuggest } from '../../model/api/suggest';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseSuggest } from './parse';

export function querySuggestLocations({ localeCode, query }: { localeCode: LocaleCode; query?: string }) {
  const queryKey = ['suggest', localeCode, query];

  async function queryFn() {
    if (query == null) {
      return [];
    }

    const response = await api<IAPISuggest>({
      path: template(settings.api.paths.suggest, { localeCode, query })
    });

    return parseSuggest(response);
  }

  return {
    queryKey,
    queryFn
  };
}
