import { IAPISuggest } from '../../model/api/suggest';
import { parseLocation } from '../../data/locations/parse';

export function parseSuggest(data: IAPISuggest) {
  if (data._embedded == null) {
    return [];
  }

  return data._embedded.location.map(location => parseLocation(location));
}
