import { ClickableLink } from '../Clickable/Clickable';
import { LogoMet } from '../LogoMet/LogoMet';

export function ServedBy__METLogo() {
  return (
    <ClickableLink
      className="served-by__logo-link served-by__logo-link--met"
      href="http://www.met.no"
      data-app-tracking-source="header"
      isExternal={true}
    >
      <LogoMet className="served-by__logo" />
    </ClickableLink>
  );
}
