import { IApiSnowDepth } from '../../model/api/observations/snowDepths';
import { ISnowDepth, ISnowDepthStation } from '../../model/observations/snowDepths';
import { parseLocation } from '../locations/parse';

export function parseSnowDepth(data: IApiSnowDepth): ISnowDepth {
  const stations: ISnowDepthStation[] = data.stations.map(station => {
    return {
      ...station,
      location: parseLocation(station.location)
    };
  });

  const region = {
    ...data,
    stations
  };

  return region;
}
