import { Text } from '../Text/Text';
import './Banner.scss';

interface IProps {
  children: React.ReactNode;
}

export function Banner(props: IProps) {
  const { children } = props;

  return (
    <Text as="div" size="5" className="banner">
      {children}
    </Text>
  );
}
