import { QueryClient } from '@tanstack/react-query';
import { IPageDetails, IPageHandler } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class StandaloneMapPageHandler implements IPageHandler {
  getSettings({ pageDetails }: { pageDetails: IPageDetails }) {
    const embedded = pageDetails.query.embedded;

    return {
      isModal: false,
      showHeader: embedded === false,
      showFooter: false,
      expandedSearchInput: false
    };
  }

  getMetaData(_queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { subpageId: mapType } = pageDetails.params;

    const title = translate(`meta/standaloneMap/${mapType}/title`);
    const ogTitle = translate(`meta/standaloneMap/${mapType}/ogTitle`);
    const description = translate(`meta/standaloneMap/${mapType}/description`);

    return { title, ogTitle, description };
  }
}
