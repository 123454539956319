import { Icon } from '@nrk/yr-icons';
import { createPageUrl } from '../../app/redirects';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableLink } from '../Clickable/Clickable';
import { Text } from '../Text/Text';

interface IProps {
  locationId: string;
  onClick: (params: { locationId: string; href: string }) => void;
}

export function Search__Coordinate(props: IProps) {
  const { locationId, onClick } = props;

  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const href = createPageUrl({
    localeCode,
    pageId: 'forecast',
    subpageId: 'daily-table',
    locationId
  });

  return (
    <li className="search__suggested-item">
      <ClickableLink
        href={href}
        onClick={event => {
          event.preventDefault();
          onClick({ locationId, href });
        }}
        className="search__suggested-link"
      >
        <Icon id="icon-location" className="search__suggested-icon" />
        <span className="search__suggested-content">
          <Text size="4" weight="bold" color="inherit" className="search__suggested-title">
            {locationId}
          </Text>{' '}
          <Text size="5" className="search__suggested-subtitle">
            {translate('search/coordinates')}
          </Text>
        </span>
      </ClickableLink>
    </li>
  );
}
