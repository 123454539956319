import jsCookie from 'js-cookie';
import settings from '../../../app/settings';
import { getLocalStorageObject, setLocalStorageObject } from '../../../lib/helpers/localStorage';

export function getLocalStorageLocationIds() {
  const favouritedLocationIds = getLocalStorageObject<string[] | undefined>('favouritedLocations');
  const visitedLocationIds = getLocalStorageObject<string[] | undefined>('visitedLocations');
  let prepopulatedLocationIds = getLocalStorageObject<string[] | undefined>('prepopulatedLocations');

  // If we have no favourited, visited, or prepopulated locations in local storage
  // we know this is a new user who should get the hardcoded list
  // of prepopulated locations.
  if (favouritedLocationIds == null && visitedLocationIds == null && prepopulatedLocationIds == null) {
    prepopulatedLocationIds = settings.location.prepopulated;
  }

  return {
    favouritedLocationIds: favouritedLocationIds ?? [],
    visitedLocationIds: visitedLocationIds ?? [],
    prepopulatedLocationIds: prepopulatedLocationIds ?? []
  };
}

export function setLocalStorageLocationIds({
  favouritedLocationIds,
  visitedLocationIds,
  prepopulatedLocationIds
}: {
  favouritedLocationIds: string[];
  visitedLocationIds: string[];
  prepopulatedLocationIds: string[];
}) {
  const { totalFavourited, totalVisited } = settings.cookies;

  setLocalStorageObject('favouritedLocations', favouritedLocationIds);
  setLocalStorageObject('visitedLocations', visitedLocationIds);
  setLocalStorageObject('prepopulatedLocations', prepopulatedLocationIds);

  // Instead of using a separate cookie for how many prepopulated locations the user has
  // we reuse the total visited cookie since we treat prepopulated locations roughly as
  // though they were the user's visited locations.
  setLocationCookie({ name: totalFavourited, length: favouritedLocationIds.length });
  setLocationCookie({ name: totalVisited, length: visitedLocationIds.length + prepopulatedLocationIds.length });
}

function setLocationCookie({ name, length }: { name: string; length: number }) {
  try {
    jsCookie.set(name, length.toString(), { expires: 365, path: '/', sameSite: 'lax' });
  } catch (error) {
    // Swallow error
    // See https://nrknyemedier.atlassian.net/browse/YR-3110
  }
}
