import { ReactNode } from 'react';
import { EmptyText } from '../EmptyText/EmptyText';
import classNames from 'classnames';

interface IProps {
  renderFooter?: true;
  renderHeader?: false;
  children: ReactNode;
  contentMargin?: boolean;
}

export const Card__Empty = (props: IProps) => {
  const { children, renderFooter, renderHeader = true, contentMargin = true } = props;
  return (
    <div className="card card--empty" data-testid="empty-state">
      <div className={classNames('card__content', { 'card__content--with-margin': contentMargin })}>
        {renderHeader && (
          <div className="card__header">
            <div className="card__header-titles">
              <EmptyText className="card__header-title" type="h2" length={14} />
              <EmptyText className="card__header-subtitle" type="p" length={8} />
            </div>
          </div>
        )}
        {children}
      </div>
      {renderFooter && <div className="card__link-footer" />}
    </div>
  );
};
