import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiArticles } from '../../model/api/articles';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseArticles } from './parse';

export function queryArticles({ localeCode }: { localeCode: LocaleCode }) {
  const queryKey = ['articles', localeCode];

  async function queryFn() {
    const response = await api<IApiArticles>({
      path: template(settings.api.paths.article, { localeCode })
    });

    return parseArticles(response);
  }

  return {
    queryKey,
    queryFn
  };
}
