// Mapping of unsafe HTML chars to their HTML entity counterparts.
// From https://www.owasp.org/index.php/XSS_%28Cross_Site_Scripting%29_Prevention_Cheat_Sheet
const ESCAPED_HTML_CHARS = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#x27;',
  '/': '&#x2F;'
};

// Mapping of unsafe HTML and invalid JavaScript line terminator chars to their
// Unicode char counterparts which are safe to use in JavaScript strings.
// Based on https://github.com/yahoo/serialize-javascript and https://github.com/zertosh/htmlescape
const ESCAPED_JSON_CHARS = {
  '&': '\\u0026',
  '<': '\\u003C',
  '>': '\\u003E',
  '/': '\\u002F',
  '\u2028': '\\u2028',
  '\u2029': '\\u2029'
};

const RE_ESCAPE_HTML = /[&<>"'/]/g;
const RE_ESCAPE_JSON = /[&<>/\u2028\u2029]/g;
const RE_SENTENCE_CASE = /(^\s*\w{1}|\.\s*\w{1})/gi;
const RE_TEMPLATE = /\{([0-9a-zA-Z]+)\}/g;

export { escapeHtml, escapeJson, padNumber, removeFinalPunctuationMark, sentenceCase, template, truncate };

/**
 * Escape HTML string according to OWASP recomendation
 * @param {String} str A string with potential HTML tokens
 * @return {String}
 */
function escapeHtml(str = '') {
  return String(str).replace(RE_ESCAPE_HTML, char => ESCAPED_HTML_CHARS[char]);
}

/**
 * Escape HTML string according to OWASP recomendation
 * @param {String} str A string with potential HTML tokens
 * @return {String}
 */
function escapeJson(str = '') {
  return String(str).replace(RE_ESCAPE_JSON, char => ESCAPED_JSON_CHARS[char]);
}

/**
 * Pad 'num' with 'char' to given 'length'
 * @param {String|Number} num
 * @param {Number} length
 * @param {String} char
 * @returns {String}
 */
function padNumber(num, length, char) {
  let pad = '';

  if (typeof num === 'number') {
    num = num.toString();
  }

  if (num.length < length) {
    pad = Array(length - num.length + 1).join(char);
  }

  return pad + num;
}

/**
 * Remove final punctuation mark from 'str' if any
 * @param {String} str
 * @returns {String}
 */
function removeFinalPunctuationMark(str) {
  return str.charAt(str.length - 1) === '.' ? str.substring(0, str.length - 1) : str;
}

/**
 * Capitalize the first character of a sentence in 'str'. Defaults to only first sentence in string.
 * @param {String} [str]
 * @param {Boolean} [global] Capitalize for all sentences in string if true
 * @returns {String}
 */
function sentenceCase(str, global = false) {
  if (str) {
    if (global) {
      str = str.replace(RE_SENTENCE_CASE, match => {
        return match.toUpperCase();
      });
    } else {
      str = str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
  return str;
}

/**
 * Substitute 'data' values in 'str' template
 * @param {String} str
 * @param {Object} [data]
 * @returns {String}
 */
function template(str, data) {
  return String(str).replace(RE_TEMPLATE, (match, prop, idx) => {
    return data && data[prop] != null ? data[prop] : '';
  });
}

/**
 * Truncate 'str' to given 'length'
 * @param {String} str
 * @param {Number} length
 * @returns {String}
 */
function truncate(str, length) {
  return `${str.slice(0, length)}…`;
}
