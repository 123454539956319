import { Component } from 'react';
import { captureException } from '../../lib/errorReporter';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IPage } from '../../model/page';
import { isItSafeToReload, storeReloadAttemptTimestamp } from './reloadVersionStorage';
import './ErrorBoundary.scss';
import { Text } from '../Text/Text';

const CSS_CHUNK_LOAD_FAILED = 'CSS_CHUNK_LOAD_FAILED';
const CHUNK_LOAD_ERROR = 'ChunkLoadError';

export const isChunkLoadError = (error: IError) => {
  // If error.name is "ChunkLoadError", we know that loadable had troubles fetching
  // a js sourcefile from the server (error comes from webpack). If error.code is "CSS_CHUNK_LOAD_FAILED" we know
  // we had trouble fetching a css file from the server (error comes from mini-css-extract-plugin).
  return error.name === CHUNK_LOAD_ERROR || error.code === CSS_CHUNK_LOAD_FAILED;
};

interface IError extends Error {
  code?: string;
}

interface IProps {
  currentPage: IPage;
  showLogo: boolean;
  resetErrorWhenNavigatingToNewPage: boolean;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
  static getDerivedStateFromError(error: IError) {
    if (isChunkLoadError(error)) {
      // If error is ChunkLoad, we know loadable had trouble fetching an asset from the server,
      // most likely getting a 404. This often happens when we deploy a new version and people
      // have a tab open already, if they navigate or do something that triggers a loadable component,
      // their browser will try to fetch a resource that is no longer available.
      // To prevent this we try to do a reload of the page, to make sure the client is running the
      // same version of the app as the server.
      if (isItSafeToReload()) {
        storeReloadAttemptTimestamp();
        window.location.reload();
      }
    }

    captureException({ error, logger: 'ErrorBoundary' });

    // Update state so the next render will show the fallback UI
    return {
      hasError: true
    };
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidUpdate(prevProvs: IProps) {
    const { resetErrorWhenNavigatingToNewPage: reloadWhenNavigatingToNewPage, currentPage } = this.props;
    const { hasError } = this.state;

    if (reloadWhenNavigatingToNewPage === true && prevProvs.currentPage !== currentPage && hasError === true) {
      this.setState({
        hasError: false
      });
    }
  }

  render() {
    const { showLogo, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorBoundary__Error showLogo={showLogo} />;
    }

    return <>{children}</>;
  }
}

function ErrorBoundary__Error(props: { showLogo: boolean }) {
  const { showLogo } = props;

  const translate = useTranslate();
  const localeCode = translate('localeCode');

  return (
    <div className="error-boundary">
      {showLogo && (
        // We use `data-app-external="true"` because we want to reload the entire page when an error occurs and the user clicks on this link
        <a href={`/${localeCode}`} className="error-boundary__logo-link" data-app-external={true}>
          <img
            className="error-boundary__logo"
            src="/assets/images/logo-yr--circle.svg"
            alt={translate('fiveOhOh/back')}
          />
        </a>
      )}

      <Text as="p" size="2" weight="bold">
        {translate('fiveOhOh/title')}
      </Text>
    </div>
  );
}
