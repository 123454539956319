export function testReducedMotion() {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.matchMedia && window.matchMedia('(prefers-reduced-motion)').matches;
}

export function testScrollBehavior() {
  if (typeof window === 'undefined') {
    return false;
  }

  return 'scrollBehavior' in document.documentElement.style;
}

export function testSmil() {
  if (typeof window === 'undefined') {
    return false;
  }

  if (typeof document.createElementNS !== 'function') {
    return false;
  }

  const animate = document.createElementNS('http://www.w3.org/2000/svg', 'animate');
  return /SVGAnimate/.test(animate.toString());
}
