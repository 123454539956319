import { memo } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { Clippy } from '../Clippy/Clippy';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { LoadingBar } from '../LoadingBar/LoadingBar';
import { LocationHeader } from '../LocationHeader/LocationHeader';
import { PageHeader } from '../PageHeader/PageHeader';
import { ServedBy } from '../ServedBy/ServedBy';
import { SkipToContent } from '../SkipToContent/SkipToContent';
import { Survey } from '../Survey/Survey';
import './Page.scss';

interface IProps {
  children: React.ReactNode;
}

export const Page = memo(function Page(props: IProps) {
  const { children } = props;

  const { currentPage, currentPageSettings, isFirstRender } = useAppState();

  const { showHeader } = currentPageSettings;
  const { embedded } = currentPage.details.query;
  const { locationId } = currentPage.details.params;

  return (
    <div className="page">
      <div className="page__header">
        {embedded === false && (
          <>
            <SkipToContent />
            {/* Do not render Clippy on the server since it is JS dependent */}
            {isFirstRender === false && <Clippy />}
            <Survey />
            <LoadingBar />
          </>
        )}

        {showHeader && (
          <>
            <div className="layout-container">
              <div className="page__served-by">
                <ServedBy />
              </div>

              <PageHeader />
            </div>

            {/*
              We use the location id as a key here because we want to make sure we re-render
              the location header when the user changes the location so that the scroll
              position gets reset.
            */}
            {locationId != null && <LocationHeader key={locationId} />}
          </>
        )}
      </div>

      <div className="page__main">
        <ErrorBoundary currentPage={currentPage} showLogo={false} resetErrorWhenNavigatingToNewPage={true}>
          {children}
        </ErrorBoundary>
      </div>
    </div>
  );
});
