import classNames from 'classnames';
import { useDebouncedCallback } from '../../lib/hooks/useDebouncedCallback';
import { useDebouncedResize } from '../../lib/hooks/useDebouncedResize';
import { track } from '../../lib/analytics/track';
import { Children, useCallback, useRef, useState } from 'react';
import './CardList.scss';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  trackingId?: string;
  length: 2 | 3 | 4;
  equalHeight?: boolean;
  children: React.ReactNode;
}

interface ICardSet {
  startIndex: number;
  endIndex: number;
  left: number;
  width: number;
}

export const CardList = (props: IProps) => {
  const { trackingId, length, equalHeight = true, children } = props;
  const translate = useTranslate();

  const baseRef = useRef<HTMLDivElement>(null);
  const [visibleCards, setVisibleCards] = useState<number[]>();
  const [cardSets, setCardSets] = useState<ICardSet[]>([]);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Group cards into sets where each set contains the maximum number of cards
  // that can be fully visible within the scrollable card list at once.
  // Each card can be part of multiple sets.
  const createCardSets = useCallback(() => {
    if (baseRef.current == null) {
      return;
    }

    const baseRect = baseRef.current.getBoundingClientRect();
    const cards = baseRef.current.querySelectorAll<HTMLDivElement>('.card-list__card');

    const cardDimensions = Array.from(cards).map(card => {
      const left = card.offsetLeft;
      const width = card.offsetWidth;

      return {
        left,
        right: left + width
      };
    });

    const newCardSets: ICardSet[] = [];

    // Starting from the first card, find the rightmost card that together with the current card
    // has a combined width less than the card list itself. We repeat this process for each card
    // until we have created a set that includes the last card in the card list.
    for (let startIndex = 0; startIndex < cardDimensions.length; startIndex++) {
      const startDimensions = cardDimensions[startIndex];

      for (let endIndex = cardDimensions.length - 1; endIndex >= startIndex; endIndex--) {
        const endDimensions = cardDimensions[endIndex];
        const combinedWidth = endDimensions.right - startDimensions.left;

        if (combinedWidth < baseRect.width) {
          newCardSets[startIndex] = {
            startIndex,
            endIndex,
            left: startDimensions.left,
            width: endDimensions.right - startDimensions.left
          };

          break;
        }
      }

      // Stop if we haven't found any sets yet.
      // This could happen if styles haven't loaded yet and the cards
      // are displayed as block elements with the same size as the container.
      if (newCardSets.length === 0) {
        break;
      }

      // If the last set we found includes the last card we don't need to create more sets
      if (newCardSets[startIndex].endIndex === cardDimensions.length - 1) {
        break;
      }
    }

    setCardSets(newCardSets);
  }, []);

  // Recreate the card sets whenever the viewport changes
  useDebouncedResize(createCardSets, 250);

  // Detect which cards are fully visible
  const detectVisibleCards = useCallback(() => {
    if (baseRef.current == null) {
      return;
    }

    const baseRect = baseRef.current.getBoundingClientRect();
    const cards = baseRef.current.querySelectorAll('.card-list__card');
    const newVisibleCards = [];

    for (let i = 0; i < cards.length; i++) {
      const cardRect = cards[i].getBoundingClientRect();

      const isFullyVisible = cardRect.right <= baseRect.right && cardRect.left >= baseRect.left;

      if (isFullyVisible) {
        newVisibleCards.push(i);
      }
    }

    setVisibleCards(newVisibleCards);
  }, []);

  // Detect visible cards when the viewport changes
  useDebouncedResize(detectVisibleCards, 250);

  const handleScroll = useDebouncedCallback(
    useCallback(() => {
      // Track that the use has scrolled the card list, but only the first time they do so
      if (hasScrolled === false) {
        setHasScrolled(true);
      }

      // Detect visible cards when the user scrolls the card list
      detectVisibleCards();
    }, [hasScrolled, detectVisibleCards]),
    100
  );

  const handlePeekButtonClick = useCallback(
    (index: number) => {
      if (trackingId != null) {
        track.event({ category: 'card_list', action: 'click_peek_button', label: trackingId });
      }

      scrollCardIntoView(index);
    },
    [trackingId]
  );

  function scrollCardIntoView(index: number) {
    if (baseRef.current == null) {
      return;
    }

    const card = baseRef.current.querySelector(`.card-list__card:nth-child(${index + 1})`);
    if (card == null) {
      return;
    }

    const baseRect = baseRef.current.getBoundingClientRect();
    const cardRect = card.getBoundingClientRect();

    // Calculate the card list and card center. The card center must account for how much the card
    // list has been scrolled since `getBoundingClientRect()` is only based on the viewport.
    const cardListCenter = baseRect.left + baseRect.width / 2;
    const cardCenter = baseRef.current.scrollLeft + cardRect.left + cardRect.width / 2;

    // Scroll the clicked card into view
    baseRef.current.scrollLeft = cardCenter - cardListCenter;
  }

  // We filter out children that are null to make sure we do not render empty cards.
  // We also need to make sure the filteredCards array is the same length as
  // 'cardListRef.current.querySelectorAll('.card-list__card');' in checkVisibleCards
  const filteredCards: React.ReactNode[] = [];
  Children.forEach(children, child => {
    if (child != null) {
      filteredCards.push(child);
    }
  });

  return (
    <div
      className={classNames('card-list', `card-list--length-${length}`, {
        'card-list--wrap': filteredCards.length > length
      })}
      onScroll={handleScroll}
      ref={baseRef}
    >
      <ol className={classNames('card-list__list')}>
        {filteredCards.map((child, index) => {
          let ariaLabel = undefined;
          if (visibleCards != null) {
            ariaLabel =
              visibleCards[0] > index ? translate('cardList/showCard/previous') : translate('cardList/showCard/next');
          }

          return (
            <li
              key={index}
              className={classNames({ 'card-list__card': true, 'card-list__card--equal-height': equalHeight })}
            >
              {child}

              <button
                className="card-list__peek-button"
                // The peek button is unnecessary when a card is completely visible
                hidden={visibleCards == null || visibleCards.includes(index)}
                aria-label={ariaLabel}
                onClick={() => {
                  handlePeekButtonClick(index);
                }}
              ></button>
            </li>
          );
        })}
      </ol>

      {/* Render a div per card set which will be used as scroll snap points */}
      {cardSets.map((cardSet, index) => (
        <div
          className="card-list__card-set"
          data-index={index}
          key={index}
          style={{
            left: cardSet.left,
            width: cardSet.width
          }}
        />
      ))}
    </div>
  );
};
