import settings from '../../app/settings';
import { template } from '../../lib/string';
import { IApiAirQuality } from '../../model/api/airQuality';
import { LocaleCode } from '../../model/locale';
import { api } from '../api';
import { parseAirQuality } from './parse';

export function queryAirQualityByLocationId({
  localeCode,
  locationId
}: {
  localeCode: LocaleCode;
  locationId?: string;
}) {
  const queryKey = ['airQuality', locationId];

  async function queryFn() {
    if (locationId == null) {
      return;
    }

    const response = await api<IApiAirQuality>({
      path: template(settings.api.paths.airQuality, { localeCode, locationId })
    });

    return parseAirQuality(response);
  }

  return {
    queryKey,
    queryFn
  };
}
