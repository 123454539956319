import time from '@nrk/yr-time';
import camelCase from 'lodash/camelCase';
import { PageNotFoundError } from '../../app/errors';
import { createTimeLabel } from '../../lib/helpers/time';
import { TStatisticsPeriod } from '../../model/statistics';
import { ITranslateFunction } from '../../model/translate';
import { StringMap } from '../../model/ts-utils';

export type TStatisticsTimeQueryType = 'day' | 'month' | 'year';

export const getDateQueryString = (selectedDate: string, queryType: TStatisticsTimeQueryType) => {
  const queryStringSize: StringMap<number> = {
    day: 10,
    month: 7,
    year: 4
  };

  // return dateQuery for the selected date based on active view type
  // day: YYYY-MM-DD
  // month: YYYY-MM
  // year: YYYY
  return `?q=${selectedDate.slice(0, queryStringSize[queryType])}`;
};

export function getQueryFromCurrentSearch(search: string, translate: ITranslateFunction) {
  if (search === 'last-13-months' || search === 'last-30-days' || search === 'last-24-hours') {
    return translate(`statisticsHeader/query/${camelCase(search)}`);
  }

  return `?q=${search}`;
}

export function getStatisticsPeriodFromQuery(query?: string): TStatisticsPeriod {
  // Default to show last 13 months if we have no parameters
  if (query == null) {
    return { type: 'last-13-months' };
  }

  if (query === 'last-13-months' || query === 'last-30-days' || query === 'last-24-hours') {
    return { type: query };
  }

  if (/^\d{4}$/.test(query)) {
    return { type: 'year', time: query };
  }

  if (/^\d{4}-\d{2}$/.test(query)) {
    return { type: 'month', time: query };
  }

  if (/^\d{4}-\d{2}-\d{2}$/.test(query)) {
    return { type: 'day', time: query };
  }

  throw new PageNotFoundError();
}

export function createStatisticsPeriodLabel({
  period,
  translate
}: {
  period: TStatisticsPeriod;
  translate: ITranslateFunction;
}) {
  if (period.type === 'last-13-months' || period.type === 'last-30-days' || period.type === 'last-24-hours') {
    return translate(`statisticsPeriodLabel/${camelCase(period.type)}`);
  }

  if (period.type === 'year') {
    return time.create(period.time).format('YYYY');
  }

  if (period.type === 'month') {
    return createTimeLabel({
      time: time.create(period.time).format('YYYY-MM'),
      type: 'month-with-year-long',
      transform: 'sentence-case',
      translate
    });
  }

  if (period.type === 'day') {
    return createTimeLabel({
      time: time.create(period.time).format('YYYY-MM-DD'),
      type: 'date-with-year-long',
      transform: 'sentence-case',
      translate
    });
  }

  return undefined;
}
