import { QueryClient } from '@tanstack/react-query';
import { queryLocation } from '../../data/locations/queries';
import { querySearchByPosition, querySearchByQuery } from '../../data/search/queries';
import { parseDecimalDegrees } from '../../lib/coordinate';
import { positionToDecimalDegrees, positionToDms } from '../../lib/format';
import { decode } from '../../lib/helpers/url';
import { escapeHtml } from '../../lib/string';
import { IPageDetails, IPageHandler, IPageHandlerGetDataQueriesOptions } from '../../model/page';
import { ITranslateFunction } from '../../model/translate';

export class SearchPageHandler implements IPageHandler {
  getSettings({ pageDetails }: { pageDetails: IPageDetails }) {
    return {
      isModal: false,
      showHeader: true,
      showFooter: pageDetails.subpageId !== 'map',
      expandedSearchInput: true
    };
  }

  getMetaData(_queryClient: QueryClient, pageDetails: IPageDetails, translate: ITranslateFunction) {
    const { q, lat, lon } = pageDetails.query;

    const querystring = q ? q : positionToDms({ lat, lon }, ' ', translate);
    const escapedQueryString = escapeHtml(decode(querystring));

    if (q != null) {
      return {
        title: translate('meta/search/query/title', { search: escapedQueryString }),
        ogTitle: translate('meta/search/query/ogTitle', { search: escapedQueryString }),
        description: translate('meta/search/query/description')
      };
    }

    return {
      title: translate('meta/search/main/title'),
      ogTitle: translate('meta/search/main/ogTitle'),
      description: translate('meta/search/main/description')
    };
  }

  async getDataQueries({ pageDetails }: IPageHandlerGetDataQueriesOptions) {
    const { localeCode } = pageDetails.params;
    const { q: query, lat, lon } = pageDetails.query;

    const queryPosition = query != null ? parseDecimalDegrees(query) : undefined;

    if (queryPosition != null) {
      const locationId = positionToDecimalDegrees({ position: queryPosition, type: 'locationId' });
      return [queryLocation({ localeCode, locationId })];
    }

    if (query != null) {
      return [querySearchByQuery({ localeCode, query })];
    }

    if (lat != null && lon != null) {
      return [querySearchByPosition({ localeCode, lat, lon })];
    }

    return [];
  }
}
