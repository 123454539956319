import { QueryClient } from '@tanstack/react-query';
import { selectAll } from '../lib/element';
import { join } from '../lib/helpers/url';
import { LocaleCode } from '../model/locale';
import { IAlternates, IPageMetaData } from '../model/metaTags';
import { IPageDetails } from '../model/page';
import { ITranslateFunction } from '../model/translate';
import { isKeyOfObject } from '../model/ts-utils';
import { pages } from './pages';
import { createPageUrl } from './redirects';
import { getPageDetails } from './routes';
import settings from './settings';

interface IOpenGraphMetaData {
  title: string;
  type: string;
  url: string;
  description: string;
  image: string;
  locale: string;
}

export function getOpenGraphMetaData(
  path: string,
  search: string,
  localeCode: LocaleCode,
  metaData?: IPageMetaData
): IOpenGraphMetaData {
  const url = path + search;

  const data = {
    image: `${settings.site.baseUrl}/assets/images/yr-some.jpg`,
    locale: `${settings.locale.supportedLanguages[localeCode].lang.replace('-', '_')}`
  };

  return {
    ...data,
    title: metaData != null ? metaData.ogTitle : '',
    type: 'website',
    url: join(settings.site.baseUrl, url),
    description: metaData != null ? metaData.description : ''
  };
}

export function getAlternates(path: string, search: string) {
  const { supportedLanguages } = settings.locale;
  const pageDetails = getPageDetails(path, search);
  const data: IAlternates = {};

  if (pageDetails == null) {
    return data;
  }

  if (pageDetails.hasLocaleCode === false) {
    return data;
  }

  Object.keys(supportedLanguages).forEach(localeCode => {
    const search = getSearchFromQuery(pageDetails, localeCode as LocaleCode);

    const href = createPageUrl({
      ...pageDetails.params,
      search,
      localeCode: localeCode as LocaleCode
    });

    const alternate = {
      // <link rel="alternate" hreflang="nb" href="..."> must be an absolute URL
      href: `${settings.site.baseUrl}${href}`,
      title: supportedLanguages[localeCode as LocaleCode].title,
      hreflang: localeCode as LocaleCode
    };

    data[localeCode] = alternate;
  });

  return data;
}

export function updateMeta(path: string, search: string, queryClient: QueryClient, translate: ITranslateFunction) {
  const elAltLinks = selectAll('.alt-link');
  const elOgMeta = selectAll('.og-meta');

  const pageDetails = getPageDetails(path, search);
  if (pageDetails == null) {
    return;
  }

  const { handler, params } = pageDetails;
  const metaData = handler.getMetaData(queryClient, pageDetails, translate) ?? getMetaDataFallback(translate);

  const openGraphData = getOpenGraphMetaData(path, search, params.localeCode, metaData);
  const alternates = getAlternates(path, search);

  document.title = metaData.title;

  if (alternates) {
    elAltLinks.forEach(el => {
      const id = el.getAttribute('data-id');
      if (id != null && id !== '') {
        const alternate = alternates[id];

        if (alternate != null) {
          el.setAttribute('href', alternate.href);
        }
      }
    });
  }

  elOgMeta.forEach(el => {
    // Update meta elements, which can have either a name attribute (`<meta name="name" ...>`)
    // or a property attribute (`< meta property="og:name" ...>`.
    let name: string | undefined = el.getAttribute('name') ?? undefined;
    if (name == null) {
      const property = el.getAttribute('property');
      name = property?.split(':')[1] ?? undefined;
    }

    if (name != null) {
      if (isKeyOfObject(openGraphData, name)) {
        el.setAttribute('content', openGraphData[name] ?? '');
      }
    }
  });
}

const getSearchFromQuery = (pageDetails: IPageDetails, localeCode: LocaleCode): string => {
  // On statistics page we have queries that are locale specific. The following
  // crazyness is here to  to generate the
  // proper alt-links and prevent google and other bots from reaching statistics
  // pages that are invalid due to wrong query translation.
  if (
    pageDetails.pageId === 'statistics' &&
    (pageDetails.query.q === 'last-13-months' ||
      pageDetails.query.q === 'last-30-days' ||
      pageDetails.query.q === 'last-24-hours')
  ) {
    const translatedQuery = pages.locations.find(page => page.id === 'statistics')?.query?.q[pageDetails.query.q][
      localeCode
    ];

    return `?q=${translatedQuery ?? pageDetails.query.q}`;
  }

  return pageDetails.search;
};

export function getMetaDataFallback(translate: ITranslateFunction) {
  return {
    title: translate('meta/home/title'),
    ogTitle: translate('meta/home/ogTitle'),
    description: translate('meta/home/description')
  };
}
